import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { deleteAccount as deleteAccountAction, clearErrors as clearErrorsAction } from '../actions';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 60px auto;
  width: 80%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const deleteUserErrors = useSelector(state => state.deleteUserErrors);

  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ currentPassword: '' }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required('Musisz potwierdzić hasło!'),
      })}
      onSubmit={({ currentPassword }) => {
        dispatch(deleteAccountAction(currentPassword))
          .then(() => navigate(routes.accountDeleted));
      }}
    >
      {({ handleChange, handleBlur, errors, values, touched, submitForm }) => {
        return (
          <ManageUserFormTemplate
            confirmModal
            removeButton
            header="Usuń konto"
            buttonText="Usuń"
            modalText="Jesteś pewien? Twój profil zostanie usunięty."
            onSubmit={() => submitForm()}
            submitButtonText="Usuń"
            errors={errors}
            formErrors={deleteUserErrors}
          >
            <StyledFormInput
              labelName="Potwierdź hasło"
              name="currentPassword"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.currentPassword}
              touched={touched.currentPassword}
              value={values.currentPassword}
            />
            {deleteUserErrors &&
              deleteUserErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default DeleteAccount;
