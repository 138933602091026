import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.footer`
  text-align: right;
  width: 100%;
  height: 30px;
  cursor: default;
  flex-shrink: 0;
  @media (max-width: 450px) {
    display: none;
  }
`;

const Content = styled.p`
  padding-right: 20px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.xxs};
`;

const Footer = () => (
  <StyledWrapper>
    <Content> &copy; 2021 - Szama</Content>
  </StyledWrapper>
);

export default Footer;
