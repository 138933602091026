import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { range } from '../../helpers/Functions';

const StyledContainer = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  margin: 15px auto;
  > p {
    width: 185px;
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.xxs};
    color: ${({ theme }) => theme.black};
    text-align: center;
    font-weight: ${({ theme }) => theme.bold};
    z-index: -1;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.primary};
    opacity: 1;
    padding: 2px 5px;
    border-radius: 3px;
    letter-spacing: 0.5px;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: ${({ theme }) => theme.fontSize.l};
    opacity: 0.4;
    position: relative;
    color: ${({ theme }) => theme.grey};
  }
  .swiper-slide-active {
    font-weight: ${({ theme }) => theme.bold};
    opacity: 1;
    width: 100%;
  }
`;

const AmountPicker = ({
  twoTitlesVariant,
  min,
  max,
  name,
  onChange,
  value,
  itemTitle,
  firstVariantTitle,
  secondVariantTitle,
  activeVariant,
}) => {
  const amountArray = range(min, max);
  const initialIndex = Math.max(amountArray.indexOf(value), 0);
  const [amount, setAmount] = useState(amountArray[initialIndex]);

  useEffect(() => {
    onChange(amount);
    //eslint-disable-next-line
  }, [amount]);

  useEffect(() => {
    if (value) setAmount(value);
  }, [value]);

  return (
    <StyledContainer name={name}>
      <StyledSwiper
        loop={true}
        centeredSlides={true}
        slidesPerView={3}
        direction={'vertical'}
        onSlideChange={(e) => {
          setAmount(amountArray[e.realIndex]);
        }}
        initialSlide={initialIndex}
      >
        {amountArray.map((amount, index) => (
          <SwiperSlide key={index}>
            <div>{amount}</div>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <p>
        {twoTitlesVariant
          ? activeVariant === 1
            ? firstVariantTitle
            : secondVariantTitle
          : itemTitle}
      </p>
    </StyledContainer>
  );
};

AmountPicker.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemTitle: PropTypes.string,
  title: PropTypes.string,
};

export default AmountPicker;
