import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  editNutrientsGoal as editNutrientsGoalAction,
  fetchCurrentNutrientsGoal as fetchCurrentNutrientsGoalAction,
} from '../actions/index';
import FormInput from '../components/atoms/FormInput';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';

const StyledFormInput = styled(FormInput)`
  &:before {
    content: 'G';
    position: absolute;
    right: 35px;
    font-family: 'Big Shoulders Display';
    bottom: 15px;
    color: ${({ theme }) => theme.black};
    font-size: ${({ theme }) => theme.fontSize.m};
  }
  &:first-child {
    &:before {
      content: 'KCAL';
    }
  }
  margin: 40px 15px;
  width: 40%;
  > label {
    top: 45px;
    font-weight: ${({ theme }) => theme.bold};
  }
  > input {
    font-weight: ${({ theme }) => theme.bold};
    font-size: ${({ theme }) => theme.fontSize.xxl};
    font-family: 'Big Shoulders Display';
    color: ${({ theme }) => theme.lightGrey};
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ChangeNutrientsGoal = () => {
  const navigate = useNavigate();
  const currentNutrientsGoal = useSelector((state) => state.currentNutrientsGoal);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState();
  useEffect(() => {
    dispatch(fetchCurrentNutrientsGoalAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentNutrientsGoal)
      setInitialValues({
        calories: currentNutrientsGoal.calories,
        fats: currentNutrientsGoal.fats,
        proteins: currentNutrientsGoal.proteins,
        carbohydrates: currentNutrientsGoal.carbohydrates,
        sugars: currentNutrientsGoal.sugars,
        salt: currentNutrientsGoal.salt,
      });
    // eslint-disable-next-line
  }, [currentNutrientsGoal]);

  if (!initialValues) return <></>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        calories: Yup.number()
          .min(0, 'Kalorie nie mogą być ujemne!')
          .max(10000, 'Maksymalna ilość kalorii to 10000!'),
        fats: Yup.number()
          .min(0, 'Tłuszcze nie mogą być ujemne!')
          .max(10000, 'Maksymalna ilość tłuszczów to 10000!'),
        proteins: Yup.number()
          .min(0, 'Białko nie może być ujemne!')
          .max(10000, 'Maksymalna ilość białka to 10000!'),
        carbohydrates: Yup.number()
          .min(0, 'Węglowodany nie mogą być ujemne!')
          .max(10000, 'Maksymalna ilość węglowodanów to 10000!'),
        sugars: Yup.number()
          .min(0, 'Cukry nie mogą być ujemne!')
          .max(10000, 'Maksymalna ilość cukrów to 10000!'),
        salt: Yup.number()
          .min(0, 'Sól nie może być ujemna!')
          .max(10000, 'Maksymalna ilość soli to 10000!'),
      })}
      onSubmit={(values) => {
        dispatch(editNutrientsGoalAction(values))
          .then(() => navigate(`/account/nutrientsGoalTimeline`));
      }}
    >
      {({ handleChange, handleBlur, values, errors, touched, submitForm }) => {
        return (
          <ManageUserFormTemplate
            swapButton
            header="Twoje cele"
            // header="Nutrients goal"
            buttonText="Zmień"
            onSubmit={() => submitForm()}
            submitButtonText="Zmień"
          >
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
              <StyledFormInput
                labelName="Kalorie"
                name="calories"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.calories}
                errors={errors.calories}
                touched={touched.calories}
              />
              <StyledFormInput
                labelName="Tłuszcze"
                name="fats"
                type="number"
                value={values.fats}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.fats}
                touched={touched.fats}
              />
              <StyledFormInput
                labelName="Białko"
                name="proteins"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.proteins}
                errors={errors.proteins}
                touched={touched.proteins}
              />
              <StyledFormInput
                labelName="Węglowodany"
                name="carbohydrates"
                type="number"
                value={values.carbohydrates}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.carbohydrates}
                touched={touched.carbohydrates}
              />
              <StyledFormInput
                labelName="Cukry"
                name="sugars"
                type="number"
                value={values.sugars}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.sugars}
                touched={touched.sugars}
              />

              <StyledFormInput
                labelName="Sól"
                name="salt"
                type="number"
                value={values.salt}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.salt}
                touched={touched.salt}
              />
            </div>
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ChangeNutrientsGoal;
