import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  changeUsername as changeUsernameAction,
  clearErrors as clearErrorsAction,
} from '../actions';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 60px auto;
  width: 80%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ChangeUsername = () => {
  const { user, changeUsernameErrors } = useSelector(state => ({
    users: state.users,
    user: state.user,
    changeUsernameErrors: state.changeUsernameErrors,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ currentPassword: '', newUsername: user && user.username }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required('Musisz potwierdzić hasło!'),
        newUsername: Yup.string()
          .min(4, 'Nazwa użytkownika musi zawierać minimalnie 4 znaki!')
          .max(25, 'Nazwa użytkownika może zawierać maksymalnie 25 znaków!')
          .required('Nazwa użytkownika jest wymagana!'),
      })}
      onSubmit={({ currentPassword, newUsername }) => {
        dispatch(changeUsernameAction(currentPassword, newUsername));
      }}
    >
      {({ handleChange, handleBlur, errors, touched, values, submitForm }) => {
        return (
          <ManageUserFormTemplate
            confirmModal
            header="Zmień nazwę użytkownika"
            buttonText="Zmień"
            swapButton
            modalText="Jesteś pewien? Po zmianie nazwy zostaniesz wylogowany."
            onSubmit={() => submitForm()}
            submitButtonText="Zmień"
            errors={errors}
            formErrors={changeUsernameErrors}
          >
            <StyledFormInput
              labelName="Potwierdź hasło"
              name="currentPassword"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.currentPassword}
              touched={touched.currentPassword}
            />
            <StyledFormInput
              labelName="Zmień nazwę użytkownika"
              name="newUsername"
              type="text"
              value={values.newUsername}
              onChange={handleChange}
              onBlur={handleBlur}
              autocomplete="off"
              errors={errors.newUsername}
              touched={touched.newUsername}
            />
            {changeUsernameErrors &&
              changeUsernameErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ChangeUsername;
