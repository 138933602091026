import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { SuitHeart } from '@styled-icons/bootstrap/SuitHeart';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { routes } from '../../routes';
import { Person } from '@styled-icons/bootstrap/Person';
import { Hourglass } from '@styled-icons/bootstrap/Hourglass';
import {
  addToFavourites as addToFavouritesAction,
  removeFromFavourites as removeFromFavouritesAction,
} from '../../actions';

const StyledWrapper = styled.div`
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  height: fit-content;
  text-align: left;
  margin: 25px 10px;
  ${(props) =>
    props.$isFavourite &&
    css`
      .heartWrapper {
        opacity: 1 !important;
      }
    `};
  @media (min-width: 751px) {
    text-align: center;
    border-radius: 0;
    &:before {
      z-index: 5;
      position: absolute;
      content: '';
      pointer-events: none;
      opacity: 0;
      top: 40px;
      right: 20px;
      bottom: 40px;
      left: 20px;
      border-top: 1px solid ${({ theme }) => theme.white};
      border-bottom: 1px solid ${({ theme }) => theme.white};
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      transition: opacity 0.35s, transform 0.35s;
    }
    &:after {
      z-index: 5;
      position: absolute;
      content: '';
      pointer-events: none;
      opacity: 0;
      top: 20px;
      right: 40px;
      bottom: 20px;
      left: 40px;
      border-right: 1px solid ${({ theme }) => theme.white};
      border-left: 1px solid ${({ theme }) => theme.white};
      -webkit-transform: scale(1, 0);
      transform: scale(1, 0);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0;
      transition: opacity 0.35s, transform 0.35s;
    }
    #description {
      display: none;
    }
    .recipeName {
      position: absolute;
      color: ${({ theme }) => theme.white};
      font-variant: small-caps;
      font-size: ${({ theme }) => theme.fontSize.xxl};
      padding: 10px 50px;
      transition: 0.35s ease;
    }
    .recipeImage {
      height: 350px;
      border-radius: 0 !important;
    }

    .recipeBackground {
      display: block;
    }
    .heartWrapper {
      bottom: 0;
      right: 0;
      top: unset;
      opacity: 0;
      background-color: transparent;
      padding: 0;
    }
    .info {
      position: absolute;
      top: 50%;
      margin: 0px auto;
      padding: 0 30px;
      opacity: 0;
      transform: translateY(-50%);
      transition: opacity 0.35s, transform 0.35s;
      justify-content: center;
      > div {
        color: ${({ theme }) => theme.white};
        font-size: ${({ theme }) => theme.fontSize.s};
        font-weight: ${({ theme }) => theme.light};
        white-space: nowrap;
        margin: 0 10px;
      }
    }
    &:hover {
      &:before,
      &:after {
        opacity: 1;
        transform: scale(1);
      }
      &:after {
        transition-delay: 0.25s;
      }
      .recipeName {
        transform: translateY(120%);
        background-color: transparent;
      }
      .info {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.25s;
      }
      .heartWrapper {
        opacity: 1;
      }
      .recipeBackground {
        transform: translateY(0%);
        backdrop-filter: blur(5px);
      }
    }
  }
`;

const StyledImage = styled.div`
  height: 250px;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  cursor: pointer;
`;

const StyledName = styled.h1`
  width: 100%;
  position: relative;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.secondary};
  top: 0;
  cursor: pointer;
  overflow: hidden;
  pointer-events: none;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.l};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  z-index: 2;
  padding: 10px 5px 2px 5px;
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

const StyledDescription = styled.h3`
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0 5px;
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const Background = styled.div`
  pointer-events: none;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  transform: translateY(-80%);
  top: 0;
  display: none;
  left: 0;
  transition: 0.3s;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  transform: unset;
  z-index: 2;
  pointer-events: none;
`;

const StyledInfo = styled.div`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.bold};
  margin: 0 5px;
  > svg {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
  }
`;

const HeartWrapper = styled.div`
  top: 230px;
  opacity: 1;
  bottom: unset;
  left: unset;
  right: 20px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 100%;
  padding: 4px;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  transition: 0.35s;

  svg {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.red};
    margin: 5px;
    @media (max-width: 750px) {
      width: 25px;
      height: 25px;
    }
    @media (max-width: 450px) {
      width: 23px;
      height: 23px;
    }
  }
`;

const Recipe = ({
  id,
  user,
  name,
  description,
  recipeIngredients,
  numberOfPortions,
  image,
  activeCookingTime,
  totalCookingTime,
  isFavourite,
  refreshData,
}) => {
  const naviate = useNavigate();
  let recipeActiveCookingTime = undefined;
  if (activeCookingTime) {
    let split = activeCookingTime.split(':').map((x) => parseInt(x));
    if (split.length === 2) {
      let [hours, minutes] = split;
      if (hours > 0 || minutes > 0) recipeActiveCookingTime = { hours, minutes };
    }
  }

  const dispatch = useDispatch();
  const addToFavourites = () => {
    dispatch(addToFavouritesAction(id)).then(() => refreshData());
  };
  const removeFromFavourites = () => {
    dispatch(removeFromFavouritesAction(id)).then(() => refreshData());
  };

  return (
    <StyledWrapper className="recipe" id={name} $isFavourite={isFavourite}>
      <Link to={`/recipes/${id}`}>
        <StyledImage
          className="recipeImage"
          src={image}
        />
      </Link>
      <StyledName className="recipeName" title={name}>
        {name}
      </StyledName>
      <StyledDescription id="description">{description}</StyledDescription>
      <InfoWrapper className="info">
        {recipeActiveCookingTime && (
          <StyledInfo>
            <Hourglass style={{ marginRight: '5px' }} size={23} />
            {recipeActiveCookingTime.hours}
            {recipeActiveCookingTime.minutes > 0 && '.' + recipeActiveCookingTime.minutes}h
          </StyledInfo>
        )}
        <StyledInfo>
          <Person size={26} style={{ marginRight: '5px' }} />
          {recipeIngredients &&
            recipeIngredients
              .map((i) => i.ingredient && (i.ingredient.calories * i.amount) / numberOfPortions)
              .reduce((x, y) => x + y, 0)
              .toFixed(0)}{' '}
          kcal
        </StyledInfo>
      </InfoWrapper>
      {!isFavourite ? (
        <HeartWrapper
          className="heartWrapper"
          onClick={() => (user ? addToFavourites() : navigate(routes.login))}
        >
          <SuitHeart />
        </HeartWrapper>
      ) : (
        <HeartWrapper className="heartWrapper" onClick={removeFromFavourites}>
          <SuitHeartFill />
        </HeartWrapper>
      )}
      <Background className="recipeBackground" />
    </StyledWrapper>
  );
};

Recipe.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  user: PropTypes.object,
  name: PropTypes.string,
  recipeIngredients: PropTypes.array,
  image: PropTypes.string,
  cookingTime: PropTypes.string,
  isFavourite: PropTypes.bool,
  refreshData: PropTypes.func,
  numberOfPortions: PropTypes.number,
};
export default Recipe;
