import styled from 'styled-components';

const ViewName = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.secondary};
  font-size: ${({ theme }) => theme.fontSize.xxl};
  white-space: nowrap;
  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

export default ViewName;
