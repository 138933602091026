import styled from 'styled-components';

const Button = styled.button`
  text-decoration: none;
  padding: 0;
  width: 50%;
  height: 75px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.secondary};
  transition: 0.2s;
  font-size: ${({ theme }) => theme.fontSize.s};
  border: none;
  position: relative;
  bottom: 0;
  left: 0;
  cursor: pointer;
  :disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.lightSecondary};
    cursor: default;
  }
  :not(:disabled) {
    :hover {
      backdrop-filter: contrast(0.5);
    }
  }
  @media (max-width: 600px) {
    /* font-size: ${({ theme }) => theme.fontSize.xs}; */
  }
`;

export default Button;
