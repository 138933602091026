import styled from 'styled-components';

const FormErrorMessage = styled.div`
  color: ${({ theme }) => theme.red};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export default FormErrorMessage;
