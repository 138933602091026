import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { clearErrors as clearErrorsAction } from '../../actions';
import FieldErrorMessage from './FieldErrorMessage';

const StyledInputWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 60%;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const StyledInput = styled(Field)`
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.black} !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.black};
  border: none;
  border-radius: 0;
  height: 45px;

  border-bottom: 1px solid ${({ theme }) => theme.primary};
  background-color: transparent;
  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border-color: ${({ theme }) => theme.grey};
  }
  &:valid,
  &:focus,
  &.hasValue {
    ~ label {
      left: -5px;
      color: ${({ theme }) => theme.secondary};
      font-size: 0.8rem;
      top: -10px;
    }
  }

  &.is-invalid:focus ~ label {
    color: ${({ theme }) => theme.red} !important;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 10px;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: normal;
  pointer-events: none;
  transition: 0.2s ease all;
`;

const FormInput = ({
  labelName,
  disabled,
  name,
  autofocus,
  type,
  onChange,
  onBlur,
  autocomplete,
  className,
  value,
  validate,
  errors,
  touched,
  onKeyPress,
  id,
  style,
}) => {
  const dispatch = useDispatch();
  return (
    <StyledInputWrapper id={id} className={className} style={style}>
      <StyledInput
        autoFocus={autofocus}
        disabled={disabled}
        name={name}
        type={type}
        onChange={(e) => {
          dispatch(clearErrorsAction());
          onChange(e);
        }}
        onBlur={onBlur}
        validate={validate}
        autoComplete={autocomplete}
        value={value}
        onKeyPress={onKeyPress}
        required
        className={`form-control ${errors && touched ? ' is-invalid' : ''} ${
          value ? 'hasValue' : ''
        }`}
      />
      <StyledLabel>{labelName}</StyledLabel>
      {errors && <FieldErrorMessage name={name} />}
    </StyledInputWrapper>
  );
};

FormInput.propTypes = {
  labelName: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  onBlur: PropTypes.func,
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validate: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
  onKeyPress: PropTypes.func,
  amount: PropTypes.bool,
  style: PropTypes.object,
};

export default FormInput;
