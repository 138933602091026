import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  addRecipe as addRecipeAction,
  fetchRecipes as fetchRecipesAction,
  fetchIngredients as fetchIngredientsAction,
  editRecipe as editRecipeAction,
  fetchSpecificRecipe as fetchSpecificRecipeAction,
} from '../actions';
import TimePicker from '../components/atoms/TimePicker';
import AmountPicker from '../components/atoms/AmountPicker';
import MultiStepFormTemplate from '../templates/MultiStepFormTemplate';
import SelectOption from '../components/atoms/SelectOption';
import { Add } from '@styled-icons/material-rounded/Add';
import FormInput from '../components/atoms/FormInput';
import Switch from '../components/atoms/Switch';
import FormFile from '../components/atoms/FormFile';
import FormTextarea from '../components/atoms/FormTextarea';
import { SERVER_BASE_URL } from '../Config';
import { Minus } from '@styled-icons/evaicons-solid/Minus';

const StyledAmountTitle = styled.div`
  color: ${({ theme }) => theme.grey};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimePickersWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @media (max-width: 700px) {
    flex-flow: column nowrap;
  }
`;

const StyledIngredientWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: calc(100% - 20px);
  height: 80px;
  background-color: #f2f2f2;
  border-radius: 5px;
  align-items: center;
  margin: 25px auto;
  @media (max-width: 950px) {
    flex-flow: row wrap;
    height: unset;
  }
`;

const StyledIngredientFields = styled.section`
  padding: 13px 15px 25px 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  width: calc(100% - 185px);
  .select {
    width: calc(100% - 330px);
  }
  .ingredientAmount {
    width: 130px;
  }
  .annotation {
    width: 200px;
  }
  @media (max-width: 950px) {
    width: 100%;
    margin-bottom: 25px;
    flex-flow: row wrap;
    .select {
      margin-bottom: 45px;
      width: 100% !important;
    }
    .ingredientAmount {
      width: 40% !important;
    }
    .annotation {
      width: 60% !important;
    }
  }
`;

const StyledDeleteButton = styled.div`
  cursor: pointer;
  width: 170px;
  margin: 0 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px 0 25px;
  font-size: ${({ theme }) => theme.xs};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.black};
  border-radius: 0 9px 9px 0;
  background-color: ${({ theme }) => theme.primary};
  > p {
    margin: 0;
  }
  svg {
    color: ${({ theme }) => theme.red};
    padding: 0 0 5px 4px;
    width: 25px;
    height: 25px;
  }
  @media (max-width: 950px) {
    width: 100%;
    height: 80px;
    border-radius: 0 0 9px 9px;
  }
`;
const StyledAddButton = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.bold};
  cursor: pointer;
  width: fit-content;
  margin: 0 auto 25px auto;
  > svg {
    position: relative;
    width: 25px;
    height: 25px;
    bottom: 3px;
    color: ${({ theme }) => theme.green};
  }
`;

const PreparationDescription = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-top: 40px;
  padding: 0 5px 10px 5px;
  color: ${({ theme }) => theme.grey};
`;

const StepHeader = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.darkGrey};
  width: 100%;
  margin: auto;
  padding: 0 10px 10px 10px;
`;

const emptyValue = {
  ingredientFK: '',
  amount: '',
  unitOfMeasure: '',
};

Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent.filter((v) => v !== value).some((v) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

const AddEditRecipe = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = !id;
  const [recipeAmountVariant, setRecipeAmountVariant] = useState(1);
  const { ingredients, specificRecipe, token } = useSelector((state) => ({
    ingredients: state.ingredients,
    specificRecipe: state.specificRecipe,
    token: state.token,
  }));

  const dispatch = useDispatch();
  const pageNumber = '1';
  const searchString = '';
  const pageSize = 99999;

  const [initialValues, setInitialValues] = useState(
    isAddMode
      ? {
          name: '',
          description: '',
          activeCookingTime: '',
          totalCookingTime: '',
          numberOfPortions: '',
          amountVariant: '',
          preparation: '',
          image: '',
          recipeIngredients: [emptyValue],
        }
      : undefined,
  );

  useEffect(() => {
    dispatch(fetchIngredientsAction(pageNumber, searchString, pageSize));
    if (isAddMode) {
      dispatch(fetchRecipesAction(pageNumber, searchString));
    } else if (!isAddMode) {
      dispatch(fetchSpecificRecipeAction(id));
    }
  }, [dispatch, id, isAddMode]);

  useEffect(() => {}, [recipeAmountVariant]);

  useEffect(() => {
    if (!isAddMode) {
      if (specificRecipe && specificRecipe.recipe.id === id)
        setInitialValues(specificRecipe.recipe);
    }
  }, [specificRecipe, id, isAddMode]);

  const options = ingredients && ingredients.map((i) => ({ value: i.id, label: i.name }));

  // const FILE_SIZE = 200000000 * 200000000;

  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  if (!initialValues) return <></>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(100, 'Nazwa może zawierać maksymalnie 100 znaków!')
          .required('Nazwa jest wymagana!'),
        description: Yup.string()
          .max(100, 'Opis może zawierać maksymalnie 100 znaków!')
          .required('Opis jest wymagany!'),
        activeCookingTime: Yup.string(),
        totalCookingTime: Yup.string(),
        numberOfPortions: Yup.number(),
        amountVariant: Yup.string(),
        preparation: Yup.string()
          .min(20, 'Przygotowanie musi zawierać co najmniej 20 znaków!')
          .max(10000, 'Przygotowanie może zawierać maksymalnie 10000 znaków!')
          .required('Przygotowanie jest wymagane!'),
        imageUrl: Yup.mixed().required('Plik jest wymagany!'),
        recipeIngredients: Yup.array().of(
          Yup.object()
            .shape({
              ingredientFK: Yup.string().required('Wybierz składnik!'),
              annotation: Yup.string(),
              amount: Yup.number()
                // .typeError('Ilość musi być liczbą!')
                .typeError('Pole wymagane!')
                .min(0.001, 'Minimalna ilość to 0.001!')
                .max(10000, 'Ilość nie może być większa niż 10000!')
                .required('Ilość jest wymagana!'),

              // unitOfMeasure: Yup.string().required('Field is required!'),
            })
            .unique('ingredientFK', 'Składnik musi być unikatowy!'),
        ),
      })}
      onSubmit={(values, recipeAmountVariant) => {
        if (isAddMode) {
          dispatch(addRecipeAction(values, recipeAmountVariant))
            .then(() => navigate(`/recipes`));
        } else if (!isAddMode) {
          dispatch(editRecipeAction(values, recipeAmountVariant, id));
        }
      }}
    >
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue, validateForm }) => (
        <MultiStepFormTemplate
          title={isAddMode ? 'Nowy przepis' : 'Edytuj przepis'}
          steps={7}
          nextButtonDisabled={(currentStep) => {
            switch (currentStep) {
              case 1:
                return errors.name || !values.name;
              case 2:
                return errors.description || !values.description;
              case 3:
                return (
                  errors.activeCookingTime ||
                  !values.activeCookingTime ||
                  errors.totalCookingTime ||
                  !values.totalCookingTime
                );
              case 4:
                return (
                  errors.numberOfPortions ||
                  !values.numberOfPortions ||
                  errors.amountVariant ||
                  !values.amountVariant
                );
              case 5:
                return errors.preparation || !values.preparation;
              case 6:
                return errors.imageUrl || !values.imageUrl;
              case 7:
                return errors.recipeIngredients || !values.recipeIngredients;
              default:
                return false;
            }
          }}
          createChild={(currentStep) => {
            switch (currentStep) {
              case 1:
                return (
                  <FormInput
                    autofocus
                    labelName="Nazwa"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autocomplete="off"
                    value={values.name}
                    onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : undefined)}
                    errors={errors.name}
                    touched={touched.name}
                  />
                );
              case 2:
                return (
                  <FormInput
                    autofocus
                    labelName="Opis"
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autocomplete="off"
                    value={values.description}
                    errors={errors.description}
                    touched={touched.description}
                  />
                );
              case 3:
                return (
                  <TimePickersWrapper>
                    <TimePicker
                      title="Aktywny czas przygotowania"
                      subtitle="Opcjonalnie"
                      name="activeCookingTime"
                      onChange={(e) => {
                        setFieldValue('activeCookingTime', e.hours + ':' + e.minutes);
                        touched.activeCookingTime = true;
                        validateForm();
                      }}
                      value={{
                        hours: parseInt(values.activeCookingTime.split(':')[0]),
                        minutes: parseInt(values.activeCookingTime.split(':')[1]),
                      }}
                    />
                    <TimePicker
                      title="Całkowity czas przygotowania"
                      subtitle="Opcjonalnie"
                      name="totalCookingTime"
                      onChange={(e) => {
                        setFieldValue('totalCookingTime', e.hours + ':' + e.minutes);
                        touched.totalCookingTime = true;
                        validateForm();
                      }}
                      value={{
                        hours: parseInt(values.totalCookingTime.split(':')[0]),
                        minutes: parseInt(values.totalCookingTime.split(':')[1]),
                      }}
                    />
                  </TimePickersWrapper>
                );
              case 4:
                return (
                  <>
                    <StyledAmountTitle activeVariant={recipeAmountVariant}>
                      Liczba
                      <Switch
                        name="amountVariant"
                        onChange={(e) => {
                          setFieldValue('amountVariant', e);
                          touched.amountVariant = true;
                          validateForm();
                        }}
                        firstVariantTitle="porcji"
                        secondVariantTitle="sztuk"
                        activeVariant={recipeAmountVariant}
                        onVariantChange={(e) => setRecipeAmountVariant(e)}
                        value={values.amountVariant}
                      />
                    </StyledAmountTitle>
                    <AmountPicker
                      twoTitlesVariant
                      activeVariant={recipeAmountVariant}
                      firstVariantTitle="Porcje"
                      secondVariantTitle="Sztuki"
                      min="1"
                      max="50"
                      name="numberOfPortions"
                      onChange={(e) => {
                        setFieldValue('numberOfPortions', e);
                        touched.numberOfPortions = true;
                        validateForm();
                      }}
                      value={values.numberOfPortions}
                    />
                  </>
                );
              case 5:
                return (
                  <>
                    <FormTextarea
                      autofocus
                      labelName="Przygotowanie"
                      name="preparation"
                      rows="3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.preparation}
                      errors={errors.preparation}
                      touched={touched.preparation}
                    />
                    <PreparationDescription>
                      W celu przygotowania numerowanej listy dodaj * przed każdą sekcją.
                      {/* If you want to create a list of tasks add '*' before every section. */}
                    </PreparationDescription>
                  </>
                );
              case 6:
                return (
                  <>
                    <FormFile
                      name="imageUrl"
                      file="file"
                      errors={errors.imageUrl}
                      touched={touched.imageUrl}
                      value={values.imageUrl}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const image = event.target.files[0];
                        var formData = new FormData();
                        formData.append('image', image);
                        return axios
                          .post(`${SERVER_BASE_URL}/images`, formData, {
                            headers: {
                              Authorization: token,
                              'Content-Type': 'multipart/form-data',
                            },
                          })
                          .then(({ data }) => {
                            setFieldValue('imageUrl', data);
                          })
                          .catch((err) => {
                            // if (onError) onError(err);
                            // console.log(err);
                          });
                      }}
                    />
                  </>
                );
              case 7:
                return (
                  <FieldArray
                    name="recipeIngredients"
                    render={(arrayHelpers) => (
                      <>
                        <StepHeader>Lista składników</StepHeader>
                        {values.recipeIngredients.map((ingredient, index) => {
                          return (
                            <StyledIngredientWrapper key={index}>
                              <StyledIngredientFields>
                                <SelectOption
                                  className="select"
                                  labelName="Nazwa"
                                  name={`recipeIngredients.${index}.ingredientFK`}
                                  onChange={setFieldValue}
                                  onBlur={() => {
                                    touched.recipeIngredients = touched.recipeIngredients ?? [];
                                    touched.recipeIngredients[index] =
                                      touched.recipeIngredients[index] ?? {};
                                    touched.recipeIngredients[index].ingredientFK = true;
                                    validateForm();
                                  }}
                                  options={options}
                                  touched={touched?.recipeIngredients?.[index]?.ingredientFK}
                                  errors={errors.recipeIngredients?.[index]?.ingredientFK}
                                  value={ingredient.ingredientFK}
                                />
                                <FormInput
                                  labelName="Ilość(gr)"
                                  name={`recipeIngredients.${index}.amount`}
                                  type="number"
                                  className="ingredientAmount"
                                  onChange={(e) => {
                                    let value = e.target.value || '';
                                    setFieldValue(`recipeIngredients.${index}.amount`, value);
                                  }}
                                  onBlur={handleBlur}
                                  autocomplete="off"
                                  value={ingredient.amount}
                                  errors={errors?.recipeIngredients?.[index]?.amount}
                                  touched={touched?.recipeIngredients?.[index]?.amount}
                                />
                                <FormInput
                                  className="annotation"
                                  style={{ width: '250px', margin: '0' }}
                                  labelName="Adnotacja(opcjonalnie)"
                                  name={`recipeIngredients.${index}.annotation`}
                                  type="text"
                                  onChange={(e) => {
                                    let value = e.target.value || '';
                                    setFieldValue(`recipeIngredients.${index}.annotation`, value); }} onBlur={handleBlur}
                                  autocomplete="off"
                                  value={ingredient.annotation}
                                  errors={errors?.recipeIngredients?.[index]?.annotation}
                                  touched={touched?.recipeIngredients?.[index]?.annotation}
                                />
                              </StyledIngredientFields>
                              <StyledDeleteButton
                                onClick={() => arrayHelpers.remove(index)}
                                className="deleteButton"
                              >
                                <p>
                                  Usuń składnik
                                  <Minus />
                                </p>
                              </StyledDeleteButton>
                            </StyledIngredientWrapper>
                          );
                        })}
                        <StyledAddButton onClick={() => arrayHelpers.push(emptyValue)}>
                          Dodaj nowy składnik
                          <Add />
                        </StyledAddButton>
                      </>
                    )}
                  />
                );

              default:
                return null;
            }
          }}
        />
      )}
    </Formik>
  );
};

AddEditRecipe.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
};

AddEditRecipe.defaultProps = {
  ingredients: [],
  recipes: [],
};

export default AddEditRecipe;
