import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { fetchNutrientsGoals as fetchNutrientsGoalsAction } from '../actions/index';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import { routes } from '../routes';

const NutrientsGoalsList = styled.section`
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px 0;
`;

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  &:last-child .date > p:last-child {
    display: block;
  }
`;

const Date = styled.h3`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.xs};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: right;
  > p {
    &:after {
      content: '';
      position: absolute;
      right: -25px;
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.grey};
      background-color: ${({ theme }) => theme.white};
      width: 15px;
      height: 15px;
    }
    &:last-child {
      display: none;
      margin-bottom: -12px;
      :after {
        bottom: -12px;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -18px;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.grey};
  }
`;

const NutrientsGoal = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 20px 0 5px 35px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.lightSecondary};
  border-radius: 5px;
  @media (max-width: 750px) {
    flex-flow: column wrap;
    width: 100%;
    min-width: 80px;
    > div {
      width: 100%;
      justify-content: space-between;
      display: flex;

      > span {
        margin-right: 15px;
      }
    }
  }
`;

const StyledNutrient = styled.div`
  width: 16.66%;
  padding: 10px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  ${props =>
    props.changed &&
    css`
      background-color: ${({ theme }) => theme.secondary} !important;
    `}
  @media (max-width: 530px) {
    width: 90px;
  }
  > span {
    display: block;
    font-weight: 600;
  }
`;

const NutrientsGoalTimeline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nutrientsGoals = useSelector(state => state.nutrientsGoals);

  useEffect(() => {
    dispatch(fetchNutrientsGoalsAction());
    // eslint-disable-next-line
  }, []);
  if (!nutrientsGoals) return <></>;
  return (
    <ManageUserFormTemplate
      onSubmit={() => navigate(routes.nutrientsGoal)}
      buttonText="Dodaj nowy wpis"
      // header="Nutrients goal timeline"
      header="Twoje cele"
    >
      <NutrientsGoalsList>
        {nutrientsGoals.length === 0 ? (
          <h5 style={{ margin: '0', fontStyle: 'italic', fontSize: '15px' }}>
            Nie dodałeś jeszcze żadnych celów
          </h5>
        ) : (
          nutrientsGoals.map((goal, index) => (
            <StyledWrapper key={index}>
              <Date className="date">
                <p>{goal.from.split('T')[0].replaceAll('-', '.')}</p>
                <p>teraz</p>
              </Date>
              <NutrientsGoal key={index}>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1]
                      ? goal.calories !== nutrientsGoals[index - 1].calories
                      : false
                  }
                >
                  <span>Kalorie</span>
                  {goal.calories}
                </StyledNutrient>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1] ? goal.fats !== nutrientsGoals[index - 1].fats : false
                  }
                >
                  <span>Tłuszcze</span> {goal.fats}
                </StyledNutrient>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1]
                      ? goal.proteins !== nutrientsGoals[index - 1].proteins
                      : false
                  }
                >
                  <span>Białko</span>
                  {goal.proteins}
                </StyledNutrient>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1]
                      ? goal.carbohydrates !== nutrientsGoals[index - 1].carbohydrates
                      : false
                  }
                >
                  <span>Węgle</span>
                  {goal.carbohydrates}
                </StyledNutrient>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1]
                      ? goal.sugars !== nutrientsGoals[index - 1].sugars
                      : false
                  }
                >
                  <span>Cukry</span> {goal.sugars}
                </StyledNutrient>
                <StyledNutrient
                  changed={
                    nutrientsGoals[index - 1] ? goal.salt !== nutrientsGoals[index - 1].salt : false
                  }
                >
                  <span>Sól</span> {goal.salt}
                </StyledNutrient>
              </NutrientsGoal>
            </StyledWrapper>
          ))
        )}
      </NutrientsGoalsList>
    </ManageUserFormTemplate>
  );
};

NutrientsGoalTimeline.propTypes = {
};

export default NutrientsGoalTimeline;
