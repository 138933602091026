import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { fetchShoppingList as fetchShoppingListAction } from '../actions';
import DayPicker from '../components/organisms/DayPicker';
import { Shop } from '@styled-icons/bootstrap/Shop';
import { FormattedDate } from '../helpers/Functions';
import { routes } from '../routes';
import { Link } from 'react-router-dom';
import CarrotColor from '!file-loader!../assets/BoldCarrotColor.svg';
import ViewName from '../components/atoms/ViewName';

const StyledFieldset = styled.fieldset`
  width: 50%;
  padding: 10px;
  margin: auto;
  margin-top: 30px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.black};
  @media (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
  legend {
    text-align: center;
    width: unset;

    > svg {
      width: 40px;
      width: 40px;
    }
  }
`;

const StyledIcon = styled(Shop)`
  margin: 0 5px;
  color: ${({ theme }) => theme.secondary};
`;

const Carrot = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(${CarrotColor});
  background-size: cover;
`;

const StyledDescription = styled.h4`
  margin: 0 80px;
  text-transform: uppercase;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.black};
  @media (max-width: 1000px) {
    letter-spacing: 1px;
  }
  @media (max-width: 650px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`;

const StyledEmptyTitle = styled.h1`
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.s};
  margin: 0;
  text-align: center;
  padding: 0;
  a {
    color: ${({ theme }) => theme.secondary};
    :hover {
      font-weight: ${({ theme }) => theme.bold};
      text-decoration: none;
    }
  }
  @media (max-width: 700px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

const TableRow = styled.tr`
  transition: 0.3s;
  font-size: ${({ theme }) => theme.fontSize.xs};

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
  :first-child {
    > td {
      border-top: none;
    }
  }
  > td {
    .annotation {
      color: ${({ theme }) => theme.secondary};
      font-style: italic;
      font-weight: ${({ theme }) => theme.bold};
    }
    border-color: ${({ theme }) => theme.primary} !important;
    :nth-child(1) {
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }
    :nth-child(2) {
      text-align: right;
    }
    :nth-child(3) {
      width: 60px;
      padding-left: 0;
    }
  }
  :hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
`;

const ShoppingList = () => {
  const shoppingList = useSelector((state) => state.shoppingList);
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([FormattedDate(new Date())]);
  useEffect(() => {
    dispatch(fetchShoppingListAction(selectedDays));
  }, [dispatch, selectedDays]);

  useEffect(() => {
    console.log(shoppingList);
  }, [shoppingList]);
  return (
    <>
      <ViewName style={{ marginTop: '10px', textAlign: 'center' }}>Lista zakupów</ViewName>
      <StyledDescription>
        Wybierz daty, dla których chcesz wygenerować listę zakupów
      </StyledDescription>
      <DayPicker
        fetchSelectedDaysToParent={(days) => {
          setSelectedDays(days.map((day) => FormattedDate(day)));
          dispatch(fetchShoppingListAction(selectedDays));
        }}
        multipleDaySelect={true}
        daysCount={7}
      />
      <StyledFieldset>
        <legend>
          <StyledIcon size="50" />
        </legend>
        {shoppingList >= '1' ? (
          <Table style={{ textAlign: 'center', margin: '0', position: 'relative', bottom: '10px' }}>
            <tbody>
              {shoppingList.map(({ name, annotation, amount, unitOfMeasure }) => (
                <TableRow key={name}>
                  <td>
                    {name}
                    {annotation && <span className="annotation"> ({annotation})</span>}
                  </td>
                  <td>{amount}</td>
                  <td>{unitOfMeasure}</td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        ) : (
          <StyledEmptyTitle>
            Lista jest pusta. <br />
            Dodaj coś do swojej{' '}
            <Link to={routes.diet}>
              diety <Carrot />
            </Link>
          </StyledEmptyTitle>
        )}
      </StyledFieldset>
    </>
  );
};

export default ShoppingList;
