import React, { useState } from 'react';
import {
  verifyRecipe as verifyRecipeAction,
  rejectRecipe as rejectRecipeAction,
} from '../../actions';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { Minus } from '@styled-icons/boxicons-regular/Minus';
import { Send } from '@styled-icons/feather/Send';
import { Person } from '@styled-icons/bootstrap/Person';
import { Hourglass } from '@styled-icons/bootstrap/Hourglass';

const RecipeContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-flow: column nowrap;
  margin: 10px;
  max-width: 700px;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(125, 125, 125, 0.3);
  box-shadow: 5px 5px 15px ${({ theme }) => theme.primary};
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const StyledImage = styled.div`
  background-image: url(${(props) => props.src});
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  @media (max-width: 650px) {
    max-width: 400px;
    max-height: 400px;
    min-width: 400px;
    min-height: 400px;
  }
  @media (max-width: 400px) {
    max-width: 75vw;
    max-height: 75vw;
    min-width: 75vw;
    min-height: 75vw;
  }
`;

const RecipeInfo = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-style: italic;
  svg {
    color: ${({ theme }) => theme.grey};
  }
  > p {
    margin-right: auto;
  }
  @media (max-width: 650px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    > p {
      margin: 0;
    }
  }
`;

const StyledContent = styled.section`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

const StyledWrapper = styled.section`
  position: relative;
  text-align: center;
  > h2 {
    font-size: ${({ theme }) => theme.fontSize.l};
    font-family: ${({ theme }) => theme.primaryFont};
    overflow-wrap: anywhere;
  }
  :first-child {
    margin-right: 20px;
    @media (max-width: 650px) {
      margin: 0 auto;
    }
  }
`;

const StyledPreparation = styled.ol`
  list-style: none;
  text-align: justify;
  margin: 0 20px;
  padding: 0;
  counter-reset: item;
  position: relative;
  bottom: 13px;
  @media (max-width: 650px) {
    padding: 0;
  }
`;

const PreparationItem = styled.li`
  counter-increment: item;
  font-size: ${({ theme }) => theme.fontSize.xs};
  overflow-wrap: anywhere;
  text-align-last: center;
  &:before {
    content: counter(item);
    color: ${({ theme }) => theme.grey};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: ${({ theme }) => theme.fontSize.l};
    height: 10px;
    width: 1.2em;
    text-align: center;
    display: inline-block;
  }
`;

const BottomWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.primary};
  > h5 {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-style: italic;
  }
`;

const IconsWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
  > svg {
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius: 8px;
    margin: 0 5px;
    transition: 0.3s;
    :hover {
      color: ${({ theme }) => theme.secondary};
      border-color: ${({ theme }) => theme.secondary};
    }
  }
`;

const StyledStatus = styled.div`
  strong {
    font-size: ${({ theme }) => theme.fontSize.xs};
    ${(props) =>
      props.status === 'Requested' &&
      css`
        color: ${({ theme }) => theme.orange};
      `}
    ${(props) =>
      props.status === 'Accepted' &&
      css`
        color: ${({ theme }) => theme.green};
      `}

      ${(props) =>
      props.status === 'Rejected' &&
      css`
        color: ${({ theme }) => theme.red};
      `}
  }
`;

const StyledMessageField = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.primary};
  padding: 15px;
  position: absolute;
  width: 400px;
  height: fit-content;
  top: -150px;
  left: 90px;
  flex-direction: column;
  justify-content: flex-end;
  display: none;
  ${(props) =>
    props.$active ? 
    css`
      display: flex;
    ` : undefined}

  >label {
    margin: 5px 0 0 3px;
    font-size: ${({ theme }) => theme.fontSize.s};

    padding: 0;
    color: ${({ theme }) => theme.black};
    font-family: ${({ theme }) => theme.primaryFont};
  }

  > textarea {
    border: 1px solid ${({ theme }) => theme.primary};
    &:focus {
      box-shadow: none !important;
      border: 2px solid ${({ theme }) => theme.lightSecondary};
    }
  }

  > svg {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 15px;
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
    margin-left: auto;
    color: ${({ theme }) => theme.secondary};
  }
`;

const AdminRecipeCard = ({ id, fetchRequests, recipe, status }) => {
  const [activeMessageField, setActiveMessageField] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  let activeCookingTime = undefined;
  if (recipe.activeCookingTime) {
    let split = recipe.activeCookingTime.split(':').map((x) => parseInt(x));
    if (split.length === 2) {
      let [hours, minutes] = split;
      if (hours > 0 || minutes > 0) activeCookingTime = { hours, minutes };
    }
  }

  return (
    <div style={{ gridRowEnd: 'span 250' }} className="grid-item">
      <RecipeContainer className="content">
        <StyledContent>
          <StyledWrapper>
            <StyledImage className="img" src={recipe.imageUrl} />
            <RecipeInfo style={{ whiteSpace: 'nowrap' }}>
              <p style={{ whiteSpace: 'nowrap' }}>
                {activeCookingTime && <Hourglass size="25" />}
                {activeCookingTime &&
                  activeCookingTime.hours > 0 &&
                  ' ' + activeCookingTime.hours + 'h'}
                {activeCookingTime &&
                  activeCookingTime.minutes > 0 &&
                  ' ' + activeCookingTime.minutes + 'min'}
              </p>
              <p style={{ whiteSpace: 'nowrap' }}>
                <Person size="25" /> {recipe.numberOfPortions}
              </p>
            </RecipeInfo>
          </StyledWrapper>
          <StyledWrapper style={{ width: '100%' }}>
            <h2>{recipe.name}</h2>
            <ul style={{ padding: '0' }} id="preparation">
              <StyledPreparation>
                {recipe.preparation
                  .split('*')
                  .filter((pi) => pi.trim() !== '')
                  .map((pi, index) => (
                    <PreparationItem key={index}>{pi}</PreparationItem>
                  ))}
              </StyledPreparation>
            </ul>
          </StyledWrapper>
        </StyledContent>
        <BottomWrapper>
          <IconsWrapper>
            {status && status === 'Requested' && (
              <>
                <Check
                  size="34"
                  title="Accept"
                  onClick={() => dispatch(verifyRecipeAction(id)).then(fetchRequests)}
                />
                <Minus size="34" title="Reject" onClick={() => setActiveMessageField((x) => !x)} />
                <StyledMessageField $active={activeMessageField}>
                  <Send
                    onClick={() => dispatch(rejectRecipeAction(id, message)).then(fetchRequests)}
                  />
                  <textarea
                    name="message"
                    rows="3"
                    className="form-control message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <label htmlFor="message">Wiadomość</label>
                </StyledMessageField>
              </>
            )}
          </IconsWrapper>
          <StyledStatus status={status}>
            Status:{' '}
            <strong>
              {status === 'Requested' && 'Oczekujący'}
              {status === 'Accepted' && 'Zaakceptowany'}
              {status === 'Rejected' && 'Odrzucony'}
            </strong>
          </StyledStatus>
        </BottomWrapper>
      </RecipeContainer>
    </div>
  );
};

AdminRecipeCard.propTypes = {
  id: PropTypes.string,
  fetchRequests: PropTypes.func,
  recipe: PropTypes.object,
  status: PropTypes.string,
};

export default AdminRecipeCard;
