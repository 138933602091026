import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { routes } from '../routes';
import Pagination from '../components/organisms/Pagination';
import NewItemButton from '../components/atoms/NewItemButton';
import {
  fetchUserRecipes as fetchUserRecipesAction,
  requestToVerify as requestToVerifyAction,
  removeRecipe as removeRecipeAction,
} from '../actions/index';
import UserRecipeCard from '../components/molecules/UserRecipeCard';
import Searchbar from '../components/molecules/Searchbar';
import ViewName from '../components/atoms/ViewName';

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 35px;

  #recipesSearchbar,
  #newItemButtonContainer {
    width: 33%;
  }

  #recipesHeader {
    width: calc(100% - 66%);
    text-align: center;
  }

  @media (max-width: 1000px) {
    padding: 0 10px;
    flex-flow: row-reverse wrap;
    height: unset;

    #recipesHeader,
    #newItemButtonContainer {
      width: fit-content;
    }

    #recipesSearchbar {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.primary};
      margin: 10px 0;
      padding: 0 5px;
      svg {
        color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

const RecipesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  width: 80%;
  margin: 20px auto;
  justify-content: center;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const defaultQueryParams = { page: '1', searchPhrase: '' };
const Recipes = ({ }) => {
  const [searchVariant, setSearchVariant] = useState('Name');

  const _queryParams = { ...defaultQueryParams };
  new URLSearchParams(window.location.search).forEach((value, key) => (_queryParams[key] = value));
  const [queryParams, setQueryParams] = useState(_queryParams);
  const setPage = (page) => setQueryParams({ ...queryParams, page });
  const { userRecipes, numberOfUserRecipesFound } = useSelector((state) => ({
    userRecipes: state.userRecipes,
    numberOfUserRecipesFound: state.numberOfUserRecipesFound,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserRecipesAction(queryParams.page, queryParams.searchPhrase));
    let { origin, pathname, search, hash } = window.location;
    search = Object.entries(queryParams)
      .filter(([key, value]) => defaultQueryParams[key] !== value)
      .map(([key, value]) => key + (value ? '=' + value : ''))
      .join('&');
    if (search) search = '?' + search;
    const newUrl = `${origin}${pathname}${search}${hash}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }, [queryParams, dispatch]);

  useEffect(() => {
    setSearchVariant(searchVariant);
  }, [searchVariant]);

  return (
    <>
      <HeaderContainer>
        <div id="newItemButtonContainer">
        <Link to={routes.addRecipe}>
          <NewItemButton content="Dodaj przepis" />
        </Link>
        </div>
        <ViewName id="recipesHeader">Twoje przepisy</ViewName>
        <Searchbar
          fewVariants
          searchVariant={searchVariant}
          setSearchVariant={setSearchVariant}
          id="recipesSearchbar"
          placeholder={queryParams.searchPhrase || 'Wyszukaj przepis...'}
          onEnter={(searchPhrase) => {
            setQueryParams({ searchPhrase: searchPhrase, page: '1' });
          }}
        />
      </HeaderContainer>
      <RecipesWrapper>
        {userRecipes &&
          userRecipes.map((recipe, index) => (
            <UserRecipeCard
              key={index}
              id={recipe.id}
              recipe={recipe}
              verifications={recipe.verifications}
              handleRecipeDelete={() =>
                dispatch(removeRecipeAction(recipe.id)).then(() =>
                  dispatch(fetchUserRecipesAction(queryParams.page, queryParams.searchPhrase)),
                )
              }
              handleRecipePublish={() =>
                dispatch(requestToVerifyAction(recipe.id)).then(() =>
                  dispatch(fetchUserRecipesAction(queryParams.page, queryParams.searchPhrase)),
                )
              }
            />
          ))}
      </RecipesWrapper>
      <Pagination
        onClick={setPage}
        active={queryParams.page}
        numberOfPages={numberOfUserRecipesFound / 12}
      />
    </>
  );
};

Recipes.propTypes = {
  userRecipes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      preparation: PropTypes.string.isRequired,
    }),
  ),
  numberOfUserRecipesFound: PropTypes.number,
};

Recipes.defaultProps = {
  userRecipes: [],
  numberOfUserRecipesFound: 0,
};

export default Recipes;
