import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { KeyboardArrowRight } from '@styled-icons/material-rounded/KeyboardArrowRight';

const StyledContainer = styled.div`
  position: fixed;
  left: calc(50% + 32px);
  transform: translateX(-50%);
  width: 75%;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary};
  z-index: 8;
  margin: 0 auto;
  padding: 10px 20px;
  @media (max-width: 1500px) {
    width: 90%;
  }
  @media (max-width: 1000px) {
    padding: 10px 0;
  }
  @media (max-width: 450px) {
    width: 100%;
    left: 50%;
    bottom: 65px;
    padding: 0;
    border-radius: 0;
  }
  > svg {
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
    right: 0;
    width: 18px;
    height: 18px;
    color: grey;
    @media (min-width: 1000px) {
      display: none;
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    @media (max-width: 750px) {
      /* opacity: 0.5; */
    }
  }
  .swiper-slide-active {
    /* opacity: 1; */
  }
  .slide:last-child > div:before {
    display: none;
  }
`;

const StyledMacronutrient = styled.div`
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.black};
  transition: transform 0.3s color 0s;
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0 4px;
  ${props =>
    props.$surplus &&
    props.goal > 0 &&
    css`
       {
        color: ${({ theme }) => theme.red};
      }
    `}
  &:hover {
    p,
    strong {
      transform: translateY(-40%);
    }

    p:last-child {
      opacity: 1;
      top: 60%;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
  > strong {
    margin-right: 5px;
    transition: 0.5s;
    font-size: ${({ theme }) => theme.fontSize.xs};
    pointer-events: none;
  }
  > p {
    position: relative;
    pointer-events: none;
    margin: 0;
    padding: 0;
    transition: 0.5s;

    &:last-child {
      display: block;
      position: absolute;
      top: 70%;
      right: 0%;
      opacity: 0;
      transform: translate(-50%, -50%);
      color: ${({ theme }) => theme.grey};
    }
  }
`;

const DaySummary = ({ meals, nutrientsGoal }) => {
  nutrientsGoal = nutrientsGoal ? nutrientsGoal : 0;
  const emptyMacronutrientsSummary = {
    calories: 0,
    fats: 0,
    proteins: 0,
    carbs: 0,
    sugars: 0,
    salt: 0,
  };
  const [macronutrientsSummary, setMacronutrientsSummary] = useState(emptyMacronutrientsSummary);

  const { currentNutrientsGoal } = useSelector(state => ({
    currentNutrientsGoal: state.currentNutrientsGoal,
  }));
  useEffect(() => {
    if (meals) {
      setMacronutrientsSummary(
        meals
          .map(meal => meal.macronutrientsToEat)
          .reduce((accumulator, current) => {
            return {
              calories: accumulator.calories + current.calories,
              fats: accumulator.fats + current.fats,
              proteins: accumulator.proteins + current.proteins,
              carbs: accumulator.carbs + current.carbs,
              sugars: accumulator.sugars + current.sugars,
              salt: accumulator.salt + current.salt,
            };
          }, emptyMacronutrientsSummary),
      );
    }
    // eslint-disable-next-line
  }, [meals]);
  return (
    <StyledContainer>
      <StyledSwiper
        breakpoints={{
          1: {
            slidesPerView: 2,
            centeredSlides: true,
          },
          300: {
            slidesPerView: 3,
            centeredSlides: false,
          },
          600: {
            slidesPerView: 4,
            centeredSlides: false,
          },
          800: {
            slidesPerView: 5,
            centeredSlides: false,
          },
          1000: {
            slidesPerView: 6,
            centeredSlides: false,
          },
        }}
      >
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.calories}
            $surplus={macronutrientsSummary.calories.toFixed(1) > nutrientsGoal.calories}
          >
            <strong>Kalorie</strong>
            <p>{macronutrientsSummary.calories.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.calories &&
                `/ ${currentNutrientsGoal.calories} kcal`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.fats}
            $surplus={macronutrientsSummary.fats.toFixed(1) > nutrientsGoal.fats}
          >
            <strong>Tłuszcze</strong>
            <p>{macronutrientsSummary.fats.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.fats &&
                `/ ${currentNutrientsGoal.fats} g`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.proteins}
            $surplus={macronutrientsSummary.proteins.toFixed(1) > nutrientsGoal.proteins}
          >
            <strong>Białko</strong>
            <p>{macronutrientsSummary.proteins.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.proteins &&
                `/ ${currentNutrientsGoal.proteins} g`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.carbohydrates}
            $surplus={macronutrientsSummary.carbs.toFixed(1) > nutrientsGoal.carbohydrates}
          >
            <strong>Węgle</strong>
            <p>{macronutrientsSummary.carbs.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.carbohydrates &&
                `/ ${currentNutrientsGoal.carbohydrates} g`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.sugars}
            $surplus={macronutrientsSummary.sugars.toFixed(1) > nutrientsGoal.sugars}
          >
            <strong>Cukry</strong>
            <p>{macronutrientsSummary.sugars.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.sugars &&
                `/ ${currentNutrientsGoal.sugars} g`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <StyledMacronutrient
            goal={nutrientsGoal.salt}
            $surplus={macronutrientsSummary.salt.toFixed(1) > nutrientsGoal.sugars}
          >
            <strong>Sól</strong>
            <p>{macronutrientsSummary.salt.toFixed(1)}</p>
            <p>
              {currentNutrientsGoal &&
                currentNutrientsGoal.salt &&
                `/ ${currentNutrientsGoal.salt} g`}
            </p>
          </StyledMacronutrient>
        </SwiperSlide>
      </StyledSwiper>
      <KeyboardArrowRight />
    </StyledContainer>
  );
};

DaySummary.propTypes = {
  meals: PropTypes.array,
  nutrientsGoal: PropTypes.object,
};

export default DaySummary;
