import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  resetPassword as resetPasswordAction,
  clearErrors as clearErrorsAction,
} from '../actions/index';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 60px auto;
  width: 80%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ResetPassword = () => {
  const resetPasswordErrors = useSelector(state => state.resetPasswordErrors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ email: '', password: '', confirmPassword: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email jest wymagany!'),
        password: Yup.string()
          .min(6, 'Hasło musi zawierać co najmniej 6 znaków!')
          .required('Hasło jest wymagane!'),
        confirmPassword: Yup.string()
          .required('Powtórz hasło!')
          .when('newPassword', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('newPassword')], 'Hasła muszą być takie same!'),
          }),
      })}
      onSubmit={({ email, password, confirmPassword }) => {
        const token = new URLSearchParams(window.location.search).get('token');
        dispatch(resetPasswordAction(token, email, password, confirmPassword));
      }}
    >
      {({ handleChange, handleBlur, errors, values, touched, submitForm }) => {
        return (
          <ManageUserFormTemplate
            header="Resetuj hasło"
            buttonText="Resetuj"
            onSubmit={() => submitForm()}
            errors={resetPasswordErrors}
          >
            <StyledFormInput
              labelName="Email"
              name="email"
              type="email"
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.email}
              touched={touched.email}
              value={values.email}
            />
            <StyledFormInput
              labelName="Hasło"
              name="password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.password}
              touched={touched.password}
              value={values.password}
            />
            <StyledFormInput
              labelName="Powtórz nowe hasło"
              name="confirmPassword"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.confirmPassword}
              touched={touched.confirmPassword}
              value={values.confirmPassword}
            />
            {resetPasswordErrors &&
              resetPasswordErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
