import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"; 
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { logout as logoutAction } from '../actions';
import { LogOut } from '@styled-icons/ionicons-outline/LogOut';
import { routes } from '../routes';
import {
  fetchCurrentNutrientsGoal as fetchCurrentNutrientsGoalAction,
  disableSleepMode as disableSleepModeAction,
  enableSleepMode as enableSleepModeAction,
} from '../actions/index';
// import { Food } from '@styled-icons/fluentui-system-regular/Food';
import { Settings } from '@styled-icons/feather/Settings';
import { ClipboardData } from '@styled-icons/bootstrap/ClipboardData';
import blueMoon from '!file-loader!../assets/blueMoon.svg';
import moonOff from '!file-loader!../assets/moonOff.svg';

const StyledWrapper = styled.div`
  width: 850px;
  margin: 100px auto 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(150, 150, 150, 0.5);
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 450px) {
    padding: 10px;
    margin: 20px auto 10px auto;
    width: calc(100% - 20px);
    box-shadow: none;
  }
`;

const StyledUserName = styled.h1`
  width: 80%;
  text-align: center;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xxl};
  padding: 10px;
  margin: 0;
  word-break: break-word;
  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const ActionsWrapper = styled.section`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 750px) {
    flex-flow: column nowrap;
  }
`;

const UserInfoContainer = styled.div`
  width: 200px;
  height: 250px;
  @media (max-width: 1000px) {
    width: 30%;
  }

  @media (max-width: 950px) {
    margin: 30px 5px;
  }
  
  @media (max-width: 750px) {
    width: 80%;
    height: 150px;
    margin: 15px 5px;
    justify-content: center;
  }
  margin: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  cursor: pointer;

  > svg {
    margin: 60px auto 0 auto;
    width: 60px;
    height: 60px;
    color: ${({ theme }) => theme.secondary};
    @media (max-width: 750px) {
      width: 35px;
      height: 35px;
      margin: 0 auto;
    }
  }

  > p {
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.bold};
    color: ${({ theme }) => theme.black};
    overflow: hidden;
    // white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    margin: 10px 0 0 0;
    // @media (max-width: 1000px) {
    //   font-size: ${({ theme }) => theme.fontSize.xs};
    // }
    // @media (max-width: 550px) {
    //   font-size: ${({ theme }) => theme.fontSize.xxs};
    // }
  }
`;

const StyledLogout = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.l};
  margin: auto;
  width: fit-content;
  font-weight: ${({ theme }) => theme.light};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.primaryFont};
  > svg {
    margin-left: 10px;
  }
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const MoonIcon = styled.div`
  background-image: url(${blueMoon});
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  margin: 60px auto 0 auto;
  height: 60px;
  @media (max-width: 750px) {
    width: 35px;
    height: 35px;
    margin: 0 auto;
  }
  ${(props) =>
    props.$sleepMode ?
    css`
      background-image: url(${moonOff});
    ` : undefined}
`;

const Account = () => {
  const navigate = useNavigate();
  const sleepMode = useSelector((state) => state.sleepMode);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrentNutrientsGoalAction());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <StyledWrapper>
        <StyledUserName>Witaj, {user.username}</StyledUserName>
        <ActionsWrapper>
          <UserInfoContainer onClick={() => navigate(routes.userData)}>
            <Settings />
            <p>Ustawienia konta</p>
          </UserInfoContainer>
          <UserInfoContainer onClick={() => navigate(routes.nutrientsGoalTimeline)}>
            <ClipboardData />
            <p>Ustaw cele odżywcze</p>
          </UserInfoContainer>
          <UserInfoContainer
            onClick={() => {
              if (!sleepMode) dispatch(enableSleepModeAction());
              else dispatch(disableSleepModeAction());
            }}
          >
            <MoonIcon $sleepMode={sleepMode} />
            {sleepMode ? <p>Wygaszanie ekranu zablokowane</p> : <p>Zablokuj wygaszanie ekranu</p>}
          </UserInfoContainer>
        </ActionsWrapper>
      </StyledWrapper>
      <StyledLogout onClick={() => 
        {
          dispatch(logoutAction());
          navigate('/'); 
        }}>
        Wyloguj
        <LogOut size="30" />
      </StyledLogout>
    </>
  );
};

Account.propTypes = {
};

export default Account;
