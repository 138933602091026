import React, { useState, useEffect } from 'react';
import { fetchVerificationRequests as fetchVerificationRequestsAction } from '../actions';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Pagination from '../components/organisms/Pagination';
import { ResizeGridItems } from '../helpers/Functions';
import AdminRecipeCard from '../components/molecules/AdminRecipeCard';
import Searchbar from '../components/molecules/Searchbar';
import FilterModal from '../components/atoms/FilterModal';
import ViewName from '../components/atoms/ViewName';

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 0 35px;

  #adminSearchbar {
    width: 33%;
  }

  #adminHeader {
    width: calc(100% - 66%);
    text-align: center;
  }

  #filterContainer {
    width: 33%;
    text-align: right;
  }

  @media (max-width: 1000px) {
    padding: 5px 10px 0 10px;
    flex-flow: row-reverse wrap;
    height: unset;

    #adminSearchbar {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.primary};
      margin: 10px 0;
      padding: 0 5px;
      svg {
        color: ${({ theme }) => theme.secondary};
      }
    }
    #adminHeader,
    #filterContainer {
      width: fit-content;
    }
  }
`;

const RecipesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 700px);
  grid-auto-rows: 0;
  grid-gap: 20px;
  margin: 20px 0;
  justify-content: center;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const defaultQueryParams = { page: '1', searchPhrase: '' };
const AdminPage = () => {
  const [currentFilterStatus, setCurrentFilterStatus] = useState(undefined);
  const _queryParams = { ...defaultQueryParams };
  const [queryParams, setQueryParams] = useState(_queryParams);
  new URLSearchParams(window.location.search).forEach((value, key) => (_queryParams[key] = value));
  const setPage = (page) => setQueryParams({ ...queryParams, page });
  const { verificationRequests, numberOfRecipeVerificationRequestsFound } = useSelector(
    (state) => ({
      verificationRequests: state.verificationRequests,
      numberOfRecipeVerificationRequestsFound: state.numberOfRecipeVerificationRequestsFound,
    }),
  );
  const dispatch = useDispatch();
  useEffect(() => {
    ResizeGridItems();
    window.onresize = ResizeGridItems;
  }, []);

  useEffect(() => {
    ResizeGridItems();
  }, [verificationRequests]);

  useEffect(() => {
    dispatch(
      fetchVerificationRequestsAction(
        currentFilterStatus,
        queryParams.page,
        queryParams.searchPhrase,
      ),
    );
    let { origin, pathname, search, hash } = window.location;
    search = Object.entries(queryParams)
      .filter(([key, value]) => defaultQueryParams[key] !== value)
      .map(([key, value]) => key + (value ? '=' + value : ''))
      .join('&');
    if (search) search = '?' + search;
    const newUrl = `${origin}${pathname}${search}${hash}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
    // eslint-disable-next-line
  }, [queryParams, dispatch]);

  const changeStatus = (status) => {
    setCurrentFilterStatus(status);
    setQueryParams({ ...queryParams, page: '1' });
    dispatch(fetchVerificationRequestsAction(status, queryParams.page, queryParams.searchPhrase));
  };
  return (
    <>
      <HeaderContainer>
        <div id="filterContainer">
          <FilterModal currentFilterStatus={currentFilterStatus} changeStatus={changeStatus} />
        </div>
        <ViewName id="adminHeader">Admin Page</ViewName>
        <Searchbar
          id="adminSearchbar"
          placeholder={queryParams.searchPhrase || 'Szukaj...'}
          onEnter={(searchPhrase) => {
            setQueryParams({ ...queryParams, searchPhrase: searchPhrase, page: '1' });
          }}
        />
      </HeaderContainer>
      {verificationRequests && (
        <RecipesWrapper className="grid">
          {verificationRequests.map(({ id, recipe, status }, index) => (
            <AdminRecipeCard
              fetchRequests={() =>
                dispatch(
                  fetchVerificationRequestsAction(
                    currentFilterStatus,
                    queryParams.page,
                    queryParams.searchPhrase,
                  ),
                )
              }
              id={id}
              key={index}
              recipe={recipe}
              status={status}
            />
          ))}
        </RecipesWrapper>
      )}
      <Pagination
        onClick={setPage}
        active={queryParams.page}
        numberOfPages={numberOfRecipeVerificationRequestsFound / 6}
      />
    </>
  );
};
export default AdminPage;
