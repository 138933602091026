import axios from 'axios';
import { FormattedDate } from '../helpers/Functions';
import { SERVER_BASE_URL } from '../Config';
import { routes } from '../routes';
import history from '../helpers/history';
import NoSleep from 'nosleep.js';
import { useNavigate } from 'react-router-dom';

export const ADD_RECIPE_REQUEST = 'ADD_RECIPE_REQUEST';
export const ADD_RECIPE_SUCCESS = 'ADD_RECIPE_SUCCESS';
export const ADD_RECIPE_FAILURE = 'ADD_RECIPE_FAILURE';

export const EDIT_RECIPE_REQUEST = 'EDIT_RECIPE_REQUEST';
export const EDIT_RECIPE_SUCCESS = 'EDIT_RECIPE_SUCCESS';
export const EDIT_RECIPE_FAILURE = 'EDIT_RECIPE_FAILURE';

export const FETCH_SHOPPING_LIST_REQUEST = 'FETCH_SHOPPING_LIST_REQUEST';
export const FETCH_SHOPPING_LIST_SUCCESS = 'FETCH_SHOPPING_LIST_SUCCESS';
export const FETCH_SHOPPING_LIST_FAILURE = 'FETCH_SHOPPING_LIST_FAILURE';

export const FETCH_SPECIFIC_RECIPE_REQUEST = 'FETCH_SPECIFIC_RECIPE_REQUEST';
export const FETCH_SPECIFIC_RECIPE_SUCCESS = 'FETCH_SPECIFIC_RECIPE_SUCCESS';
export const FETCH_SPECIFIC_RECIPE_FAILURE = 'FETCH_SPECIFIC_RECIPE_FAILURE';

export const FETCH_RECIPES_REQUEST = 'FETCH_RECIPES_REQUEST';
export const FETCH_RECIPES_SUCCESS = 'FETCH_RECIPES_SUCCESS';
export const FETCH_RECIPES_FAILURE = 'FETCH_RECIPES_FAILURE';

export const FETCH_RECIPES_BY_INGREDIENT_REQUEST = 'FETCH_RECIPES_BY_INGREDIENT_REQUEST';
export const FETCH_RECIPES_BY_INGREDIENT_SUCCESS = 'FETCH_RECIPES_BY_INGREDIENT_SUCCESS';
export const FETCH_RECIPES_BY_INGREDIENT_FAILURE = 'FETCH_RECIPES_BY_INGREDIENT_FAILURE';

export const FETCH_PUBLIC_AND_PRIVATE_RECIPES_REQUEST = 'FETCH_PUBLIC_AND_PRIVATE_RECIPES_REQUEST';
export const FETCH_PUBLIC_AND_PRIVATE_RECIPES_SUCCESS = 'FETCH_PUBLIC_AND_PRIVATE_RECIPES_SUCCESS';
export const FETCH_PUBLIC_AND_PRIVATE_RECIPES_FAILURE = 'FETCH_PUBLIC_AND_PRIVATE_RECIPES_FAILURE';

export const FETCH_USER_RECIPES_REQUEST = 'FETCH_USER_RECIPES_REQUEST';
export const FETCH_USER_RECIPES_SUCCESS = 'FETCH_USER_RECIPES_SUCCESS';
export const FETCH_USER_RECIPES_FAILURE = 'FETCH_USER_RECIPES_FAILURE';

export const FETCH_NUTRIENTS_GOALS_REQUEST = 'FETCH_NUTRIENTS_GOALS_REQUEST';
export const FETCH_NUTRIENTS_GOALS_SUCCESS = 'FETCH_NUTRIENTS_GOALS_SUCCESS';
export const FETCH_NUTRIENTS_GOALS_FAILURE = 'FETCH_NUTRIENTS_GOALS_FAILURE';

export const FETCH_CURRENT_NUTRIENTS_GOAL_REQUEST = 'FETCH_CURRENT_NUTRIENTS_GOAL_REQUEST';
export const FETCH_CURRENT_NUTRIENTS_GOAL_SUCCESS = 'FETCH_CURRENT_NUTRIENTS_GOAL_SUCCESS';
export const FETCH_CURRENT_NUTRIENTS_GOAL_FAILURE = 'FETCH_CURRENT_NUTRIENTS_GOAL_FAILURE';

export const REMOVE_RECIPE_REQUEST = 'REMOVE_RECIPE_REQUEST';
export const REMOVE_RECIPE_SUCCESS = 'REMOVE_RECIPE_SUCCESS';
export const REMOVE_RECIPE_FAILURE = 'REMOVE_RECIPE_REQUEST';

export const FETCH_FAVOURITE_RECIPES_REQUEST = 'FETCH_FAVOURITE_RECIPES_REQUEST';
export const FETCH_FAVOURITE_RECIPES_SUCCESS = 'FETCH_FAVOURITE_RECIPES_SUCCESS';
export const FETCH_FAVOURITE_RECIPES_FAILURE = 'FETCH_FAVOURITE_RECIPES_FAILURE';

export const REMOVE_FROM_FAVOURITES_REQUEST = 'REMOVE_FROM_FAVOURITES_REQUEST';
export const REMOVE_FROM_FAVOURITES_SUCCESS = 'REMOVE_FROM_FAVOURITES_SUCCESS';
export const REMOVE_FROM_FAVOURITES_FAILURE = 'REMOVE_FROM_FAVOURITES_REQUEST';

export const ADD_MEAL_REQUEST = 'ADD_MEAL_REQUEST';
export const ADD_MEAL_SUCCESS = 'ADD_MEAL_SUCCESS';
export const ADD_MEAL_FAILURE = 'ADD_MEAL_FAILURE';

export const REMOVE_MEAL_REQUEST = 'REMOVE_MEAL_REQUEST';
export const REMOVE_MEAL_SUCCESS = 'REMOVE_MEAL_SUCCESS';
export const REMOVE_MEAL_FAILURE = 'REMOVE_MEAL_REQUEST';

export const FETCH_MEALS_REQUEST = 'FETCH_MEALS_REQUEST';
export const FETCH_MEALS_SUCCESS = 'FETCH_MEALS_SUCCESS';
export const FETCH_MEALS_FAILURE = 'FETCH_MEALS_FAILURE';

export const ADD_INGREDIENT_REQUEST = 'ADD_INGREDIENT_REQUEST';
export const ADD_INGREDIENT_SUCCESS = 'ADD_INGREDIENT_SUCCESS';
export const ADD_INGREDIENT_FAILURE = 'ADD_INGREDIENT_FAILURE';

export const EDIT_INGREDIENT_REQUEST = 'EDIT_INGREDIENT_REQUEST';
export const EDIT_INGREDIENT_SUCCESS = 'EDIT_INGREDIENT_SUCCESS';
export const EDIT_INGREDIENT_FAILURE = 'EDIT_INGREDIENT_FAILURE';

export const EDIT_NUTRIENTS_GOAL_REQUEST = 'EDIT_NUTRIENTS_GOAL_REQUEST';
export const EDIT_NUTRIENTS_GOAL_SUCCESS = 'EDIT_NUTRIENTS_GOAL_SUCCESS';
export const EDIT_NUTRIENTS_GOAL_FAILURE = 'EDIT_NUTRIENTS_GOAL_FAILURE';

export const REMOVE_INGREDIENT_REQUEST = 'REMOVE_INGREDIENT_REQUEST';
export const REMOVE_INGREDIENT_SUCCESS = 'REMOVE_INGREDIENT_SUCCESS';
export const REMOVE_INGREDIENT_FAILURE = 'REMOVE_INGREDIENT_FAILURE';

export const FETCH_INGREDIENTS_REQUEST = 'FETCH_INGREDIENTS_REQUEST';
export const FETCH_INGREDIENTS_SUCCESS = 'FETCH_INGREDIENTS_SUCCESS';
export const FETCH_INGREDIENTS_FAILURE = 'FETCH_INGREDIENTS_FAILURE';

export const FETCH_SPECIFIC_INGREDIENT_REQUEST = 'FETCH_SPECIFIC_INGREDIENT_REQUEST';
export const FETCH_SPECIFIC_INGREDIENT_SUCCESS = 'FETCH_SPECIFIC_INGREDIENT_SUCCESS';
export const FETCH_SPECIFIC_INGREDIENT_FAILURE = 'FETCH_SPECIFIC_INGREDIENT_FAILURE';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE';

export const CHANGE_USERNAME_REQUEST = 'CHANGE_USERNAME_REQUEST';
export const CHANGE_USERNAME_SUCCESS = 'CHANGE_USERNAME_SUCCESS';
export const CHANGE_USERNAME_FAILURE = 'CHANGE_USERNAME_FAILURE';

export const ADD_TO_FAVOURITES_REQUEST = 'ADD_TO_FAVOURITES_REQUEST';
export const ADD_TO_FAVOURITES_SUCCESS = 'ADD_TO_FAVOURITES_SUCCESS';
export const ADD_TO_FAVOURITES_FAILURE = 'ADD_TO_FAVOURITES_FAILURE';

export const FETCH_RECIPES_TO_VERIFY_REQUEST = 'FETCH_RECIPES_TO_VERIFY_REQUEST';
export const FETCH_RECIPES_TO_VERIFY_SUCCESS = 'FETCH_RECIPES_TO_VERIFY_SUCCESS';
export const FETCH_RECIPES_TO_VERIFY_FAILURE = 'FETCH_RECIPES_TO_VERIFY_FAILURE';

export const FETCH_VERIFICATION_REQUESTS_REQUEST = 'FETCH_VERIFICATION_REQUESTS_REQUEST';
export const FETCH_VERIFICATION_REQUESTS_SUCCESS = 'FETCH_VERIFICATION_REQUESTS_SUCCESS';
export const FETCH_VERIFICATION_REQUESTS_FAILURE = 'FETCH_VERIFICATION_REQUESTS_FAILURE';

export const SEND_PASSWORD_RESET_LINK_REQUEST = 'SEND_PASSWORD_RESET_LINK_REQUEST';
export const SEND_PASSWORD_RESET_LINK_SUCCESS = 'SEND_PASSWORD_RESET_LINK_SUCCESS';
export const SEND_PASSWORD_RESET_LINK_FAILURE = 'SEND_PASSWORD_RESET_LINK_FAILURE';

export const FETCH_VERIFICATION_REQUESTS_COUNT_REQUEST =
  'FETCH_VERIFICATION_REQUESTS_COUNT_REQUEST';
export const FETCH_VERIFICATION_REQUESTS_COUNT_SUCCESS =
  'FETCH_VERIFICATION_REQUESTS_COUNT_SUCCESS';
export const FETCH_VERIFICATION_REQUESTS_COUNT_FAILURE =
  'FETCH_VERIFICATION_REQUESTS_COUNT_FAILURE';

export const REQUEST_TO_VERIFY_REQUEST = 'REQUEST_TO_VERIFY_REQUEST';
export const REQUEST_TO_VERIFY_SUCCESS = 'REQUEST_TO_VERIFY_SUCCESS';
export const REQUEST_TO_VERIFY_FAILURE = 'REQUEST_TO_VERIFY_FAILURE';

export const VERIFY_RECIPE_REQUEST = 'VERIFY_RECIPE_REQUEST';
export const VERIFY_RECIPE_SUCCESS = 'VERIFY_RECIPE_SUCCESS';
export const VERIFY_RECIPE_FAILURE = 'VERIFY_RECIPE_FAILURE';

export const REJECT_RECIPE_REQUEST = 'REJECT_RECIPE_REQUEST';
export const REJECT_RECIPE_SUCCESS = 'REJECT_RECIPE_SUCCESS';
export const REJECT_RECIPE_FAILURE = 'REJECT_RECIPE_FAILURE';

export const SHOW_LOGOUT_NOTIFICATION = 'SHOW_LOGOUT_NOTIFICATION';
export const HIDE_LOGOUT_NOTIFICATION = 'HIDE_LOGOUT_NOTIFICATION';

export const ENABLE_SLEEP_MODE = 'ENABLE_SLEEP_MODE';
export const DISABLE_SLEEP_MODE = 'DISABLE_SLEEP_MODE';

const noSleep = new NoSleep();

export const deleteAccount = (currentPassword) => (dispatch, getState) => {
  dispatch({ type: DELETE_USER_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/users/delete`,
      { currentPassword },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then((payload) => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch({ type: DELETE_USER_SUCCESS, payload });
    })
    .catch((error) => {
      dispatch({ type: DELETE_USER_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const enableSleepMode = () => (dispatch) => {
  dispatch({ type: ENABLE_SLEEP_MODE });
  noSleep.enable();
};

export const disableSleepMode = () => (dispatch) => {
  dispatch({ type: DISABLE_SLEEP_MODE });
  noSleep.disable();
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER_REQUEST });
  try {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  } catch {
    dispatch({ type: LOGOUT_USER_FAILURE });
    return;
  }
  dispatch({ type: LOGOUT_USER_SUCCESS });
  dispatch({ type: SHOW_LOGOUT_NOTIFICATION });
  setTimeout(() => dispatch({ type: HIDE_LOGOUT_NOTIFICATION }), 1000);
};

export const registration = (username, email, password) => (dispatch) => {
  dispatch({ type: REGISTER_USER_REQUEST });

  return axios
    .post(`${SERVER_BASE_URL}/api/users/register`, { username, email, password })
    .then((payload) => {
      dispatch({ type: REGISTER_USER_SUCCESS, payload });
    })
    .catch((error) => {
      dispatch({ type: REGISTER_USER_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const sendPasswordResetLink = (email) => (dispatch) => {
  dispatch({ type: SEND_PASSWORD_RESET_LINK_REQUEST });

  return axios
    .post(`${SERVER_BASE_URL}/api/users/sendPasswordResetLink`, { email: email })
    .then((payload) => {
      dispatch({ type: SEND_PASSWORD_RESET_LINK_SUCCESS, payload });
    })
    .catch((error) => {
      dispatch({ type: SEND_PASSWORD_RESET_LINK_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const login = (email, password) => (dispatch) => {
  dispatch({ type: LOGIN_USER_REQUEST });
  return axios
    .post(`${SERVER_BASE_URL}/api/users/requestToken`, { email, password })
    .then((response) => {
      let { token, user } = response.data;
      token = `Bearer ${token}`;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', JSON.stringify(token));
      dispatch({ type: LOGIN_USER_SUCCESS, user, token });
    })
    .catch((error) => {
      dispatch({ type: LOGIN_USER_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const changePassword = (currentPassword, newPassword) => (dispatch, getState) => {
  dispatch({ type: CHANGE_PASSWORD_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/users/changePassword`,
      { currentPassword, newPassword },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then((payload) => {
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });
      history.push(routes.passwordChanged);
    })
    .catch((error) => {
      dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const resetPassword = (token, email, password, confirmPassword) => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  return axios
    .post(`${SERVER_BASE_URL}/api/users/resetPassword`, {
      token,
      email,
      password,
      confirmPassword,
    })
    .then((payload) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
      history.push('/passwordReseted');
    })
    .catch((error) => {
      dispatch({ type: RESET_PASSWORD_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const changeEmail = (currentPassword, newEmail) => (dispatch, getState) => {
  dispatch({ type: CHANGE_EMAIL_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/users/sendEmailChangeConfirmationLink`,
      { currentPassword, newEmail },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then((payload) => {
      dispatch({
        type: CHANGE_EMAIL_SUCCESS,
      });
      dispatch(logout());
      history.push(routes.confirmationLinkSent);
    })
    .catch((error) => {
      dispatch({ type: CHANGE_EMAIL_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const changeUsername = (currentPassword, newUsername) => (dispatch, getState) => {
  dispatch({ type: CHANGE_USERNAME_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/users/changeUsername`,
      { currentPassword, newUsername },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then((payload) => {
      dispatch({
        type: CHANGE_USERNAME_SUCCESS,
      });
      dispatch(logout());
      history.push(routes.usernameChanged);
    })
    .catch((error) => {
      dispatch({ type: CHANGE_USERNAME_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const addToFavourites = (recipeId) => (dispatch, getState) => {
  dispatch({ type: ADD_TO_FAVOURITES_REQUEST });
  return axios
    .post(`${SERVER_BASE_URL}/api/favouriteRecipes/addToFavourites/${recipeId}`, undefined, {
      headers: { Authorization: getState().token },
    })
    .then(() => {
      dispatch({
        type: ADD_TO_FAVOURITES_SUCCESS,
        payload: {
          recipeId,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_TO_FAVOURITES_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const requestToVerify = (recipeId) => (dispatch, getState) => {
  dispatch({ type: REQUEST_TO_VERIFY_REQUEST });
  return axios
    .post(`${SERVER_BASE_URL}/api/recipeVerification/request/${recipeId}`, undefined, {
      headers: { Authorization: getState().token },
    })
    .then((payload) => {
      dispatch({
        type: REQUEST_TO_VERIFY_SUCCESS,
        payload: {
          recipeId,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: REQUEST_TO_VERIFY_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const verifyRecipe = (requestId) => (dispatch, getState) => {
  dispatch({ type: VERIFY_RECIPE_REQUEST });
  return axios
    .post(`${SERVER_BASE_URL}/api/recipeVerification/verify/${requestId}`, undefined, {
      headers: { Authorization: getState().token },
    })
    .then((payload) => {
      dispatch({
        type: VERIFY_RECIPE_SUCCESS,
        payload: {
          // recipeId,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: VERIFY_RECIPE_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const rejectRecipe = (recipeId, message) => (dispatch, getState) => {
  dispatch({ type: REJECT_RECIPE_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/recipeVerification/reject`,
      { id: recipeId, verificationMessage: message },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then((payload) => {
      dispatch({
        type: REJECT_RECIPE_SUCCESS,
        payload: {
          recipeId,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: REJECT_RECIPE_FAILURE, payload: error?.response?.data });
      return Promise.reject(error);
    });
};

export const fetchFavouriteRecipes = (pageNumber, searchString) => (dispatch, getState) => {
  dispatch({ type: FETCH_FAVOURITE_RECIPES_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/favouriteRecipes`, {
      headers: { Authorization: getState().token },
      params: {
        recipePage: pageNumber,
        searchString,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_FAVOURITE_RECIPES_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FAVOURITE_RECIPES_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchRecipes = (pageNumber, searchString, visibility) => (dispatch, getState) => {
  dispatch({ type: FETCH_RECIPES_REQUEST });
  var params = new URLSearchParams();
  params.append('recipePage', pageNumber);
  params.append('searchString', searchString);
  (visibility ?? []).forEach((v) => params.append('visibility', v));
  return axios
    .get(`${SERVER_BASE_URL}/api/recipes`, {
      headers: { Authorization: getState().token },
      params,
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_RECIPES_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_RECIPES_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchRecipesByIngredient =
  (pageNumber, searchString, visibility) => (dispatch, getState) => {
    dispatch({ type: FETCH_RECIPES_BY_INGREDIENT_REQUEST });
    var params = new URLSearchParams();
    params.append('recipePage', pageNumber);
    params.append('searchString', searchString);
    (visibility ?? []).forEach((v) => params.append('visibility', v));
    return axios
      .get(`${SERVER_BASE_URL}/api/recipes/GetRecipesByIngredient`, {
        headers: { Authorization: getState().token },
        params,
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_RECIPES_BY_INGREDIENT_SUCCESS,
          payload: {
            data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_RECIPES_BY_INGREDIENT_FAILURE });
      return Promise.reject(err);
      });
  };

export const fetchVerificationRequests =
  (status, pageNumber, searchString) => (dispatch, getState) => {
    dispatch({ type: FETCH_VERIFICATION_REQUESTS_REQUEST });
    return axios
      .get(`${SERVER_BASE_URL}/api/recipeVerification`, {
        headers: { Authorization: getState().token },
        params: {
          status: status,
          recipePage: pageNumber,
          searchString,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_VERIFICATION_REQUESTS_SUCCESS,
          payload: {
            data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_VERIFICATION_REQUESTS_FAILURE });
      return Promise.reject(err);
      });
  };

export const fetchVerificationRequestsCount =
  (status, pageNumber, searchString) => (dispatch, getState) => {
    dispatch({ type: FETCH_VERIFICATION_REQUESTS_COUNT_REQUEST });
    return axios
      .get(`${SERVER_BASE_URL}/api/recipeVerification`, {
        headers: { Authorization: getState().token },
        params: {
          status: status,
          recipePage: pageNumber,
          searchString,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_VERIFICATION_REQUESTS_COUNT_SUCCESS,
          payload: {
            data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_VERIFICATION_REQUESTS_COUNT_FAILURE });
      return Promise.reject(err);
      });
  };

export const fetchRecipesToVerify = (pageNumber, searchString) => (dispatch, getState) => {
  dispatch({ type: FETCH_RECIPES_TO_VERIFY_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/recipes/recipesToVerify`, {
      headers: { Authorization: getState().token },
      params: {
        recipePage: pageNumber,
        searchString,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_RECIPES_TO_VERIFY_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_RECIPES_TO_VERIFY_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchUserRecipes = (pageNumber, searchString, visibility) => (dispatch, getState) => {
  dispatch({ type: FETCH_USER_RECIPES_REQUEST });
  var params = new URLSearchParams();
  params.append('recipePage', pageNumber);
  params.append('searchString', searchString);
  params.append('userId', getState().user.id);

  (visibility ?? []).forEach((v) => params.append('visibility', v));
  return axios
    .get(`${SERVER_BASE_URL}/api/recipes`, {
      headers: { Authorization: getState().token },
      params,
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_USER_RECIPES_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_USER_RECIPES_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchShoppingList = (dates) => (dispatch, getState) => {
  dispatch({ type: FETCH_SHOPPING_LIST_REQUEST });

  const params = new URLSearchParams();
  dates.forEach((date) => params.append('date', FormattedDate(new Date(date))));
  return axios
    .get(`${SERVER_BASE_URL}/api/shoppingList`, {
      headers: { Authorization: getState().token },
      params,
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_SHOPPING_LIST_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_SHOPPING_LIST_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchSpecificRecipe = (id) => (dispatch, getState) => {
  dispatch({ type: FETCH_SPECIFIC_RECIPE_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/recipes/${id}`, {
      headers: { Authorization: getState().token },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_SPECIFIC_RECIPE_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_SPECIFIC_RECIPE_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchIngredients = (pageNumber, searchString, pageSize) => (dispatch, getState) => {
  dispatch({ type: FETCH_INGREDIENTS_REQUEST });

  return axios
    .get(`${SERVER_BASE_URL}/api/ingredients`, {
      headers: { Authorization: getState().token },
      params: {
        ingredientPage: pageNumber,
        searchString,
        pageSize,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_INGREDIENTS_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_INGREDIENTS_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchSpecificIngredient = (id) => (dispatch, getState) => {
  dispatch({ type: FETCH_SPECIFIC_INGREDIENT_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/ingredients/${id}`, {
      headers: { Authorization: getState().token },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_SPECIFIC_INGREDIENT_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_SPECIFIC_INGREDIENT_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchMeals = (daysToFetch) => (dispatch, getState) => {
  dispatch({ type: FETCH_MEALS_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/diet/getMealsForSelectedDays`,
      { dates: daysToFetch },
      { headers: { Authorization: getState().token } },
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_MEALS_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_MEALS_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchCurrentNutrientsGoal = () => (dispatch, getState) => {
  dispatch({ type: FETCH_CURRENT_NUTRIENTS_GOAL_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/nutrientsGoals/GetCurrentNutrientsGoal`, {
      headers: { Authorization: getState().token },
    })
    .then(({ data }) => {
      if (data === undefined || data === null || data === '')
        data = {
          calories: undefined,
          fats: undefined,
          proteins: undefined,
          carbohydrates: undefined,
          sugars: undefined,
          salt: undefined,
        };

      dispatch({
        type: FETCH_CURRENT_NUTRIENTS_GOAL_SUCCESS,
        payload: {
          data: data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_CURRENT_NUTRIENTS_GOAL_FAILURE });
      return Promise.reject(err);
    });
};

export const fetchNutrientsGoals = () => (dispatch, getState) => {
  dispatch({ type: FETCH_NUTRIENTS_GOALS_REQUEST });
  return axios
    .get(`${SERVER_BASE_URL}/api/nutrientsGoals`, {
      headers: { Authorization: getState().token },
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_NUTRIENTS_GOALS_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_NUTRIENTS_GOALS_FAILURE });
      return Promise.reject(err);
    });
};

export const editNutrientsGoal = (itemContent) => (dispatch, getState) => {
  dispatch({ type: EDIT_NUTRIENTS_GOAL_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/nutrientsGoals`,
      {
        ...itemContent,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: EDIT_NUTRIENTS_GOAL_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: EDIT_NUTRIENTS_GOAL_FAILURE });
      return Promise.reject(err);
    });
};

export const removeRecipe = (id) => (dispatch, getState) => {
  dispatch({ type: REMOVE_RECIPE_REQUEST });

  return axios
    .delete(`${SERVER_BASE_URL}/api/recipes/${id}`, {
      headers: { Authorization: getState().token },
    })
    .then(() => {
      dispatch({
        type: REMOVE_RECIPE_SUCCESS,
        payload: {
          id,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: REMOVE_RECIPE_FAILURE });
      return Promise.reject(err);
    });
};

export const removeFromFavourites = (recipeId) => (dispatch, getState) => {
  dispatch({ type: REMOVE_FROM_FAVOURITES_REQUEST });

  return axios
    .post(`${SERVER_BASE_URL}/api/favouriteRecipes/removeFromFavourites/${recipeId}`, undefined, {
      headers: { Authorization: getState().token },
    })
    .then(() => {
      dispatch({
        type: REMOVE_FROM_FAVOURITES_SUCCESS,
        payload: {
          recipeId,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: REMOVE_FROM_FAVOURITES_FAILURE });
      return Promise.reject(err);
    });
};

export const removeIngredient = (id) => (dispatch, getState) => {
  dispatch({ type: REMOVE_INGREDIENT_REQUEST });
  return axios
    .delete(`${SERVER_BASE_URL}/api/ingredients/${id}`, {
      headers: { Authorization: getState().token },
    })
    .then(() => {
      dispatch({
        type: REMOVE_INGREDIENT_SUCCESS,
        payload: {
          id,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: REMOVE_INGREDIENT_FAILURE });
      return Promise.reject(err);
    });
};

export const removeMeal = (id) => (dispatch, getState) => {
  dispatch({ type: REMOVE_MEAL_REQUEST });
  return axios
    .delete(`${SERVER_BASE_URL}/api/diet/${id}`, {
      headers: { Authorization: getState().token },
    })
    .then(() => {
      dispatch({
        type: REMOVE_MEAL_SUCCESS,
        payload: {
          id,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: REMOVE_MEAL_FAILURE });
      return Promise.reject(err);
    });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const addRecipe = (itemContent) => (dispatch, getState) => {
  dispatch({ type: ADD_RECIPE_REQUEST });
  const recipeIngredients = itemContent.recipeIngredients.map((ri) => ({
    ingredientFK: ri.ingredientFK,
    annotation: ri.annotation,
    amount: ri.amount,
    unitOfMeasure: 'Gram',
  }));
  const requestData = { ...itemContent, recipeIngredients };
  return axios
    .post(
      `${SERVER_BASE_URL}/api/recipes`,
      {
        ...requestData,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: ADD_RECIPE_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: ADD_RECIPE_FAILURE });
      return Promise.reject(err);
    });
};

export const addIngredient = (itemContent) => (dispatch, getState) => {
  const unitOfMeasure = (itemContent.unitOfMeasure = 'Gram');
  const requestData = { ...itemContent, unitOfMeasure };
  dispatch({ type: ADD_INGREDIENT_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/ingredients`,
      {
        ...requestData,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: ADD_INGREDIENT_SUCCESS,
        payload: {
          data,
        },
      });
      history.push(`/ingredients`);
    })
    .catch((err) => {
      dispatch({ type: ADD_INGREDIENT_FAILURE });
      return Promise.reject(err);
    });
};

export const addMeal = (itemContent) => (dispatch, getState) => {
  dispatch({ type: ADD_MEAL_REQUEST });
  return axios
    .post(
      `${SERVER_BASE_URL}/api/diet`,
      {
        ...itemContent,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: ADD_MEAL_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: ADD_MEAL_FAILURE });
      return Promise.reject(err);
    });
};

export const editRecipe = (itemContent, id) => (dispatch, getState) => {
  dispatch({ type: EDIT_RECIPE_REQUEST });

  const recipeIngredients = itemContent.recipeIngredients.map((ri) => ({
    ingredientFK: ri.ingredientFK,
    recipeFK: itemContent.id,
    annotation: ri.annotation,
    amount: ri.amount,
    unitOfMeasure: 'Gram',
  }));
  const requestData = { ...itemContent, recipeIngredients };
  return axios
    .put(
      `${SERVER_BASE_URL}/api/recipes/${id}`,
      {
        ...requestData,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: EDIT_RECIPE_SUCCESS,
        payload: {
          data,
        },
      });
      history.push(`/recipes`);
    })
    .catch((err) => {
      dispatch({ type: EDIT_RECIPE_FAILURE });
      return Promise.reject(err);
    });
};

export const editIngredient = (itemContent, id) => (dispatch, getState) => {
  dispatch({ type: EDIT_INGREDIENT_REQUEST });
  return axios
    .put(
      `${SERVER_BASE_URL}/api/ingredients/${id}`,
      {
        ...itemContent,
      },
      {
        headers: { Authorization: getState().token },
      },
    )
    .then(({ data }) => {
      dispatch({
        type: EDIT_INGREDIENT_SUCCESS,
        payload: {
          data,
        },
      });
      history.push(`/ingredients`);
    })
    .catch((err) => {
      dispatch({ type: EDIT_INGREDIENT_FAILURE });
      return Promise.reject(err);
    });
};
