import React, { Component } from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import { Person } from '@styled-icons/bootstrap/Person';
import { Timer } from '@styled-icons/material/Timer';
import PropTypes from 'prop-types';
import MacronutrientInfoWrapper from '../molecules/MacronutrientInfoWrapper';

const StyledWrapper = styled.div`
  margin: 25px auto;
  width: 100%;
  @media print {
    height: 99vh !important;
    margin: 0;
    padding: 0 !important;
    overflow: hidden;
  }
  @page {
    margin: 20mm;
  }
`;

const StyledTr = styled.tr`
  font-size: ${({ theme }) => theme.fontSize.xs};
  transition: 0.3s;
  :hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  > td {
    .annotation {
      color: ${({ theme }) => theme.secondary};
      font-style: italic;
      font-weight: ${({ theme }) => theme.bold};
    }
    padding: 8px 12px;
    :nth-child(1) {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    :nth-child(2) {
      padding-right: 0;
      text-align: right;
    }
    :nth-child(3) {
      width: 20px;
    }
    border-color: ${({ theme }) => theme.primary} !important;
  }
`;

const StyledRecipeName = styled.h1`
  font-size: 55px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  text-overflow: ellipsis;
  letter-spacing: 2px;
  white-space: wrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

const Image = styled.div`
  min-height: 350px;
  height: 350px;
  position: relative;
  border-radius: 10px;
  width: 100%;
  background-image: url(${(props) => props.src});
  /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); */
  min-width: 200px;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 1;
  background-repeat: no-repeat;
`;

const StyledSubtitle = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  padding: 0;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
`;

const StyledInfo = styled.div`
  color: ${({ theme }) => theme.black};
  white-space: nowrap;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.bold};
  margin: 0 5px 0 0;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  &:last-child {
    &:before {
      display: none;
    }
  }
  > svg {
    margin: 0 5px 2px 0;
    color: ${({ theme }) => theme.grey};
  }

  > div {
    color: ${({ theme }) => theme.black};
    padding: 5px;
    border-radius: 15px;
    width: fit-content;
  }
`;

const SectionName = styled.p`
  font-size: 27px;
  padding: 0;
  position: relative;
  font-family: ${({ theme }) => theme.primaryFont};

  font-weight: ${({ theme }) => theme.light};
  margin: 20px 0 0 0;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.darkSecondary};

  > span {
    color: ${({ theme }) => theme.black};
    font-size: 14px;
    position: absolute;
    font-family: ${({ theme }) => theme.primaryFont};
    top: 0;
    right: 0;
    margin-top: 5px;
  }
`;

const StyledMacronutrientsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0 20px;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
`;

const StyledPreparation = styled.ol`
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
  counter-reset: item;
  position: relative;
  bottom: 13px;
  font-size: 17px;
`;

const PreparationItem = styled.li`
  counter-increment: item;
  &:first-child {
    margin-top: 17px;
  }
  &:before {
    content: counter(item);
    color: ${({ theme }) => theme.darkSecondary};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: 35px;
    height: 10px;
    width: 1.2em;
    text-align: center;
    display: inline-block;
  }
`;

class RecipeToPrint extends Component {
  render() {
    const {
      recipeActiveCookingTime,
      recipeTotalCookingTime,
      specificRecipe,
      ingredientsPortions,
      nutrientsPortions,
      printWithImage,
      amountVariant,
    } = this.props;
    return (
      <StyledWrapper>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '45%',
              marginRight: '20px',
            }}
          >
            <StyledRecipeName>{specificRecipe.recipe.name}</StyledRecipeName>
            <StyledSubtitle>{specificRecipe.recipe.description}</StyledSubtitle>
            <InfoWrapper className="info">
              {recipeActiveCookingTime && (
                <StyledInfo>
                  <Timer size={17} />
                  Aktywny czas pracy :
                  <div>
                    {recipeActiveCookingTime.hours}
                    {recipeActiveCookingTime.minutes > 0 && '.' + recipeActiveCookingTime.minutes}h
                  </div>
                </StyledInfo>
              )}
              {recipeTotalCookingTime && (
                <StyledInfo>
                  <Timer size={17} />
                  Całkowity czas pracy:
                  <div>
                    {recipeTotalCookingTime.hours}
                    {recipeTotalCookingTime.minutes > 0 && '.' + recipeTotalCookingTime.minutes}h
                  </div>
                </StyledInfo>
              )}
              <StyledInfo>
                <Person size={19} />
                {specificRecipe.recipe.amountVariant === 'Portions'
                  ? 'Kcal w 1 porcji :'
                  : 'Kcal w 1 sztuce :'}
                <div>
                  {specificRecipe &&
                    specificRecipe.recipe.recipeIngredients
                      .map(
                        (i) =>
                          i.ingredient &&
                          (i.ingredient.calories * i.amount) /
                            specificRecipe.recipe.numberOfPortions,
                      )
                      .reduce((x, y) => x + y, 0)
                      .toFixed(0)}
                </div>
              </StyledInfo>
            </InfoWrapper>
            <SectionName className="sectionName">
              Składniki odżywcze
              <span>
                <strong>{amountVariant === 'portions' ? 'Porcje: ' : 'Sztuki: '}: </strong>
                {nutrientsPortions}
              </span>
            </SectionName>
            <StyledMacronutrientsContainer id="nutrientsContainer">
              <MacronutrientInfoWrapper
                unit=" kcal"
                value={(
                  (specificRecipe.macronutrients.calories /
                    specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(0)}
                title="Kalorie"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.fats / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Tłuszcze"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.proteins /
                    specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Białko"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.carbs / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Węglowodany"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.sugars / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Cukry"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.salt / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Sól"
              />
            </StyledMacronutrientsContainer>
            <SectionName className="sectionName">
              Składniki
              <span>
                <strong>{amountVariant === 'portions' ? 'Porcje: ' : 'Sztuki: '}: </strong>
                {ingredientsPortions}
              </span>
            </SectionName>
            <Table borderless>
              <tbody>
                {ingredientsPortions &&
                  specificRecipe &&
                  specificRecipe.recipe.recipeIngredients &&
                  specificRecipe.recipe.recipeIngredients.map(
                    ({ ingredient, annotation, amount, unitOfMeasure }) => (
                      <StyledTr key={ingredient.name}>
                        <td title={ingredient.name}>
                          {ingredient.name}
                          {annotation && <span className="annotation"> ({annotation})</span>}
                        </td>
                        <td>
                          {(
                            (amount / specificRecipe.recipe.numberOfPortions) *
                            ingredientsPortions
                          ).toFixed(0)}
                        </td>
                        <td>
                          {unitOfMeasure && unitOfMeasure === 'Milliliter' && 'ml'}
                          {unitOfMeasure && unitOfMeasure === 'Gram' && 'gr'}
                        </td>
                      </StyledTr>
                    ),
                  )}
              </tbody>
            </Table>
          </div>
          <div style={{ width: '55%' }}>
            {printWithImage && <Image id="image" src={specificRecipe.recipe.imageUrl} />}
            <SectionName className="sectionName">Przygotowanie</SectionName>
            <StyledPreparation>
              {specificRecipe &&
                specificRecipe.recipe.preparation &&
                specificRecipe.recipe.preparation
                  .split('*')
                  .filter((pi) => pi.trim() !== '')
                  .map((pi, index) => <PreparationItem key={index}>{pi}</PreparationItem>)}
            </StyledPreparation>
          </div>
        </div>
      </StyledWrapper>
    );
  }
}

RecipeToPrint.propTypes = {
  recipeActiveCookingTime: PropTypes.object,
  recipeTotalCookingTime: PropTypes.object,
  specificRecipe: PropTypes.object,
  ingredientsPortions: PropTypes.number,
  nutrientsPortions: PropTypes.number,
  printWithImage: PropTypes.bool,
};

export default RecipeToPrint;
