import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { MoreVertical } from '@styled-icons/feather/MoreVertical';
import ConfirmModal from './ConfirmModal';

const StyledContainer = styled.div`
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  ${(props) =>
    props.$secondary &&
    css`
      background-color: ${({ theme }) => theme.white};
      border-radius: 100%;
      > ul {
        left: -150px;
        bottom: 40px;
      }
    `}
  > svg {
    width: 30px;
    height: 30px;
    z-index: 1;
    color: ${({ theme }) => theme.grey};
    ${(props) =>
      props.$secondary &&
      css`
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
        padding: 5px;
      `}
  }
`;

const StyledOptions = styled.ul`
  display: none;
  position: absolute;
  left: 40px;
  overflow: hidden;
  list-style: none;
  border-radius: 10px;
  z-index: 5;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.primary};
  ${(props) =>
    props.$optionsActive ?
    css`
      display: block;
    ` : undefined}
`;

const StyledOption = styled.li`
  font-size: ${({ theme }) => theme.fontSize.xs};
  width: 100%;
  height: 50px;
  padding: 10px 35px;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const RecipeActions = ({ options, secondary }) => {
  const [modalDetails, setModalDetails] = useState(undefined);
  const [optionsActive, setOptionsActive] = useState(false);
  const optionsActiveRef = useRef(optionsActive);
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('mouseup', (event) => {
      if (!optionsActiveRef.current) return;
      var itemOptions = ref.current;
      if (event.target !== itemOptions) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === itemOptions) return;
          else parent = parent.parentNode;
        }
        setOptionsActive(false);
      }
    });
    return () => {
      optionsActiveRef.current = false;
    };
  }, []);

  useEffect(() => {
    optionsActiveRef.current = optionsActive;
  }, [optionsActive]);

  return (
    <StyledContainer ref={ref} $secondary={secondary} className="itemOptions">
      {modalDetails && (
        <ConfirmModal
          activeModal={modalDetails.active}
          text={modalDetails.modalText}
          onModalSubmit={modalDetails.onModalSubmit}
          closeModal={() => setModalDetails(undefined)}
          submitButtonText={modalDetails.modalButton}
        />
      )}
      <MoreVertical onClick={() => setOptionsActive((x) => !x)} />
      {options.length > 0 && (
        <StyledOptions $optionsActive={optionsActive}>
          {options.map(
            (option, index) =>
              option && (
                <StyledOption
                  key={index}
                  onClick={() => {
                    setOptionsActive(false);
                    option.onClick && option.onClick();
                    setModalDetails({
                      active: option.modal,
                      modalText: option.modalText,
                      modalButton: option.modalButton,
                      onModalSubmit: () => {
                        option.submitAction();
                        setModalDetails(undefined);
                      },
                    });
                  }}
                >
                  {option.name}
                </StyledOption>
              ),
          )}
        </StyledOptions>
      )}
    </StyledContainer>
  );
};

RecipeActions.propTypes = {
  options: PropTypes.array,
  deleteText: PropTypes.string,
  deleteOnSubmit: PropTypes.func,
  secondary: PropTypes.bool,
};
export default RecipeActions;
