export const routes = {
  mainPage: '/',
  home: '/home',
  recipes: '/recipes',
  recipe: '/recipes/:id',
  ingredients: '/ingredients',
  diet: '/diet',
  shoppingList: '/shoppingList',
  login: '/login',
  register: '/register',
  addMeal: '/addMeal',
  addRecipe: '/addRecipe',
  adminPage: '/adminPage',
  editRecipe: '/editRecipe/:id',
  addIngredient: '/addIngredient',
  editIngredient: '/editIngredient/:id',
  account: '/account',
  userData: '/account/settings',
  changePassword: '/account/changePassword',
  nutrientsGoalTimeline: '/account/nutrientsGoalTimeline',
  nutrientsGoal: '/account/nutrientsGoal',
  changeUsername: '/account/changeUsername',
  changeEmail: '/account/changeEmail',
  forgetPassword: '/forgetPassword',
  deleteAccount: '/account/deleteAccount',
  emailConfirmed: '/emailConfirmed',
  confirmationLinkSent: '/confirmationLinkSent',
  resetPasswordLinkSent: '/resetPasswordLinkSent',
  resetPassword: '/resetPassword',
  passwordReseted: '/passwordReseted',
  passwordChanged: '/passwordChanged',
  usernameChanged: '/usernameChanged',
  emailChanged: '/emailChanged',
  accountDeleted: '/accountDeleted',
};
