import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Recipe from '../components/molecules/Recipe';
import Pagination from '../components/organisms/Pagination';
import {
  fetchRecipes as fetchRecipesAction,
  fetchRecipesByIngredient as fetchRecipesByIngredientAction,
  fetchFavouriteRecipes as fetchFavouriteRecipesAction,
} from '../actions/index';
import Logo from '../components/atoms/Logo';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { SuitHeart } from '@styled-icons/bootstrap/SuitHeart';
import { routes } from '../routes';
import Searchbar from '../components/molecules/Searchbar';

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 35px;

  #homeSearchbar {
    width: 33%;
  }

  #logo {
    width: calc(100% - 66%);
    margin: 0;
  }

  @media (max-width: 1000px) {
    flex-flow: row-reverse wrap;
    align-items: flex-start;
    height: unset;
    padding: 10px 10px 0 10px;
    #logo {
      width: fit-content;
    }

    #homeSearchbar {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.primary};
      margin: 15px 0px;
      padding: 0 5px;
      svg {
        color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

const HeartWrapper = styled.div`
  width: 33%;
  text-align: right;
  margin-top: 15px;
  > svg {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.red};
    transition: 0.3s;
    cursor: pointer;
    :hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 1000px) {
    margin-top: 5px;
    width: fit-content;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  overflow: hidden;
  justify-content: center;
  padding: 0 25px;
  grid-template-columns: repeat(3, 33.3%);
  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 50%);
  }
  @media (max-width: 1000px) {
    padding: 0 5px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 450px) {
    > div:first-child {
      margin-top: 0;
    }
  }
`;

const defaultQueryParams = { page: '1', searchPhrase: '', isFavourite: false };

const Home = () => {
  const navigate = useNavigate();
  const [searchVariant, setSearchVariant] = useState('Name');
  const _queryParams = { ...defaultQueryParams };
  new URLSearchParams(window.location.search).forEach((value, key) => (_queryParams[key] = value));
  const [queryParams, setQueryParams] = useState(_queryParams);
  const setPage = (page) => setQueryParams({ ...queryParams, page });
  const { recipes, numberOfRecipesFound, user } = useSelector((state) => ({
    recipes: state.recipes,
    numberOfRecipesFound: state.numberOfRecipesFound,
    user: state.user,
  }));
  const dispatch = useDispatch();
  const _fetchRecipes = () => {
    if (queryParams.isFavourite)
      dispatch(fetchFavouriteRecipesAction(queryParams.page, queryParams.searchPhrase));
    else {
      if (searchVariant === 'Name')
        dispatch(fetchRecipesAction(queryParams.page, queryParams.searchPhrase, ['public']));
      else {
        dispatch(
          fetchRecipesByIngredientAction(queryParams.page, queryParams.searchPhrase, ['public']),
        );
      }
    }
  };

  useEffect(() => {
    setSearchVariant(searchVariant);
  }, [searchVariant]);

  useEffect(() => {
    _fetchRecipes();
    let { origin, pathname, search, hash } = window.location;
    search = Object.entries(queryParams)
      .filter(([key, value]) => defaultQueryParams[key] !== value)
      .map(([key, value]) => key + (value ? '=' + value : ''))
      .join('&');

    if (search) search = '?' + search;
    const newUrl = `${origin}${pathname}${search}${hash}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
    // eslint-disable-next-line
  }, [queryParams]);

  return (
    <>
      <HeaderContainer>
        <HeartWrapper>
          {!_queryParams.isFavourite ? (
            <SuitHeart
              title="Ulubione przepisy"
              onClick={() =>
                user
                  ? setQueryParams({ ..._queryParams, isFavourite: true, page: '1' })
                  : navigate(routes.login)
              }
            />
          ) : (
            <SuitHeartFill
              title="Wszystkie przepisy"
              onClick={() => setQueryParams({ ..._queryParams, isFavourite: false, page: '1' })}
            />
          )}
        </HeartWrapper>
        <Logo id="logo">Szama</Logo>
        <Searchbar
          id="homeSearchbar"
          searchVariants
          searchVariant={searchVariant}
          setSearchVariant={setSearchVariant}
          placeholder={_queryParams.searchPhrase || 'Wyszukaj przepis...'}
          onEnter={(searchPhrase) => {
            setQueryParams({ ..._queryParams, searchPhrase: searchPhrase, page: '1' });
          }}
        />
      </HeaderContainer>
      <StyledGrid>
        {recipes &&
          recipes.map(
            (
              {
                id,
                name,
                description,
                recipeIngredients,
                numberOfPortions,
                activeCookingTime,
                imageUrl,
                isFavourite,
              },
              index,
            ) => (
              <Recipe
                user={user}
                key={index}
                recipeIngredients={recipeIngredients}
                numberOfPortions={numberOfPortions}
                activeCookingTime={activeCookingTime}
                id={id}
                name={name}
                description={description}
                image={imageUrl}
                isFavourite={isFavourite}
                refreshData={_fetchRecipes}
              />
            ),
          )}
      </StyledGrid>
      <Pagination
        onClick={setPage}
        active={queryParams.page}
        numberOfPages={numberOfRecipesFound / 12}
      />
    </>
  );
};

Home.propTypes = {
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
  numberOfRecipesFound: PropTypes.number,
};

Home.defaultProps = {
  recipes: [],
  numberOfRecipesFound: 0,
};

export default Home;
