import {
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  // SEND_PASSWORD_RESET_LINK_REQUEST,
  SEND_PASSWORD_RESET_LINK_SUCCESS,
  SEND_PASSWORD_RESET_LINK_FAILURE,
  // RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
  CHANGE_USERNAME_REQUEST,
  CHANGE_USERNAME_SUCCESS,
  CHANGE_USERNAME_FAILURE,
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_FAILURE,
  CLEAR_ERRORS,
  ADD_RECIPE_REQUEST,
  ADD_RECIPE_SUCCESS,
  // ADD_RECIPE_FAILURE,
  ADD_INGREDIENT_REQUEST,
  ADD_INGREDIENT_SUCCESS,
  // ADD_INGREDIENT_FAILURE,
  EDIT_INGREDIENT_REQUEST,
  EDIT_INGREDIENT_SUCCESS,
  // EDIT_INGREDIENT_FAILURE,
  ADD_MEAL_REQUEST,
  ADD_MEAL_SUCCESS,
  // ADD_MEAL_FAILURE,
  REMOVE_RECIPE_REQUEST,
  REMOVE_RECIPE_SUCCESS,
  // REMOVE_RECIPE_FAILURE,
  REMOVE_FROM_FAVOURITES_REQUEST,
  REMOVE_FROM_FAVOURITES_SUCCESS,
  // REMOVE_FROM_FAVOURITES_FAILURE,
  REMOVE_INGREDIENT_REQUEST,
  REMOVE_INGREDIENT_SUCCESS,
  // REMOVE_INGREDIENT_FAILURE,
  REMOVE_MEAL_REQUEST,
  REMOVE_MEAL_SUCCESS,
  // REMOVE_MEAL_FAILURE,
  FETCH_RECIPES_REQUEST,
  FETCH_RECIPES_SUCCESS,
  // FETCH_RECIPES_FAILURE,
  FETCH_RECIPES_BY_INGREDIENT_REQUEST,
  FETCH_RECIPES_BY_INGREDIENT_SUCCESS,
  // FETCH_RECIPES_BY_INGREDIENT_FAILURE,
  FETCH_VERIFICATION_REQUESTS_REQUEST,
  FETCH_VERIFICATION_REQUESTS_SUCCESS,
  // FETCH_VERIFICATION_REQUESTS_FAILURE,
  FETCH_VERIFICATION_REQUESTS_COUNT_REQUEST,
  FETCH_VERIFICATION_REQUESTS_COUNT_SUCCESS,
  // FETCH_VERIFICATION_REQUESTS_COUNT_FAILURE,
  FETCH_RECIPES_TO_VERIFY_REQUEST,
  FETCH_RECIPES_TO_VERIFY_SUCCESS,
  // FETCH_RECIPES_TO_VERIFY_FAILURE,
  FETCH_USER_RECIPES_REQUEST,
  FETCH_USER_RECIPES_SUCCESS,
  // FETCH_USER_RECIPES_FAILURE,
  FETCH_FAVOURITE_RECIPES_REQUEST,
  FETCH_FAVOURITE_RECIPES_SUCCESS,
  // FETCH_FAVOURITE_RECIPES_FAILURE,
  FETCH_SPECIFIC_RECIPE_REQUEST,
  FETCH_SPECIFIC_RECIPE_SUCCESS,
  // FETCH_SPECIFIC_RECIPE_FAILURE,
  FETCH_SPECIFIC_INGREDIENT_REQUEST,
  FETCH_SPECIFIC_INGREDIENT_SUCCESS,
  // FETCH_SPECIFIC_INGREDIENT_FAILURE,
  FETCH_INGREDIENTS_REQUEST,
  FETCH_INGREDIENTS_SUCCESS,
  // FETCH_INGREDIENTS_FAILURE,
  FETCH_SHOPPING_LIST_REQUEST,
  FETCH_SHOPPING_LIST_SUCCESS,
  // FETCH_SHOPPING_LIST_FAILURE,
  FETCH_MEALS_REQUEST,
  FETCH_MEALS_SUCCESS,
  // FETCH_MEALS_FAILURE,
  FETCH_NUTRIENTS_GOALS_REQUEST,
  FETCH_NUTRIENTS_GOALS_SUCCESS,
  // FETCH_NUTRIENTS_GOALS_FAILURE,
  FETCH_CURRENT_NUTRIENTS_GOAL_REQUEST,
  FETCH_CURRENT_NUTRIENTS_GOAL_SUCCESS,
  // FETCH_CURRENT_NUTRIENTS_GOAL_FAILURE,
  EDIT_RECIPE_REQUEST,
  EDIT_RECIPE_SUCCESS,
  // EDIT_RECIPE_FAILURE,
  EDIT_NUTRIENTS_GOAL_REQUEST,
  EDIT_NUTRIENTS_GOAL_SUCCESS,
  // EDIT_NUTRIENTS_GOAL_FAILURE,
  REQUEST_TO_VERIFY_REQUEST,
  REQUEST_TO_VERIFY_SUCCESS,
  // REQUEST_TO_VERIFY_FAILURE,
  VERIFY_RECIPE_REQUEST,
  VERIFY_RECIPE_SUCCESS,
  // VERIFY_RECIPE_FAILURE,
  REJECT_RECIPE_REQUEST,
  REJECT_RECIPE_SUCCESS,
  // REJECT_RECIPE_FAILURE,
  SHOW_LOGOUT_NOTIFICATION,
  HIDE_LOGOUT_NOTIFICATION,
  ENABLE_SLEEP_MODE,
  DISABLE_SLEEP_MODE,
} from '../actions';

const user = JSON.parse(localStorage.getItem('user'));
const token = JSON.parse(localStorage.getItem('token'));

const initialState = user
  ? {
      user,
      token,
      sleepMode: false,
      isLoading: false,
      showLogoutNotification: false,
      meals: [],
      shoppingList: [],
    }
  : {
      isLoading: false,
      showLogoutNotification: false,
      sleepMode: false,
      meals: [],
      shoppingList: [],
    };

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_SLEEP_MODE:
      return {
        ...state,
        sleepMode: true,
      };
    case DISABLE_SLEEP_MODE:
      return {
        ...state,
        sleepMode: false,
      };
    case SHOW_LOGOUT_NOTIFICATION:
      return {
        ...state,
        showLogoutNotification: true,
      };
    case HIDE_LOGOUT_NOTIFICATION:
      return {
        ...state,
        showLogoutNotification: false,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        registrationErrors: action.payload.errors,
      };
    case SEND_PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
      };
    case SEND_PASSWORD_RESET_LINK_FAILURE:
      return {
        ...state,
        sendPasswordResetLinkErrors: action.payload.errors,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordErrors: action.payload.errors,
      };
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loggingIn: true,
        // user: action.user,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loginErrors: action.payload.errors,
        user: undefined,
        token: undefined,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        user: undefined,
        token: undefined,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUserErrors: action.payload.errors,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordErrors: action.payload.errors,
      };
    case EDIT_NUTRIENTS_GOAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_NUTRIENTS_GOAL_SUCCESS:
      return {
        ...state,
        currentNutrientsGoal: undefined,
        isLoading: false,
      };

    case CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        changeEmailErrors: action.payload.errors,
      };
    case CHANGE_USERNAME_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_USERNAME_FAILURE:
      return {
        ...state,
        changeUsernameErrors: action.payload.errors,
      };
    case ADD_TO_FAVOURITES_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case ADD_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
      };
    case ADD_TO_FAVOURITES_FAILURE:
      return {
        ...state,
      };
    case REQUEST_TO_VERIFY_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case REQUEST_TO_VERIFY_SUCCESS:
      return {
        ...state,
      };
    case VERIFY_RECIPE_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case VERIFY_RECIPE_SUCCESS:
      return {
        ...state,
      };
    case REJECT_RECIPE_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case REJECT_RECIPE_SUCCESS:
      return {
        ...state,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        user: undefined,
        token: undefined,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loginErrors: null,
        registrationErrors: null,
        changeEmailErrors: null,
        changeUsernameErrors: null,
        changePasswordErrors: null,
        deleteUserErrors: null,
      };
    case ADD_RECIPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_RECIPE_SUCCESS:
      return {
        ...state,
        recipes: [...state.recipes, action.payload.data],
      };

    case ADD_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: [...(state.ingredients ?? []), action.payload.data],
      };
    case ADD_INGREDIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_MEAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_MEAL_SUCCESS:
      return {
        ...state,
        meals: [...(state.meals ?? []), action.payload.data],
      };
    case REMOVE_RECIPE_REQUEST:
      return {
        ...state,

        isLoading: true,
      };
    case REMOVE_RECIPE_SUCCESS:
      return {
        ...state,
        recipes: (state.recipes ?? []).filter((item) => item.id !== action.payload.id),
      };
    case REMOVE_FROM_FAVOURITES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_FROM_FAVOURITES_SUCCESS:
      return {
        ...state,
      };
    case REMOVE_INGREDIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: (state.ingredients ?? []).filter((item) => item.id !== action.payload.id),
        // ingredients: state.ingredients.filter(item => item.name !== action.payload.id),
      };
    case REMOVE_MEAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_MEAL_SUCCESS:
      return {
        ...state,
        meals: (state.meals ?? []).filter((item) => item.meal.id !== action.payload.id),
      };
    case FETCH_RECIPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECIPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recipes: [...action.payload.data.recipes],
        numberOfRecipesFound: action.payload.data.numberOfRecipesFound,
      };
    case FETCH_RECIPES_BY_INGREDIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECIPES_BY_INGREDIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recipes: [...action.payload.data.recipes],
        numberOfRecipesFound: action.payload.data.numberOfRecipesFound,
      };
    case FETCH_VERIFICATION_REQUESTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_VERIFICATION_REQUESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verificationRequests: [...action.payload.data.requests],
        numberOfRecipeVerificationRequestsFound:
          action.payload.data.numberOfRecipeVerificationRequestsFound,
      };
    case FETCH_VERIFICATION_REQUESTS_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_VERIFICATION_REQUESTS_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestsCount: action.payload.data.numberOfRecipeVerificationRequestsFound,
      };
    case FETCH_RECIPES_TO_VERIFY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RECIPES_TO_VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recipesToVerify: [...action.payload.data.recipes],
        numberOfRecipesFound: action.payload.data.numberOfRecipesFound,
      };
    case FETCH_USER_RECIPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_RECIPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userRecipes: [...action.payload.data.recipes],
        numberOfUserRecipesFound: action.payload.data.numberOfRecipesFound,
      };
    case FETCH_FAVOURITE_RECIPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FAVOURITE_RECIPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recipes: [...action.payload.data.recipes],
        numberOfRecipesFound: action.payload.data.numberOfRecipesFound,
      };
    case FETCH_INGREDIENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_INGREDIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ingredients: [...action.payload.data.ingredients],
        numberOfIngredientsFound: action.payload.data.numberOfIngredientsFound,
      };
    case FETCH_CURRENT_NUTRIENTS_GOAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CURRENT_NUTRIENTS_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentNutrientsGoal: action.payload.data,
      };
    case FETCH_NUTRIENTS_GOALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_NUTRIENTS_GOALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nutrientsGoals: action.payload.data,
      };
    case FETCH_MEALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_MEALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        meals: [...action.payload.data],
      };
    case EDIT_RECIPE_REQUEST:
      return { ...state, isLoading: true, specificRecipe: undefined };
    case EDIT_RECIPE_SUCCESS:
      return state;
    case EDIT_INGREDIENT_REQUEST:
      return {
        ...state,
        specificIngredient: undefined,
        isLoading: true,
      };
    case EDIT_INGREDIENT_SUCCESS:
      return state;
    case FETCH_SPECIFIC_RECIPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SPECIFIC_RECIPE_SUCCESS:
      return {
        ...state,
        specificRecipe: action.payload.data,
      };
    case FETCH_SPECIFIC_INGREDIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SPECIFIC_INGREDIENT_SUCCESS:
      return {
        ...state,
        specificIngredient: action.payload.data,
      };
    case FETCH_SHOPPING_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        shoppingList: action.payload.data,
      };
    default:
      return state;
  }
};
export default rootReducer;
