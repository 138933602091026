import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"; 
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RecipeActions from '../atoms/RecipeActions';
import VerificationStatusPopup from '../atoms/VerificationStatusPopup';
import { Person } from '@styled-icons/bootstrap/Person';
import { Hourglass } from '@styled-icons/bootstrap/Hourglass';

const StyledWrapper = styled.div`
  border-radius: 25px;
  position: relative;
  margin: 30px;
  height: fit-content;
  text-align: left;
  margin: 25px 10px;
`;

const StyledImage = styled.div`
  height: 350px;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 15px;
  cursor: pointer;
`;

const StyledName = styled.h1`
  width: 100%;
  position: relative;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.secondary};
  top: 0;
  cursor: pointer;
  overflow: hidden;
  pointer-events: none;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.xl};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  padding: 5px;
  z-index: 2;
  @media (max-width: 1000px) {
    padding: 10px 5px;
  }
`;

const StyledDescription = styled.h3`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0 5px;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  font-family: ${({ theme }) => theme.primaryFont};
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  transform: unset;
  z-index: 2;
  pointer-events: none;
`;

const StyledInfo = styled.div`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.bold};
  margin: 0 5px;
  > svg {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
  }
`;

const RecipeActionsWrapper = styled.div`
  position: absolute;
  top: 330px;
  right: 20px;
  svg {
    width: 45px;
    height: 45px;
    padding: 5px;
  }
`;

const StyledStatus = styled.h1`
  color: ${({ status, theme }) => (status === 'Public' ? theme.green : theme.grey)};
  font-size: ${({ theme }) => theme.fontSize.s};
  padding: 0;
  margin: 0 0 0 5px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.bold};
`;

const UserRecipeCard = ({
  id,
  recipe,
  handleRecipeDelete,
  handleRecipePublish,
  verifications,
}) => {
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState(
    verifications && verifications.length > 0 && verifications[0].status,
  );
  verifications.sort((a, b) => (b.requestedDate > a.requestedDate ? 1 : -1));
  const [visibility, setVisibility] = useState(recipe && recipe.visibility);
  useEffect(() => {
    setVerificationStatus(verifications && verifications.length > 0 && verifications[0].status);
    // eslint-disable-next-line
  }, [recipe, verifications]);

  useEffect(() => {
    setVisibility(recipe && recipe.visibility);
  }, [recipe]);

  let recipeActiveCookingTime = undefined;
  if (recipe.activeCookingTime) {
    let split = recipe.activeCookingTime.split(':').map((x) => parseInt(x));
    if (split.length === 2) {
      let [hours, minutes] = split;
      if (hours > 0 || minutes > 0) recipeActiveCookingTime = { hours, minutes };
    }
  }

  return (
    <StyledWrapper className="recipe" id={recipe.name}>
      <Link to={`/recipes/${id}`}>
        <StyledImage
          className="recipeImage"
          src={recipe.imageUrl}
        />
      </Link>
      <StyledName className="recipeName" title={recipe.lengthname}>
        {recipe.name}
      </StyledName>
      <StyledDescription id="description">{recipe.description}</StyledDescription>
      <RecipeActionsWrapper>
        <RecipeActions
          id="recipeActions"
          secondary
          options={
            visibility && visibility === 'Public'
              ? [{ name: 'Szczegóły', onClick: () => navigate(`/recipes/${id}`) }]
              : [
                  {
                    name: 'Edytuj',
                    modal: false,
                    onClick: () => navigate(`/editRecipe/${id}`),
                  },
                  {
                    name: 'Szczegóły',
                    modal: false,
                    onClick: () => navigate(`/recipes/${id}`),
                  },
                  {
                    name: 'Usuń',
                    modal: true,
                    modalText: `Czy jesteś pewien, że chcesz usunąć "${recipe.name}"?`,
                    modalButton: 'Usuń',
                    submitAction: handleRecipeDelete,
                  },
                  verifications &&
                  verifications.length > 0 &&
                  (verifications[0].status === 'Rejected' ||
                    verifications[0].status === 'Requested')
                    ? {
                        name: 'Zweryfikuj',
                        modal: true,
                        modalText: `Czy jesteś pewien, że chcesz zweryfikować "${recipe.name}"?`,
                        modalButton: 'Zweryfikuj',
                        submitAction: handleRecipePublish,
                      }
                    : {
                        name: 'Upublicznij',
                        modal: true,
                        modalText: `Czy jesteś pewien, że chcesz upublicznić "${recipe.name}"?`,
                        modalButton: 'Upublicznij',
                        submitAction: handleRecipePublish,
                      },
                ]
          }
        />
      </RecipeActionsWrapper>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InfoWrapper className="info">
          {recipeActiveCookingTime && (
            <StyledInfo>
              <Hourglass style={{ marginRight: '5px' }} size={23} />
              {recipeActiveCookingTime.hours}
              {recipeActiveCookingTime.minutes > 0 && '.' + recipeActiveCookingTime.minutes}h
            </StyledInfo>
          )}
          <StyledInfo>
            <Person size={26} style={{ marginRight: '5px' }} />
            {recipe.recipeIngredients &&
              recipe.recipeIngredients
                .map(
                  (i) =>
                    i.ingredient && (i.ingredient.calories * i.amount) / recipe.numberOfPortions,
                )
                .reduce((x, y) => x + y, 0)
                .toFixed(0)}{' '}
            kcal
          </StyledInfo>
        </InfoWrapper>
        <VerificationStatusPopup
          verificationStatus={verificationStatus}
          verificationMessage={
            verifications && verifications.length > 0 && verifications[0].verificationMessage
          }
        />
        <StyledStatus status={visibility}>
          {visibility === 'Public' ? 'Publiczny' : 'Prywatny'}
        </StyledStatus>
      </div>
    </StyledWrapper>
  );
};

UserRecipeCard.propTypes = {
  id: PropTypes.string,
  recipe: PropTypes.object,
  handleRecipeDelete: PropTypes.func,
  handleRecipePublish: PropTypes.func,
  verifications: PropTypes.array,
};
export default UserRecipeCard;
