import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../components/atoms/Button';
import ConfirmModal from '../components/atoms/ConfirmModal';
import { Swap } from '@styled-icons/evaicons-solid/Swap';
import { Trash2 } from '@styled-icons/feather/Trash2';

const StyledAuthCard = styled.div`
  width: 650px;
  margin: 100px auto 50px auto;
  text-align: center;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(150, 150, 150, 0.5);
  @media (max-width: 750px) {
    width: 90%;
    margin: 20px auto;
  }
`;

const StyledHeader = styled.h1`
  margin: 0 auto;
  width: 80%;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.l};
  letter-spacing: 1px;
  padding: 15px 0;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
`;

const ChildrenWrapper = styled.div`
  margin: auto;
  padding: 10px 30px 10px 30px;
  @media (max-width: 500px) {
    width: 90%;
    padding: 0 10px 0px 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.secondary};
  color: #eee;
  padding-left: 30px;

  svg {
    margin-left: 5px;
  }
`;

const ManageUserFormTemplate = ({
  removeButton,
  swapButton,
  buttonText,
  confirmModal,
  header,
  children,
  modalText,
  onSubmit,
  submitButtonText,
  errors,
  formErrors,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  return (
    <>
      <StyledAuthCard>
        <StyledHeader>{header}</StyledHeader>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <StyledButton
          onClick={() => {
            confirmModal ? setActiveModal(true) : onSubmit();
          }}
        >
          {buttonText}
          {removeButton && <Trash2 size="25" />}
          {swapButton && <Swap size="25" />}
        </StyledButton>
      </StyledAuthCard>
      {confirmModal && (
        <ConfirmModal
          formErrors={formErrors}
          activeModal={activeModal}
          errors={errors}
          text={modalText}
          submitButtonText={submitButtonText}
          onModalSubmit={onSubmit}
          closeModal={() => setActiveModal(false)}
        />
      )}
    </>
  );
};

ManageUserFormTemplate.propTypes = {
  removeView: PropTypes.bool,
  removeButton: PropTypes.bool,
  swapButton: PropTypes.bool,
  formErrors: PropTypes.array,
  buttonText: PropTypes.string,
  confirmModal: PropTypes.bool,
  header: PropTypes.string,
  children: PropTypes.any,
  modalText: PropTypes.string,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  errors: PropTypes.object,
};

export default ManageUserFormTemplate;
