import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  changePassword as changePasswordAction,
  clearErrors as clearErrorsAction,
} from '../actions/index';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 60px auto;
  width: 80%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ChangePassword = () => {
  const changePasswordErrors = useSelector(state => state.changePasswordErrors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', repeatPassword: '' }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required('Musisz potwierdzić hasło!'),
        newPassword: Yup.string()
          .min(6, 'Hasło musi zawierać co najmniej 6 znaków!')
          .required('Hasło jest wymagane!'),
        repeatPassword: Yup.string()
          .required('Powtórz hasło!')
          .when('newPassword', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('newPassword')], 'Hasła muszą być takie same!'),
          }),
      })}
      onSubmit={({ currentPassword, newPassword }) => {
        dispatch(changePasswordAction(currentPassword, newPassword));
      }}
    >
      {({ handleChange, handleBlur, errors, touched, submitForm }) => {
        return (
          <ManageUserFormTemplate
            confirmModal
            header="Zmień hasło"
            swapButton
            buttonText="Zmień hasło"
            modalText="Czy jesteś pewien, że chcesz zmienić hasło?"
            onSubmit={() => submitForm()}
            submitButtonText="Zmień"
            errors={errors}
            formErrors={changePasswordErrors}
          >
            <StyledFormInput
              labelName="Potwierdź hasło"
              name="currentPassword"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.currentPassword}
              touched={touched.currentPassword}
            />
            <StyledFormInput
              labelName="Nowe hasło"
              name="newPassword"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.newPassword}
              touched={touched.newPassword}
            />
            <StyledFormInput
              labelName="Powtórz nowe hasło"
              name="repeatPassword"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.repeatPassword}
              touched={touched.repeatPassword}
            />
            {changePasswordErrors &&
              changePasswordErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
