import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendPasswordResetLink as sendPasswordResetLinkAction,
  clearErrors as clearErrorsAction,
} from '../actions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
const StyledFormInput = styled(FormInput)`
  margin: 30px auto;
`;
const ForgetPassword = () => {
  const sendPasswordResetLinkErrors = useSelector(state => state.sendPasswordResetLinkErrors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email jest wymagany!'),
      })}
      onSubmit={({ email }) => {
        dispatch(sendPasswordResetLinkAction(email))
          .then(() => navigate(routes.resetPasswordLinkSent));
      }}
    >
      {({ handleChange, handleBlur, errors, touched, values, submitForm }) => {
        return (
          <ManageUserFormTemplate
            header="Wyślij link resetujący hasło"
            buttonText="Wyślij"
            onSubmit={() => submitForm()}
            errors={sendPasswordResetLinkErrors}
          >
            <StyledFormInput
              labelName="Email"
              name="email"
              type="email"
              onChange={e => {
                dispatch(clearErrorsAction());
                handleChange(e);
              }}
              onBlur={handleBlur}
              errors={errors.email}
              value={values.email}
              touched={touched.email}
            />
            {sendPasswordResetLinkErrors &&
              sendPasswordResetLinkErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ForgetPassword;
