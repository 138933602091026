import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { Send } from '@styled-icons/feather/Send';
import Button from '../components/atoms/Button';
import { ArrowHookDownLeft } from '@styled-icons/fluentui-system-filled/ArrowHookDownLeft';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 450px) {
    position: absolute;
    top: 15px;
    order: 1;
  }
`;

const StyledButtonPrevious = styled(Button)`
  left: 0;
  bottom: 0;
  border-radius: 0 0 0 10px;
  margin-right: auto;
  width: 100%;
  > svg {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  > p {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 450px) {
    border-radius: 0 10px 10px 0;
    justify-content: left;
    padding: 10px;
    width: 50px;
    height: 50px;
    > p {
      display: none;
    }
    > svg {
      width: 60px !important;
      height: 60px !important;
    }
  }
`;

const StyledButtonNext = styled(Button)`
  right: 0;
  bottom: 0;
  border-radius: 0 0 10px 0;
  margin-left: auto;
  width: 100%;

  > svg {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
  > p {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 450px) {
    border-radius: 10px 0 0 10px;
    justify-content: right;
    padding: 10px;
    height: 50px;
    width: 50px;
    > p {
      display: none;
    }
    > svg {
      margin-left: 5px;
      width: 50px;
      height: 50px;
    }
  }
`;

const FormContainer = styled.section`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    align-items: flex-start;
  }
`;

const StyledForm = styled(Form)`
overflow:hidden;
  height: fit-content;
  width: 850px;
  border-radius: 10px;
  min-height: 500px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    margin: 20px;
  }
  @media (max-width: 500px) {
    min-height: 450px;
  }
  #dateTime {
    width: 40%;
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  @media (max-width: 450px) {
    box-shadow: none;
    border-radius: 0;
    margin: 0;
  }
`;
const StyledStep = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 0 5px 5px 0;
  width: ${(props) => ((props.$currentStep - 1) / props.$steps) * 100 + '%'};
  background-color: ${({ theme }) => theme.secondary};
  transition: 0.2s ease;
`;

const FormHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  padding: 20px 0px;
  color: ${({ theme }) => theme.grey};
  font-family: ${({ theme }) => theme.primaryFont};
  @media (max-width: 1100px) {
    font-size: ${({ theme }) => theme.fontSize.l};
    padding: 20px;
  }
  @media (max-width: 450px) {
    order: 2;
  }
`;

const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  margin: auto;
  @media (max-width: 450px) {
    order: 3;
  }
`;

const MultiStepFormTemplate = ({ createChild, title, steps, nextButtonDisabled }) => {
  const [currentStep, setCurrentStep] = useState(1);

  let _next = () => {
    setCurrentStep(currentStep + 1);
  };

  let _prev = () => {
    setCurrentStep(Math.max(1, currentStep - 1));
  };

  return (
    <FormContainer>
      <StyledForm>
        <FormHeader>{title}</FormHeader>
        <ChildrenContainer>{createChild(currentStep)}</ChildrenContainer>
        <ButtonsContainer>
          {currentStep !== 1 ? (
            <div style={{ width: '50%' }}>
              <StyledButtonPrevious type="button" onClick={_prev}>
                <ArrowHookDownLeft />
                <p>Poprzednia</p>
              </StyledButtonPrevious>
            </div>
          ) : null}
          {currentStep < steps ? (
            <div style={{ width: '50%' }}>
              <StyledButtonNext
                type="button"
                onClick={_next}
                disabled={nextButtonDisabled(currentStep)}
              >
                <p>Następna</p>
                <ArrowRightShort />
              </StyledButtonNext>
            </div>
          ) : null}
          {currentStep === steps ? (
            <div style={{ width: '50%' }}>
              <StyledButtonNext type="submit">
                <p>Wyślij</p>
                <Send />
              </StyledButtonNext>
            </div>
          ) : null}
        </ButtonsContainer>
        <StyledStep $currentStep={currentStep} $steps={steps}>
          {/* Krok {currentStep}/{steps} */}
        </StyledStep>
      </StyledForm>
    </FormContainer>
  );
};

MultiStepFormTemplate.propTypes = {
  createChild: PropTypes.func,
  title: PropTypes.string,
  steps: PropTypes.number,
  nextButtonDisabled: PropTypes.func,
};

export default MultiStepFormTemplate;
