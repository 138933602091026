import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.secondary};
  margin: 0 0 0 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  ${(props) =>
    props.activeVariant === 2 &&
    css`
      #slide {
        transform: translate(100%, -50%) !important;
      }
      > div:nth-child(1) {
        color: ${({ theme }) => theme.grey} !important;
      }
      > div:nth-child(2) {
        color: ${({ theme }) => theme.white} !important;
      }
    `}
`;

const StyledVariant = styled.div`
  transition: 0.5s;
  color: ${({ theme }) => theme.grey};
  :nth-child(1) {
    color: ${({ theme }) => theme.white};
  }
  display: inline-block;
  padding: 5px 0;
  width: 80px;
  margin: 0;
`;

const StyledSlide = styled.div`
  z-index: -1;
  pointer-events: none;
  position: absolute;
  width: 50%;
  padding: 0 5px;
  height: 110%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: 0.5s;
  background-color: ${({ theme }) => theme.secondary};
`;
const Switch = ({
  firstVariantTitle,
  secondVariantTitle,
  activeVariant,
  onVariantChange,
  onChange,
  value,
}) => {
  useEffect(() => {
    onChange(activeVariant === 1 ? 'Portions' : 'Pieces');
    //eslint-disable-next-line
  }, [activeVariant]);

  useEffect(() => {
    console.log(value);
  }, [value]);
  return (
    <StyledContainer value={value} activeVariant={activeVariant}>
      <StyledVariant onClick={() => onVariantChange(1)}>{firstVariantTitle}</StyledVariant>
      <StyledVariant onClick={() => onVariantChange(2)}>{secondVariantTitle}</StyledVariant>
      <StyledSlide id="slide" />
    </StyledContainer>
  );
};

Switch.propTypes = {};

export default Switch;
