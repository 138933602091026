import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { changeEmail as changeEmailAction, clearErrors as clearErrorsAction } from '../actions';
import ManageUserFormTemplate from '../templates/ManageUserFormTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 60px auto;
  width: 80%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ChangeEmail = () => {
  const { user, changeEmailErrors } = useSelector(state => ({
    user: state.user,
    changeEmailErrors: state.changeEmailErrors,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrorsAction());
    // eslint-disable-next-line
  }, []);
  return (
    <Formik
      initialValues={{ currentPassword: '', email: user && user.email }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required('Musisz potwierdzić hasło!'),
        email: Yup.string()
          .email('Email jest niepoprawny!')
          .required('Email jest wymagany!'),
      })}
      onSubmit={({ currentPassword, email }) => {
        dispatch(changeEmailAction(currentPassword, email));
      }}
    >
      {({ handleChange, handleBlur, errors, touched, values, submitForm }) => {
        return (
          <ManageUserFormTemplate
            confirmModal
            swapButton
            header="Zmień email"
            buttonText="Zmień"
            modalText="Jesteś pewien? Po zmianie emaila zostaniesz wylogowany."
            onSubmit={() => submitForm()}
            submitButtonText="Zmień"
            formErrors={changeEmailErrors}
            errors={errors}
          >
            <StyledFormInput
              labelName="Potwierdź hasło"
              name="currentPassword"
              type="password"
              onChange={e => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              autocomplete="off"
              errors={errors.currentPassword}
              touched={touched.currentPassword}
            />
            <StyledFormInput
              labelName="Nowy email"
              name="email"
              type="email"
              value={values.email}
              onChange={e => {
                handleChange(e);
                dispatch(clearErrorsAction());
              }}
              onBlur={handleBlur}
              errors={errors.email}
              touched={touched.email}
            />
            {changeEmailErrors &&
              changeEmailErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </ManageUserFormTemplate>
        );
      }}
    </Formik>
  );
};

export default ChangeEmail;
