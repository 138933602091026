import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 15px;
`;

const PaginationItem = styled.div`
  margin: 0 2px;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  @media (max-width: 700px) {
    width: 35px;
    height: 35px;
  border-radius: 10px;

  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.primary};
  }
  ${props =>
    props.$active ?
    css`
      color: ${({ theme }) => theme.white};
      opacity: 0.6;
      background-color: ${({ theme }) => theme.secondary} !important;
    ` : undefined}
`;

const Pagination = ({ onClick, active, numberOfPages }) => {
  numberOfPages = Math.ceil(numberOfPages);
  active = parseInt(active);
  let firstPage = active - 2;
  if (firstPage < 1) firstPage = 1;
  let lastPage = active + 2;
  if (lastPage > numberOfPages) lastPage = numberOfPages;
  let numbers = Array.from(Array(lastPage - firstPage + 1).keys()).map(x => x + firstPage);

  return !numberOfPages || numberOfPages === 0 ? (
    <></>
  ) : (
    <StyledWrapper>
      {numbers.map((item, i) => {
        return (
          <PaginationItem
            key={i}
            $active={item === active}
            onClick={() => {
              onClick(item);
              window.scrollTo(0, 0);
            }}
          >
            {item}
          </PaginationItem>
        );
      })}
    </StyledWrapper>
  );
};

Pagination.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberOfPages: PropTypes.number,
};
export default Pagination;
