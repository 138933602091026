import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import FieldErrorMessage from './FieldErrorMessage';

const colourStyles = {
  control: (styles, { selectProps }) => {
    let borderBottom = '1px solid rgb(234, 235, 233)!important';
    if (selectProps.errors && selectProps.touched)
      borderBottom = '1px solid rgb(184, 29, 29)!important';

    return {
      ...styles,
      border: 'none!important',
      borderRadius: '0',
      borderBottom,
      height: '45px',
      boxShadow: 'null',
      backgroundColor: 'transparent',
      overflow: 'hidden!important',
      fontSize: '15px',
    };
  },
};

const StyledSelectGroup = styled.div`
  padding: 0;
  width: 50%;
  position: relative;
  &:focus-within > label {
    font-size: 0.8rem;
    top: -10px;
    left: -5px;
    color: ${({ theme }) => theme.secondary};
  }
  @media (max-width: 600px) {
    width: 80%;
  }

  ${(props) =>
    props.value ?
    css`
      > label {
        font-size: 0.8rem;
        top: -10px;
        left: -5px;
        color: ${({ theme }) => theme.secondary};
      }
    ` : undefined};

  &.is-invalid:focus-within > label {
    color: ${({ theme }) => theme.red} !important;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 10px;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: normal;
  pointer-events: none;
  transition: all 0.2s ease;
`;

const SelectOption = ({
  style,
  labelName,
  name,
  options,
  onChange,
  onBlur,
  touched,
  errors,
  value,
  className,
}) => {
  const handleChange = (newSelectedOption) => {
    if (onChange) onChange(name, newSelectedOption.id);
  };
  const selectOptions = options.map((v) =>
    v.value && v.label
      ? { id: v.value, value: v.label, label: v.label }
      : { id: v, value: v, label: v },
  );
  const selectValue = selectOptions.find((v) => v.id === value) || { id: '', value: '', label: '' };
  return (
    <StyledSelectGroup
      style={style}
      value={value}
      errors={errors}
      className={`${errors && touched ? `${className} is-invalid` : `${className}`} `}
    >
      <Select
        placeholder=""
        name={name}
        onChange={handleChange}
        onBlur={() => setTimeout(() => onBlur(name, true), 200)}
        options={selectOptions}
        errors={errors}
        touched={touched}
        value={selectValue}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: `rgba(147, 165, 207, 0.3)`,
            primary50: `rgba(147, 165, 207, 0.3)`,
            primary: `rgba(147, 165, 207, 1)`,
          },
        })}
      />
      <StyledLabel>{labelName}</StyledLabel>
      {errors && touched && <FieldErrorMessage name={name} />}
    </StyledSelectGroup>
  );
};

SelectOption.propTypes = {
  style: PropTypes.object,
  labelName: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  touched: PropTypes.bool,
  errors: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default SelectOption;
