import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ServesPicker = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > h1 {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    color: ${({ theme }) => theme.grey};
    margin: 0 7px 0 0;
    text-transform: uppercase;
  }
  > div {
    cursor: pointer;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.primary};
    width: 25px;
    height: 25px;
    position: relative;
    transition: 0.3s;
    :hover {
      background-color: ${({ theme }) => theme.lightGrey};
    }
    > p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: ${({ theme }) => theme.bold};
      padding: 0;
      margin: 0;
      font-size: ${({ theme }) => theme.fontSize.m};
      color: ${({ theme }) => theme.white};
      user-select: none;
      -webkit-user-select: none;
    }
  }
  > h3 {
    color: ${({ theme }) => theme.secondary};
    font-weight: ${({ theme }) => theme.bold};
    font-size: ${({ theme }) => theme.fontSize.xs};
    // pointer-events: none;
    margin: 0 15px;
    padding: 0;
    @media (max-width: 450px) {
      margin: 0 7px;
    }
  }
`;

const PortionsCounter = ({ variant, portions, setPortions, numberOfPortions, min, max }) => {
  useEffect(() => {
    setPortions(numberOfPortions);
    //eslint-disable-next-line
  }, [numberOfPortions]);

  return (
    <ServesPicker>
      <h1>{variant === 'Portions' ? 'Porcje:' : 'Sztuki:'}</h1>
      <div onClick={() => portions > min && setPortions((x) => x - 1)}>
        <p>-</p>
      </div>
      <h3>{portions}</h3>
      <div onClick={() => portions < max && setPortions((x) => x + 1)}>
        <p>+</p>
      </div>
    </ServesPicker>
  );
};

PortionsCounter.propTypes = {
  portions: PropTypes.number,
  setPortions: PropTypes.func,
  numberOfPortions: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default PortionsCounter;
