import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { logout as logoutAction } from '../actions';
import { Mail } from '@styled-icons/feather/Mail';
import { Lock } from '@styled-icons/feather/Lock';
import { UserX } from '@styled-icons/feather/UserX';
import { Pen } from '@styled-icons/bootstrap/Pen';
import { User } from '@styled-icons/feather/User';
import { LogOut } from '@styled-icons/ionicons-outline/LogOut';
import { routes } from '../routes';

const StyledWrapper = styled.div`
  width: 850px;
  margin: 100px auto 50px auto;
  display: flex;
  flex-flow: column wrap;
  font-family: ${({ theme }) => theme.primaryFont};
  align-items: center;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(150, 150, 150, 0.5);
  @media (max-width: 750px) {
    width: 90%;
    margin: 50px auto;
  }
  @media (max-width: 450px) {
    padding: 10px;
  }
`;

const StyledUserName = styled.h1`
  width: 80%;
  text-align: center;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.xxl};
  padding: 10px;
  margin: 0;
  word-break: break-word;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const UserInfoContainer = styled.div`
  width: 80%;
  margin: 30px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 2px solid ${({ theme }) => theme.lightSecondary};
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .pen {
    padding: 4px;
  }
  > svg {
    color: ${({ theme }) => theme.grey};
    width: 25px;
    height: 25px;
    @media (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }
  > p {
    font-size: ${({ theme }) => theme.fontSize.s};
    font-weight: ${({ theme }) => theme.bold};
    color: ${({ theme }) => theme.grey};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 10px 0 0 0;
  }
`;

const StyledDeleteAccount = styled(Link)`
  width: 100%;
  padding: 5px 0;
  display: flex;
  width: fit-content;
  margin: 0 0 0 auto;
  align-items: center;
  color: ${({ theme }) => theme.red};
  font-size: ${({ theme }) => theme.fontSize.s};
  > p {
    padding-right: 10px;
    margin: 0;
  }
`;

const StyledLogout = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.l};
  margin: auto;
  width: fit-content;
  font-weight: ${({ theme }) => theme.light};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.primaryFont};

  > svg {
    margin-left: 10px;
  }
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const UserData = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <StyledWrapper>
        <StyledUserName>Witaj, {user.username}</StyledUserName>
        <UserInfoContainer onClick={() => navigate(routes.changeUsername)}>
          <User className="infoIcon" />
          <p>{user.username}</p>
          <Pen className="pen" title="Zmień nazwę użytkownika" />
        </UserInfoContainer>
        <UserInfoContainer onClick={() => navigate(routes.changePassword)}>
          <Lock className="infoIcon" />
          <p>********</p>
          <Pen className="pen" title="Change password" />
        </UserInfoContainer>
        <UserInfoContainer onClick={() => navigate(routes.changeEmail)}>
          <Mail className="infoIcon" />
          <p>{user.email}</p>
          <Pen className="pen" title="Change email" />
        </UserInfoContainer>
        <StyledDeleteAccount to={routes.deleteAccount}>
          <p>Usuń konto</p>
          <UserX size="15" />
        </StyledDeleteAccount>
      </StyledWrapper>
      <StyledLogout onClick={() => dispatch(logoutAction())}>
        Wyloguj <LogOut size="30" />
      </StyledLogout>
    </>
  );
};

UserData.propTypes = {
};
export default UserData;
