export const theme = {
  primary: 'rgb(234, 235, 233)',
  secondary: 'rgb(147, 165, 207)',
  darkSecondary: 'rgb(82, 108, 171)',
  lightSecondary: 'rgba(147, 165, 207, 0.3)',
  lightGrey: 'rgb(225, 225, 225)',
  grey: 'rgb(153, 153, 153)',
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  red: 'rgb(184, 29, 29)',
  orange: 'rgb(255, 132, 0)',
  green: 'rgb(62, 158, 30)',
  light: 300,
  bold: 600,
  fontSize: {
    xxs: '0.75rem',
    xs: '1rem',
    s: '1.2rem',
    m: '1.5rem',
    l: '1.8rem',
    xl: '2rem',
    xxl: '3rem',
    xxxl: '5rem',
  },
  primaryFont: '"Big Shoulders Display", cursive',
};
