import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { routes } from '../routes';
import Pagination from '../components/organisms/Pagination';
import NewItemButton from '../components/atoms/NewItemButton';
import RecipeActions from '../components/atoms/RecipeActions';
import {
  fetchIngredients as fetchIngredientsAction,
  removeIngredient as removeIngredientAction,
} from '../actions/index';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/components/navigation/navigation.scss';
import Searchbar from '../components/molecules/Searchbar';
import ViewName from '../components/atoms/ViewName';

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 35px;

  #ingredientsSearchbar,
  #newItemButtonContainer {
    width: 33%;
  }

  #ingredientsHeader {
    width: calc(100% - 66%);
    text-align: center;
  }

  @media (max-width: 1000px) {
    padding: 0 10px;
    flex-flow: row-reverse wrap;
    height: unset;

    #ingredientsHeader,
    #newItemButtonContainer {
      width: fit-content;
    }

    #ingredientsSearchbar {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.primary};
      margin: 15px 0;
      padding: 0 5px;
      svg {
        color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @media (max-width: 870px) {
    margin: 15px;
  }
`;

const StyledIngredient = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: ${({ theme }) => theme.primary};
  border-radius: 10px;
  width: 800px;
  padding: 5px 10px;
  margin: 7px;
  text-align: left;
  transition: 0.3s;
  @media (max-width: 870px) {
    width: 100%;
  }
  > h1 {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin: auto 10px;
    width: 30%;
  }
  :hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightGrey};
  }
`;

const NutrientsContainer = styled(Swiper)`
  width: 70%;
  text-align: center;
  overflow: hidden;
  .swiper-slide:last-child() {
    position: absolute;
    right: 0;
  }
`;

const NutrientWrapper = styled.div`
  width: 100px;
  > p {
    :nth-child(1) {
      font-size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.grey};
    }
    padding: 0;
    margin: 0;
  }
  span {
    font-size: 12px;
    margin-left: 2px;
    color: ${({ theme }) => theme.secondary};
  }
`;

const pageSize = 20;
const defaultQueryParams = { page: '1', searchPhrase: '' };
const Ingredients = ({ }) => {
  const navigate = useNavigate();
  const _queryParams = { ...defaultQueryParams };
  new URLSearchParams(window.location.search).forEach((value, key) => (_queryParams[key] = value));
  const [queryParams, setQueryParams] = useState(_queryParams);
  const setPage = (page) => setQueryParams({ ...queryParams, page });

  const { ingredients, numberOfIngredientsFound } = useSelector((state) => ({
    ingredients: (state.ingredients || []).slice(0, pageSize),
    numberOfIngredientsFound: state.numberOfIngredientsFound,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIngredientsAction(queryParams.page, queryParams.searchPhrase));
    let { origin, pathname, search, hash } = window.location;
    search = Object.entries(queryParams)
      .filter(([key, value]) => defaultQueryParams[key] !== value)
      .map(([key, value]) => key + (value ? '=' + value : ''))
      .join('&');
    if (search) search = '?' + search;
    const newUrl = `${origin}${pathname}${search}${hash}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }, [queryParams, dispatch]);

  return (
    <>
      <HeaderContainer>
        <div id="newItemButtonContainer">
          <NewItemButton
            onClick={() => navigate(routes.addIngredient)}
            content="Dodaj składnik"
          />
        </div>
        <ViewName id="ingredientsHeader">Składniki</ViewName>
        <Searchbar
          id="ingredientsSearchbar"
          placeholder={queryParams.searchPhrase || 'Wyszukaj składnik...'}
          onEnter={(searchPhrase) => {
            setQueryParams({ searchPhrase: searchPhrase, page: '1' });
          }}
        />
      </HeaderContainer>
      <StyledWrapper>
        {ingredients &&
          ingredients.map(
            (
              { id, name, calories, fats, carbohydrates, sugars, proteins, salt }, // unitOfMeasure,
            ) => (
              <StyledIngredient key={id}>
                <RecipeActions
                  options={[
                    { name: 'Edytuj', onClick: () => navigate(`/editIngredient/${id}`) },
                    {
                      name: 'Usuń',
                      modal: true,
                      modalText: `Czy jesteś pewien, że chcesz usunąć "${name}"?`,
                      modalButton: `Usuń`,
                      submitAction: () =>
                        dispatch(removeIngredientAction(id)).then(() =>
                          dispatch(
                            fetchIngredientsAction(queryParams.page, queryParams.searchPhrase),
                          ),
                        ),
                    },
                  ]}
                />
                <h1>{name}</h1>
                <NutrientsContainer
                  id="nutrientSwiper"
                  centeredSlides={false}
                  navigation={{
                    nextEl: '.next',
                  }}
                  breakpoints={{
                    1: {
                      slidesPerView: 1,
                    },
                    350: {
                      slidesPerView: 2,
                    },
                    550: {
                      slidesPerView: 3,
                    },
                    670: {
                      slidesPerView: 4,
                    },
                    900: {
                      slidesPerView: 6,
                    },
                  }}
                  direction={'horizontal'}
                >
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Kalorie</p>
                      <p>
                        {parseFloat((calories * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Tłuszcze</p>
                      <p>
                        {parseFloat((fats * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Białko</p>
                      <p>
                        {parseFloat((proteins * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Węgle</p>
                      <p>
                        {parseFloat((carbohydrates * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Cukry</p>
                      <p>
                        {parseFloat((sugars * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <NutrientWrapper>
                      <p>Sól</p>
                      <p>
                        {parseFloat((salt * 100).toFixed(3))}
                        <span>/100g</span>
                      </p>
                    </NutrientWrapper>
                  </SwiperSlide>
                </NutrientsContainer>
              </StyledIngredient>
            ),
          )}
      </StyledWrapper>
      <Pagination
        onClick={setPage}
        active={queryParams.page}
        numberOfPages={numberOfIngredientsFound / pageSize}
      />
    </>
  );
};

Ingredients.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      unitOfMeasure: PropTypes.string.isRequired,
      calories: PropTypes.number.isRequired,
      fats: PropTypes.number.isRequired,
      carbohydrates: PropTypes.number.isRequired,
      sugars: PropTypes.number.isRequired,
      proteins: PropTypes.number.isRequired,
      salt: PropTypes.number.isRequired,
    }),
  ),
  numberOfIngredientsFound: PropTypes.number,
};

Ingredients.defaultProps = {
  ingredients: [],
  numberOfIngredientsFound: 0,
};

export default Ingredients;
