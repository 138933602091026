import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftS } from '@styled-icons/remix-line/ArrowLeftS';
import { ArrowRightS } from '@styled-icons/remix-line/ArrowRightS';
import { Add } from '@styled-icons/ionicons-sharp/Add';
import { routes } from '../../routes';
import { addDays, FormattedDate } from '../../helpers/Functions';
const StyledDayPickerItemWrapper = styled.div`
  margin: 0 6px;
  cursor: pointer;
`;

const StyledNewItemLink = styled(Add)`
  position: absolute;
  top: 2px;
  right: 2px;
  color: ${({ theme }) => theme.grey};
  @media (max-width: 600px) {
    width: 15px !important;
    height: 15px !important;
  }
`;

const StyledDaySquare = styled.div`
  width: 80px;
  height: 80px;
  transition: 0.3s;
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSize.xs};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  @media (max-width: 450px) {
    width: 55px;
    height: 55px;
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
  :before {
    content: '';
    height: 5px;
    border-radius: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  :after {
    content: '';
    z-index: 1;
    height: 5px;
    width: ${(props) =>
      props.$caloriesGoal > 0 ? (props.calories / props.$caloriesGoal) * 100 + '%' : '0px'};
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.secondary};
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${({ theme }) => theme.primary};
    `};

  ${(props) =>
    props.$caloriesGoal > 0 &&
    props.$caloricSurplus &&
    css`
      :after {
        width: 100%;
      }
    `};

  ${(props) =>
    !props.$caloriesBar &&
    css`
      :before,
      :after {
        width: 0%;
      }
    `}
`;

const StyledDay = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  margin: 10px auto 0 auto;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

const StyledDate = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.black};
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`;

const StyledToday = styled.div`
  color: #eee;
  font-weight: ${({ theme }) => theme.bold};
  position: absolute;
  bottom: -30px;
  color: ${({ theme }) => theme.black};
  left: 0;
  right: 0;
  text-align: center;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    bottom: -20px;
  }
`;

const DayPickerItem = ({
  newItemButton,
  caloriesBar,
  selected,
  onClick,
  day,
  value,
  caloriesGoal,
}) => {
  const valueForCalories = value ? value : 0;
  const navigate = useNavigate();

  return (
    <StyledDayPickerItemWrapper
      title={caloriesGoal > 0 ? (value ? ((value / caloriesGoal) * 100).toFixed(0) + '%' : '') : ''}
      className="dayPickerItem"
      onClick={onClick}
    >
      <StyledDaySquare
        $caloriesGoal={caloriesGoal}
        $caloriesBar={caloriesBar}
        calories={valueForCalories}
        $caloricSurplus={valueForCalories > caloriesGoal}
        selected={selected}
      >
        {newItemButton && (
          <StyledNewItemLink
            onClick={() => navigate(routes.addMeal, { state: { date: day } })}
            size="20"
          />
        )}
        <StyledDay>{day.split('-')[2]}</StyledDay>
        <StyledDate>
          {day.split('-')[1]}/{day.split('-')[0].slice(2)}
        </StyledDate>
        {day === FormattedDate(new Date()) && <StyledToday>DZIŚ</StyledToday>}
      </StyledDaySquare>
    </StyledDayPickerItemWrapper>
  );
};
/* 















*/
const StyledDayPickerWrapper = styled.div`
  text-align: center;
  margin: 30px 0;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  > svg {
    cursor: pointer;
    color: ${({ theme }) => theme.lightSecondary};
    width: 40px;
    height: 40px;
    transition: 0.2s;
    :hover {
      color: ${({ theme }) => theme.secondary};
    }
    @media (max-width: 450px) {
      width: 25px;
      height: 25px;
    }
  }
`;

const StyledDaysWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  & > {
    *:nth-child(1),
    *:nth-last-child(1) {
      @media (max-width: 900px) {
        display: none;
      }
    }

    *:nth-child(2),
    *:nth-last-child(2) {
      @media (max-width: 600px) and (min-width: 450px) {
        display: none;
      }
      @media (max-width: 370px) {
        display: none;
      }
    }

    *:nth-child(3),
    *:nth-last-child(3) {
      @media (max-width: 250px) {
        display: none;
      }
    }
  }
`;

const DayPicker = ({
  fetchSelectedDaysToParent,
  multipleDaySelect,
  daysCount,
  valueForTheDay,
  visibleDaysChanged,
  newItemButton,
  caloriesBar,
  caloriesGoal,
}) => {
  const initialStartDay = FormattedDate(addDays(new Date(), -3));
  const [startDay, setStartDay] = useState(initialStartDay);
  const [weekdays, setWeekdays] = useState([initialStartDay]);
  const [selectedDays, setSelectedDays] = useState([FormattedDate(addDays(initialStartDay, 3))]);

  useEffect(() => {
    let days = [startDay];
    for (let index = 1; index < daysCount; index++) {
      const day = FormattedDate(addDays(startDay, index));
      days = [...days, day];
    }
    setWeekdays(days);
    if (visibleDaysChanged) {
      visibleDaysChanged(days);
    }
    // eslint-disable-next-line
  }, [startDay, daysCount]);

  useEffect(() => {
    fetchSelectedDaysToParent(selectedDays);
    // eslint-disable-next-line
  }, [selectedDays]);

  useEffect(() => {
    let days = [startDay];
    for (let index = 1; index < daysCount; index++) {
      const day = FormattedDate(addDays(startDay, index));
      days = [...days, day];
    }
    setWeekdays(days);
    // eslint-disable-next-line
  }, [startDay]);
  return (
    <StyledDayPickerWrapper>
      <ArrowLeftS onClick={() => setStartDay(FormattedDate(addDays(startDay, -1)))} />
      <StyledDaysWrapper>
        {weekdays.map((day, i) => (
          <DayPickerItem
            caloriesGoal={caloriesGoal}
            newItemButton={newItemButton}
            caloriesBar={caloriesBar}
            value={valueForTheDay ? valueForTheDay(day) : undefined}
            day={day}
            key={i}
            selectedDays={selectedDays}
            onClick={() => {
              if (!multipleDaySelect) setSelectedDays([day]);
              else {
                if (selectedDays.some((s) => s === day))
                  setSelectedDays(selectedDays.filter((s) => s !== day));
                else setSelectedDays([...selectedDays, day]);
              }
            }}
            selected={selectedDays.some((s) => s === day)}
          />
        ))}
      </StyledDaysWrapper>
      <ArrowRightS onClick={() => setStartDay(FormattedDate(addDays(startDay, 1)))} />
    </StyledDayPickerWrapper>
  );
};

DayPicker.propTypes = {
  fetchSelectedDaysToParent: PropTypes.func,
  multipleDaySelect: PropTypes.bool,
  daysCount: PropTypes.number,
  valueForTheDay: PropTypes.func,
  visibleDaysChanged: PropTypes.func,
  newItemButton: PropTypes.bool,
  caloriesBar: PropTypes.bool,
  caloriesGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DayPickerItem.propTypes = {
  newItemButton: PropTypes.bool,
  caloriesBar: PropTypes.bool,
  caloriesGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  day: PropTypes.string,
  value: PropTypes.string,
};

export default DayPicker;
