import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InformationSolid } from '@styled-icons/zondicons/InformationSolid';

const StyledContainer = styled.div`
  text-align: center;
  position: relative;
`;

const StyledIcon = styled(InformationSolid)`
  margin-bottom: 10px;
  color: ${({ status, theme }) => (status === 'Rejected' ? theme.red : theme.orange)};
`;

const StyledMessage = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 15px;
  bottom: 20px;
  padding: 10px;
  width: 200px;
  z-index: 5;
  background-color: ${({ theme }) => theme.white};
  border: 2px solid ${({ status, theme }) => (status === 'Rejected' ? theme.red : theme.orange)};
  align-items: center;
  display: ${props => (props.active ? 'block' : 'none')};
  > h1 {
    color: ${({ theme }) => theme.grey};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.bold};
    margin: 0;
  }
  > p {
    padding: 0;
    margin: 5px 0 0 0;
    overflow-wrap: anywhere;
  }
`;

const VerificationStatusPopup = ({ verificationMessage, verificationStatus }) => {
  const [messageActive, setMessageActive] = useState(false);
  const messageActiveRef = useRef(messageActive);
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('mouseup', event => {
      if (!messageActiveRef.current) return;
      var verificationStatus = ref.current;
      if (event.target !== verificationStatus) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === verificationStatus) return;
          else parent = parent.parentNode;
        }
        setMessageActive(false);
      }
    });
    return () => {
      messageActiveRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    messageActiveRef.current = messageActive;
  }, [messageActive]);

  return (
    verificationStatus &&
    verificationStatus !== 'Accepted' && (
      <StyledContainer ref={ref}>
        <StyledIcon
          status={verificationStatus}
          size="15"
          onClick={() => setMessageActive(x => !x)}
        />
        <StyledMessage status={verificationStatus} active={messageActive}>
          <h1>
            {verificationStatus === 'Rejected'
              ? 'Twój przepis został odrzucony'
              : 'Twój przepis jest rozpatrywany'}
          </h1>
          {verificationMessage && <p>{verificationMessage}</p>}
        </StyledMessage>
      </StyledContainer>
    )
  );
};

VerificationStatusPopup.propTypes = {
  verificationStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  verificationMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
export default VerificationStatusPopup;
