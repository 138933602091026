import React, { useState } from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import ConfirmModal from '../atoms/ConfirmModal';
import PropTypes from 'prop-types';
import { Trash2 } from '@styled-icons/feather/Trash2';
import { DateToFormattedTime } from '../../helpers/Functions';

const StyledContainer = styled.div`
  width: 90%;
  border-radius: 5px;
  min-width: 230px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  box-shadow: 5px 5px 15px rgba(150, 150, 150, 0.5);
  margin: 70px auto;
  padding: 35px 15px 20px 15px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  @media (min-width: 1300px) {
    width: 1100px;
  }
`;

const StyledHour = styled.div`
  margin: 0 10px;
  position: absolute;
  top: -20px;
  left: -30px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(150, 150, 150, 0.2);
  font-size: ${({ theme }) => theme.fontSize.xl};
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.m};
    left: -20px;
  }
`;

const StyledLeftSite = styled.section`
  width: 40%;
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const StyledSubtitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.bold};
  padding: 15px 0;
  margin: 0;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  > span {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const StyledMacronutrientsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  place-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.lightSecondary};
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  }
`;

const StyledMacronutrient = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  > p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  strong {
    display: block;
  }
`;

const TableRow = styled.tr`
  transition: 0.3s;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.xs};
  :hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  > td {
    .annotation {
      color: ${({ theme }) => theme.secondary};
      font-style: italic;
      font-weight: ${({ theme }) => theme.bold};
    }
    border: none;
    padding: 8px;
    :nth-child(1) {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :nth-child(2) {
      padding-right: 5px;
      text-align: right;
    }
    :nth-child(3) {
      width: 10px;
    }
  }
`;

const StyledRightSite = styled.section`
  width: 60%;
  padding: 0 15px 50px 40px;
  @media (max-width: 750px) {
    width: 100%;
    padding: 0 0 50px 0;
  }
  @media (max-width: 450px) {
    padding: 0 0 40px 0;
  }
`;

const RecipeName = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.grey};
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0;
  padding-bottom: 5px;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const StyledPreparation = styled.ol`
  font-size: ${({ theme }) => theme.fontSize.xs};
  text-align: justify;
  list-style: none;
  counter-reset: item;
  position: relative;
  word-break: break-word;
  margin: 0;
  bottom: 20px;
  padding: 0;
`;

const PreparationItem = styled.li`
  counter-increment: item;
  &:before {
    content: counter(item);
    color: ${({ theme }) => theme.secondary};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: ${({ theme }) => theme.fontSize.l};
    height: 10px;
    font-weight: ${({ theme }) => theme.light};
    width: 1.2em;
    text-align: center;
    display: inline-block;
  }
`;

const StyledRemoveButton = styled(Trash2)`
  position: absolute;
  bottom: 40px;
  right: 30px;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  transition: 0.5s;
  :hover {
    color: ${({ theme }) => theme.secondary};
    transform: scale(1.1);
  }
  @media (max-width: 750px) {
    right: 10px;
    bottom: 10px;
  }
  @media (max-width: 450px) {
    width: 35px !important;
    height: 35px !important;
  }
`;

const MealCard = ({ meal, macronutrientsToEat, ingredientsToCook, removeMealAction }) => {
  const [activeModal, setActiveModal] = useState(false);
  return (
    <StyledContainer>
      <StyledHour>{DateToFormattedTime(new Date(meal.dateTime))}</StyledHour>
      <StyledLeftSite>
        <StyledSubtitle>
          Makroskładniki{' '}
          <span>
            / {meal.numberOfPortionsToEat}{' '}
            {meal.numberOfPortionsToEat === 1
              ? 'porcja'
              : meal.numberOfPortionsToEat < 5
              ? 'porcje'
              : 'porcji'}
          </span>
        </StyledSubtitle>
        <StyledMacronutrientsContainer>
          <StyledMacronutrient>
            <p>
              <strong>Białko</strong>
              {macronutrientsToEat.proteins.toFixed(1)}
            </p>
          </StyledMacronutrient>
          <StyledMacronutrient>
            <p>
              <strong>Tłuszcze</strong>
              {macronutrientsToEat.fats.toFixed(1)}
            </p>
          </StyledMacronutrient>
          <StyledMacronutrient>
            <p>
              <strong>Węgle</strong>
              {macronutrientsToEat.carbs.toFixed(1)}
            </p>
          </StyledMacronutrient>
          <StyledMacronutrient>
            <p>
              <strong>Cukry</strong>
              {macronutrientsToEat.sugars.toFixed(1)}
            </p>
          </StyledMacronutrient>
          <StyledMacronutrient>
            <p>
              <strong>Sól</strong>
              {macronutrientsToEat.salt.toFixed(1)}
            </p>
          </StyledMacronutrient>
          <StyledMacronutrient>
            <p>
              <strong>Kalorie</strong>
              {macronutrientsToEat.calories.toFixed(1)}
            </p>
          </StyledMacronutrient>
        </StyledMacronutrientsContainer>
        <StyledSubtitle>
          Składniki{' '}
          <span>
            / {meal.numberOfPortionsToCook}{' '}
            {meal.numberOfPortionsToCook === 1
              ? 'porcja'
              : meal.numberOfPortionsToCook < 5
              ? 'porcje'
              : 'porcji'}
          </span>
        </StyledSubtitle>
        <Table>
          <tbody>
            {ingredientsToCook.map(({ name, annotation, amount, unitOfMeasure }) => (
              <TableRow key={name}>
                <td>
                  {name}
                  {annotation && <span className="annotation"> ({annotation})</span>}
                </td>
                <td>{amount}</td>
                <td>{unitOfMeasure}</td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </StyledLeftSite>
      <StyledRightSite>
        <RecipeName title={meal.recipe.name}>{meal.recipe.name}</RecipeName>
        <StyledPreparation>
          {meal.recipe.preparation
            .split('*')
            .filter((pi) => pi.trim() !== '')
            .map((pi, index) => (
              <PreparationItem key={index}>{pi}</PreparationItem>
            ))}
        </StyledPreparation>
      </StyledRightSite>
      <StyledRemoveButton size="40" onClick={() => setActiveModal(true)} />
      <ConfirmModal
        activeModal={activeModal}
        closeModal={() => setActiveModal(false)}
        text={'Czy jesteś pewien, że chcesz usunąć "' + meal.recipe.name + '"?'}
        submitButtonText="Usuń"
        onModalSubmit={() => removeMealAction(meal.id)}
      />
    </StyledContainer>
  );
};

MealCard.propTypes = {
  meal: PropTypes.object,
  macronutrientsToEat: PropTypes.object,
  ingredientsToCook: PropTypes.array,
  removeMealAction: PropTypes.func,
};

export default MealCard;
