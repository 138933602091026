import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import AuthTemplate from '../templates/AuthTemplate';
import { routes } from '../routes';
import {
  registration as registrationAction,
  clearErrors as clearRegistrationErrorsAction,
} from '../actions';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 40px auto;
  width: 80;
`;

const RegisterPage = () => {
  const [loadingActive, setLoadingActive] = useState(false);

  const { registrationErrors, user } = useSelector(state => ({
    registrationErrors: state.registrationErrors,
    user: state.user,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRegistrationErrorsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (registrationErrors) setLoadingActive(false);
  }, [registrationErrors]);

  return (
    <Formik
      initialValues={{
        username: 'User' + Math.round(Math.random() * 89999 + 10000),
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email jest wymagany'),
        password: Yup.string()
          .min(6, 'Hasło nie może być krótsze niż 6 znaków')
          .required('Hasło jest wymagane'),
      })}
      onSubmit={({ username, email, password }) => {
        setLoadingActive(true);

        dispatch(registrationAction(username, email, password))
          .then(() => history.push(routes.confirmationLinkSent));
      }}
    >
      {({ handleChange, handleBlur, errors, touched, values }) => {
        if (user) {
          return <Navigate to={routes.home} />;
        }
        return (
          <AuthTemplate
            loadingActive={loadingActive}
            header="Rejestracja"
            buttonText="Dołącz"
            linkText="Masz już konto? Zaloguj się!"
            linkUrl={routes.login}
          >
            <StyledFormInput
              labelName="Email"
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors.email}
              touched={touched.email}
            />

            <StyledFormInput
              labelName="Hasło"
              name="password"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              errors={errors.password}
              touched={touched.password}
            />
            {registrationErrors &&
              registrationErrors.map((error, i) => (
                <FormErrorMessage key={i}>{error}</FormErrorMessage>
              ))}
          </AuthTemplate>
        );
      }}
    </Formik>
  );
};

RegisterPage.propTypes = {
};

export default RegisterPage;
