import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  addIngredient as addIngredientAction,
  editIngredient as editIngredientAction,
  fetchIngredients as fetchIngredientsAction,
  fetchSpecificIngredient as fetchSpecificIngredientAction,
} from '../actions';
import MultiStepFormTemplate from '../templates/MultiStepFormTemplate';
import FormInput from '../components/atoms/FormInput';

const MacronutrientsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  padding: 10px;
  justify-content: center;
  place-items: center;
  align-items: center;
  > div {
    padding: 0 10px;
    margin: 20px 0;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

const AddEditIngredient = () => {
  const { id } = useParams();
  const isAddMode = !id;
  const specificIngredient = useSelector(state => state.specificIngredient);
  const dispatch = useDispatch();
  const pageNumber = '1';
  const searchString = '';
  const pageSize = 99999;

  const [initialValues, setInitialValues] = useState(
    isAddMode
      ? {
          name: '',
          // unitOfMeasure: '',
          calories: '',
          fats: '',
          carbohydrates: '',
          sugars: '',
          proteins: '',
          salt: '',
        }
      : undefined,
  );

  useEffect(() => {
    if (isAddMode) dispatch(fetchIngredientsAction(pageNumber, searchString, pageSize));
    else if (!isAddMode) {
      dispatch(fetchSpecificIngredientAction(id));
    }
  }, [dispatch, id, isAddMode]);

  useEffect(() => {
    if (!isAddMode) {
      if (specificIngredient && specificIngredient.id === id) setInitialValues(specificIngredient);
    }
  }, [specificIngredient, id, isAddMode]);

  // const validateUniqueName = ingredientName => {
  //   if (
  //     ingredients &&
  //     ingredients.filter(i => i.name.toLowerCase() === ingredientName.toLowerCase()).length > 0
  //   )
  //     return 'Ingredient with this name already exists';
  //   return undefined;
  // };

  if (!initialValues) return <></>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(100, 'Nazwa może zawierać maksymalnie 100 znaków!')
          .required('Nazwa jest wymagana!'),
        // unitOfMeasure: Yup.string().required('This field is required'),
        calories: Yup.number()
          .min(0, 'Ilość kalorii nie może być miejsza niż 0!')
          .max(10000, 'Ilość kalorii nie może być większa niż 10000!')
          .required('Kalorie są wymagane!'),
        fats: Yup.number()
          .min(0, 'Ilość tłuszczów nie może być mniejsza niż 0!')
          .max(10000, 'Ilość tłuszczów nie może być mniejsza niż 10000!')
          .required('Tłuszcze są wymagane!'),
        carbohydrates: Yup.number()
          .min(0, 'Ilość węglowodanów nie może być mniejsza niż 0!')
          .max(10000, 'Ilość węglowodanów nie może być mniejsza niż 10000!')
          .required('Węglowodany są wymagane!'),
        sugars: Yup.number()
          .min(0, 'Ilość cukrów nie może być mniejsza niż 0!')
          .max(10000, 'Ilość cukrów nie może być mniejsza niż 10000!')
          .required('Cukry są wymagane!'),
        proteins: Yup.number()
          .min(0, 'Ilość białka nie może być mniejsza niż 0!')
          .max(10000, 'Ilość białka nie może być mniejsza niż 10000!')
          .required('Białko jest wymagane!'),
        salt: Yup.number()
          .min(0, 'Ilość soli nie może być mniejsza niż 0!')
          .max(10000, 'Ilość soli nie może być mniejsza niż 10000!')
          .required('Sól jest wymagana!'),
      })}
      onSubmit={values => {
        if (isAddMode) {
          dispatch(addIngredientAction(values));
        } else if (!isAddMode) {
          dispatch(editIngredientAction(values, id));
        }
      }}
    >
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <MultiStepFormTemplate
            title={isAddMode ? 'Nowy składnik' : 'Edytuj składnik'}
            steps={2}
            nextButtonDisabled={currentStep => {
              switch (currentStep) {
                case 1:
                  return errors.name || !values.name;
                // case 2:
                //   return errors.unitOfMeasure || !values.unitOfMeasure;
                case 2:
                  return (
                    errors.calories ||
                    !values.calories ||
                    errors.fats ||
                    !values.fats ||
                    errors.carbohydrates ||
                    !values.carbohydrates ||
                    errors.sugars ||
                    !values.sugars ||
                    errors.proteins ||
                    !values.proteins ||
                    errors.salt ||
                    !values.salt
                  );
                default:
                  return false;
              }
            }}
            createChild={currentStep => {
              switch (currentStep) {
                case 1:
                  return (
                    <FormInput
                      labelName="Nazwa"
                      name="name"
                      type="text"
                      errors={errors.name}
                      touched={touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // validate={validateUniqueName}
                      onKeyPress={e => (e.key === 'Enter' ? e.preventDefault() : undefined)}
                      autocomplete={isAddMode ? 'off' : null}
                      value={values.name}
                    />
                  );
                // case 2:
                //   return (
                //     <SelectOption
                //       style={{ margin: '0 auto' }}
                //       labelName="Unit of measure"
                //       name="unitOfMeasure"
                //       onChange={setFieldValue}
                //       onBlur={() => {
                //         touched.unitOfMeasure = true;
                //         validateForm();
                //       }}
                //       options={['Gram', 'Milliliter']}
                //       touched={touched.unitOfMeasure}
                //       errors={errors.unitOfMeasure}
                //       value={values.unitOfMeasure}
                //     />
                //   );
                case 2:
                  return (
                    <MacronutrientsWrapper>
                      <FormInput
                        labelName="Kalorie"
                        name="calories"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.calories}
                        errors={errors.calories}
                        touched={touched.calories}
                      />
                      <FormInput
                        labelName="Tłuszcze"
                        name="fats"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fats}
                        errors={errors.fats}
                        touched={touched.fats}
                      />
                      <FormInput
                        labelName="Węglowodany"
                        name="carbohydrates"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.carbohydrates}
                        errors={errors.carbohydrates}
                        touched={touched.carbohydrates}
                      />
                      <FormInput
                        labelName="Cukry"
                        name="sugars"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sugars}
                        errors={errors.sugars}
                        touched={touched.sugars}
                      />
                      <FormInput
                        labelName="Białko"
                        name="proteins"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.proteins}
                        errors={errors.proteins}
                        touched={touched.proteins}
                      />
                      <FormInput
                        labelName="Sól"
                        name="salt"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.salt}
                        errors={errors.salt}
                        touched={touched.salt}
                      />
                    </MacronutrientsWrapper>
                  );
                default:
                  return null;
              }
            }}
          />
        );
      }}
    </Formik>
  );
};

AddEditIngredient.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  match: PropTypes.object,
};

AddEditIngredient.defaultProps = { ingredients: [] };

export default AddEditIngredient;
