import styled from 'styled-components';

const Logo = styled.h1`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.xxxl};
  font-family: ${({ theme }) => theme.primaryFont};
  text-align: center;
  margin-top: 7px;
  text-transform: uppercase;
  letter-spacing: 13px;
  color: ${({ theme }) => theme.black};
  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`;

export default Logo;
