import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CarrotColor from '!file-loader!../../assets/BoldCarrotColor.svg';
import { routes } from '../../routes';
import { Printer } from '@styled-icons/bootstrap/Printer';
import { SuitHeart } from '@styled-icons/bootstrap/SuitHeart';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  display: flex;
  flex-flow: row nowrap;
  z-index: 6;
  @media (max-width: 450px) {
    bottom: 30px;
    right: 30px;
    top: unset;
    left: unset;
  }
`;

const IconWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.white};
  border-top: none;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 0 0 10px 10px;
  margin: 0 8px;
  cursor: pointer;
  transition: 0.3s;
  #heart {
    color: ${({ theme }) => theme.red};
  }
  #heart,
  .icon {
    width: 25px;
    height: 25px;
    margin: 10px;
  }
  #printer {
    color: ${({ theme }) => theme.black};
  }
  :hover {
    background: ${({ theme }) => theme.white};
  }
  @media (max-width: 450px) {
    border-top: 2px solid ${({ theme }) => theme.white};
    border-radius: 100%;
    margin: 0 4px;
    background-color: ${({ theme }) => theme.white};
  }
`;

const PrintPanel = styled.div`
  position: absolute;
  display: none;
  top: 50px;
  left: 20px;
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  @media (max-width: 450px) {
    right: 20px;
    bottom: 50px;
    top: unset;
    left: unset;
  }

  ${(props) => props.$active ? css`display: block;` : undefined}

  > div {
    :hover {
      background-color: ${({ theme }) => theme.white};
    }
    font-weight: ${({ theme }) => theme.bold};
    white-space: nowrap;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: 10px;
    :nth-child(1) {
      border-bottom: 2px solid ${({ theme }) => theme.white};
    }
  }
`;

const Icon = styled.div`
  background-image: url(${props => props.icon});
  background-size: cover;
`;

const RecipeIcons = ({
  handlePrint,
  user,
  specificRecipe,
  addToFavourites,
  removeFromFavourites,
  match,
  setPrintWithImage,
}) => {
  const navigate = useNavigate();
  const [printPanelActive, setPrintPanelActive] = useState(false);
  const printPanelActiveRef = useRef(printPanelActive);
  useEffect(() => {
    window.addEventListener('mouseup', event => {
      if (!printPanelActiveRef.current) return;
      var printWrapper = document.getElementById('printWrapper');
      if (event.target !== printWrapper) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === printWrapper) return;
          else parent = parent.parentNode;
        }
        setPrintPanelActive(false);
      }
    });
    return () => {
      printPanelActiveRef.current = false;
    };
  }, []);

  useEffect(() => {
    printPanelActiveRef.current = printPanelActive;
  }, [printPanelActive]);

  return (
    <StyledWrapper>
      {!specificRecipe.recipe.isFavourite ? (
        <IconWrapper
          title="Dodaj do ulubionych"
          onClick={() =>
            user
              ? addToFavourites()
              : navigate('/login', { state: { from: match.url }, })
          }
        >
          <SuitHeart id="heart" />
        </IconWrapper>
      ) : (
        <IconWrapper title="Usuń z ulubionych" onClick={removeFromFavourites}>
          <SuitHeartFill className="icon" style={{ color: '#b81d1d' }} />
        </IconWrapper>
      )}
      <IconWrapper id="printWrapper" title="Drukuj" onClick={() => setPrintPanelActive(x => !x)}>
        <Printer id="printer" className="icon" />
        <PrintPanel $active={printPanelActive}>
          <div
            title=""
            onClick={() => {
              setPrintWithImage(true);
              handlePrint();
            }}
          >
            Drukuj ze zdjęciem
          </div>
          <div
            title=""
            onClick={() => {
              setPrintWithImage(false);
              handlePrint();
            }}
          >
            Drukuj bez zdjęcia
          </div>
        </PrintPanel>
      </IconWrapper>
      <IconWrapper
        title="Dodaj do diety"
        onClick={() =>
          navigate(routes.addMeal, { state: { recipeId: specificRecipe.recipe.id }})
        }
      >
        <Icon className="icon" id="carrot" icon={CarrotColor} />
      </IconWrapper>
    </StyledWrapper>
  );
};

RecipeIcons.propTypes = {
  user: PropTypes.object,
  specificRecipe: PropTypes.object,
  addToFavourites: PropTypes.func,
  setPrintWithImage: PropTypes.func,
  removeFromFavourites: PropTypes.func,
  match: PropTypes.object,
  handlePrint: PropTypes.func,
};

export default RecipeIcons;
