import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectOption from '../components/atoms/SelectOption';
import { addMeal as addMealAction, fetchRecipes as fetchRecipesAction } from '../actions';
import { FormattedDate, DateToFormattedTime } from '../helpers/Functions';
import MultiStepFormTemplate from '../templates/MultiStepFormTemplate';
import FormInput from '../components/atoms/FormInput';
import AmountPicker from '../components/atoms/AmountPicker';

const StyledAmountTitle = styled.div`
  color: ${({ theme }) => theme.grey};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AmountPickersWrapper = styled.section`
  display: flex;
  flex-flow: row nowrap;
  @media (max-width: 400px) {
    flex-flow: row wrap;
  }
`;

const AddMeal = () => {
  const recipes = useSelector((state) => state.recipes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = (recipes || []).map((r) => ({ value: r.id, label: r.name }));
  const location = useLocation();
  const defaultDateTime =
    FormattedDate(location?.state?.date ? new Date(`${location.state.date}`) : new Date()) +
    'T' +
    DateToFormattedTime(new Date());

  useEffect(() => {
    dispatch(fetchRecipesAction('1', ''));
    //eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{
        RecipeFK: location?.state?.recipeId ? `${location.state.recipeId}` : '',
        NumberOfPortionsToEat: '1',
        NumberOfPortionsToCook: '1',
        DateTime: defaultDateTime,
      }}
      validationSchema={Yup.object().shape({
        RecipeFK: Yup.string().required('Przepis jest wymagany!'),
        NumberOfPortionsToEat: Yup.number()
          .min(1)
          .max(50, 'Ilość porcji do spożycia nie może być większa niż 50!')
          .required('Ilość porcji do spożycia jest wymagana!'),
        NumberOfPortionsToCook: Yup.number()
          .min(1)
          .max(100, 'Ilość porcji do ugotowania nie może być większa niż 100!')
          .required('Ilość porcji do ugotowania jest wymagana!'),
      })}
      onSubmit={(values) => {
        dispatch(addMealAction(values))
          .then(() => {
            navigate(`/diet`);
          });
      }}
    >
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue, validateForm }) => {
        return (
          <>
            <MultiStepFormTemplate
              title="Nowy posiłek"
              steps={3}
              nextButtonDisabled={(currentStep) => {
                switch (currentStep) {
                  case 1:
                    return errors.RecipeFK || !values.RecipeFK;
                  case 2:
                    return (
                      errors.NumberOfPortionsToEat ||
                      errors.NumberOfPortionsToCook ||
                      !values.NumberOfPortionsToEat ||
                      !values.NumberOfPortionsToCook
                    );
                  case 3:
                    return true;
                  default:
                    return false;
                }
              }}
              createChild={(currentStep) => {
                switch (currentStep) {
                  case 1:
                    return (
                      <SelectOption
                        style={{ margin: '0 auto' }}
                        labelName="Przepis"
                        name="RecipeFK"
                        onChange={setFieldValue}
                        onBlur={() => {
                          touched.RecipeFK = true;
                          validateForm();
                        }}
                        options={options}
                        touched={touched.RecipeFK}
                        errors={errors.RecipeFK}
                        value={values.RecipeFK}
                      />
                    );
                  case 2:
                    return (
                      <AmountPickersWrapper>
                        <AmountPicker
                          min="1"
                          max="50"
                          itemTitle="Porcje do spożycia"
                          name="numberOfPortionsToEat"
                          onChange={(e) => {
                            setFieldValue('numberOfPortionsToEat', e);
                            touched.numberOfPortionsToEat = true;
                            validateForm();
                          }}
                          value={values.numberOfPortionsToEat}
                        />
                        <AmountPicker
                          min="1"
                          max="100"
                          itemTitle="Porcje do ugotowania"
                          name="numberOfPortionsToCook"
                          onChange={(e) => {
                            setFieldValue('numberOfPortionsToCook', e);
                            touched.numberOfPortionsToCook = true;
                            validateForm();
                          }}
                          value={values.numberOfPortionsToCook}
                        />
                      </AmountPickersWrapper>
                    );
                  case 3:
                    return (
                      <FormInput
                        id="dateTime"
                        label="Data"
                        style={{ margin: '0 auto' }}
                        name="DateTime"
                        type="datetime-local"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.DateTime ?? defaultDateTime}
                      />
                    );
                  default:
                    return null;
                }
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};

AddMeal.propTypes = {
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
};

AddMeal.defaultProps = {
  recipes: [],
};

export default AddMeal;
