import React from 'react';
import { BrowserRouter, Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { routes } from '../routes';
import PropTypes from 'prop-types';
import store from '../store/index';
import MainTemplate from '../templates/MainTemplate';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import Home from './Home';
import UserRecipes from './UserRecipes';
import Ingredients from './Ingredients';
import RecipeDetails from './RecipeDetails';
import Diet from './Diet';
import AddMeal from './AddMeal';
import AddEditRecipe from './AddEditRecipe';
import AddEditIngredient from './AddEditIngredient';
import ShoppingList from './ShoppingList';
import Account from './Account';
import UserData from './UserData';
import ChangeEmail from './ChangeEmail';
import AdminPage from './AdminPage';
import ChangeNutrientsGoal from './ChangeNutrientsGoal';
import ChangePassword from './ChangePassword';
import ChangeUsername from './ChangeUsername';
import NutrientsGoalTimeline from './NutrientsGoalTimeline';
import DeleteAccount from './DeleteAccount';
import UserPageTemplate from '../templates/UserPageTemplate';
import ShortMessageTemplate from '../templates/ShortMessageTemplate';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';

const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      <MainTemplate>
        <UserPageTemplate>
          <Routes>
            <Route
              exact
              path={routes.mainPage}
              render={(props) => <Navigate {...props} to="/home" />}
            />
            <Route exact path={routes.login} element={<LoginPage/>} />
            <Route exact path={routes.register} element={<RegisterPage/>} />
            <Route exact path={routes.forgetPassword} element={<ForgetPassword/>} />
            <Route
              exact
              path={routes.home}
              element={<Home />}
            />
            <Route path={routes.recipe} element={<RecipeDetails/>} />
            <Route exact path={routes.resetPassword} component={ResetPassword} />
            <Route
              exact
              path={routes.passwordReseted}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  link={routes.login}
                  buttonLink={routes.login}
                  cooldown={3000}
                  firstLineText="Hasło zostało zmienione!"
                  secondLineText="Za 3 sekundy zostaniesz przekierowany na stronę logowania."
                  buttonText="Wróć do strony logowania"
                />
              )}
            />
            <Route
              exact
              path={routes.passwordChanged}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  buttonLink={routes.home}
                  cooldown={undefined}
                  firstLineText="Hasło zostało zmienione!"
                  buttonText="Wróć na stronę główną"
                />
              )}
            />
            <Route
              exact
              path={routes.emailConfirmed}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  link={routes.login}
                  buttonLink={routes.login}
                  cooldown={3000}
                  firstLineText="Email potwierdzony!"
                  secondLineText="Za 3 sekundy zostaniesz przekierowany na stronę logowania."
                  buttonText="Wróć do strony logowania"
                />
              )}
            />
            <Route
              exact
              path={routes.resetPasswordLinkSent}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  buttonLink={routes.login}
                  buttonText="Wróć do strony logowania"
                  cooldown={undefined}
                  firstLineText="Link do zmiany hasła został wysłany!"
                  secondLineText="Zmień hasło klikając w link otrzymany w treści maila."
                />
              )}
            />
            <Route
              exact
              path={routes.confirmationLinkSent}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  cooldown={undefined}
                  firstLineText="Link potwierdzający został wysłany."
                  secondLineText="Zweryfikuj email klikając w otrzymany link."
                  buttonText="Przejdź do strony logowania"
                  buttonLink={routes.login}
                />
              )}
            />
            <Route
              exact
              path={routes.usernameChanged}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  link={routes.login}
                  cooldown={3000}
                  firstLineText="Nazwa użytkownika została zmieniona."
                  secondLineText="Za 3 sekundy zostaniesz przekierowany na stronę logowania."
                  buttonText="Przejdź do strony logowania"
                  buttonLink={routes.login}
                />
              )}
            />
            <Route
              exact
              path={routes.emailChanged}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  link={routes.login}
                  cooldown={3000}
                  firstLineText="Email został zmieniony."
                  secondLineText="Za 3 sekundy zostaniesz przekierowany na stronę logowania."
                  buttonText="Przejdź do strony logowania"
                  buttonLink={routes.login}
                />
              )}
            />
            <Route
              exact
              path={routes.accountDeleted}
              render={(props) => (
                <ShortMessageTemplate
                  {...props}
                  link={routes.home}
                  cooldown={3000}
                  firstLineText="Twoje konto zostało usunięte."
                  secondLineText="Za 3 sekundy zostaniesz przekierowany na stronę główną."
                  buttonText="Przejdź do strony głównej"
                  buttonLink={routes.home}
                />
              )}
            />

            <Route exact path={routes.recipes} element={<AuthenticatedRoute component={UserRecipes} getKey={() => new Date()} />} />
            <Route exact path={routes.ingredients} element={<AuthenticatedRoute component={Ingredients} getKey={() => new Date()} />} />
            <Route exact path={routes.diet} element={<AuthenticatedRoute component={Diet} />} />
            <Route exact path={routes.addMeal} element={<AuthenticatedRoute component={AddMeal} />} />
            <Route exact path={routes.addRecipe} element={<AuthenticatedRoute component={AddEditRecipe} />} />
            <Route exact path={routes.editRecipe} element={<AuthenticatedRoute component={AddEditRecipe} />} />
            <Route exact path={routes.addIngredient} element={<AuthenticatedRoute component={AddEditIngredient} />} />
            <Route exact path={routes.editIngredient} element={<AuthenticatedRoute component={AddEditIngredient} />} />
            <Route exact path={routes.shoppingList}  element={<AuthenticatedRoute component={ShoppingList} />} />
            <Route exact path={routes.account} element={<AuthenticatedRoute component={Account} />} />
            <Route exact path={routes.userData} element={<AuthenticatedRoute component={UserData} />} />
            <Route exact path={routes.changeUsername} element={<AuthenticatedRoute component={ChangeUsername} />} />
            <Route exact path={routes.changePassword} element={<AuthenticatedRoute component={ChangePassword} />} />
            <Route exact path={routes.changeEmail} element={<AuthenticatedRoute component={ChangeEmail} />} />
            <Route exact path={routes.adminPage} element={<AuthenticatedRoute component={AdminPage} />} />
            <Route exact path={routes.nutrientsGoalTimeline} element={<AuthenticatedRoute component={NutrientsGoalTimeline} />} />
            <Route exact path={routes.nutrientsGoal} element={<AuthenticatedRoute component={ChangeNutrientsGoal} />} />
            <Route exact path={routes.deleteAccount} element={<AuthenticatedRoute component={DeleteAccount} />} />

          </Routes>
        </UserPageTemplate>
      </MainTemplate>
    </BrowserRouter>
  </Provider>
);

const AuthenticatedRoute = ({ component: Component, getKey, ...props}) => (
      localStorage.getItem('token') 
      ? <Component {...props} key={getKey ? getKey() : props.key} />
      : <Navigate {...props} to={{ pathname: '/login', state: { from: props.location }, }}
  />
);

// const PrivateRoute = ({component: Component, getKey, ...rest}) => {
//   return localStorage.getItem('token')  
//     ? <Component {...props} key={getKey ? getKey() : props.key} />
//     : <Navigate
//           {...props}
//           to={{
//             pathname: '/login',
//             state: { from: props.location },
//           }}
//         />
// };
// const AuthenticatedRoute = ({ component: Component, getKey, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       localStorage.getItem('token') ? (
//         <Component {...props} key={getKey ? getKey() : props.key} />
//       ) : (
//         <Navigate
//           {...props}
//           to={{
//             pathname: '/login',
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );

// AuthenticatedRoute.propTypes = {
//   component: PropTypes.func,
//   getKey: PropTypes.func,
//   key: PropTypes.object,
//   location: PropTypes.object,
// };

export default Root;
