import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RecipeIcons from '../components/atoms/RecipeIcons';
import {
  fetchSpecificRecipe as fetchSpecificRecipeAction,
  addToFavourites as addToFavouritesAction,
  removeFromFavourites as removeFromFavouritesAction,
} from '../actions';
import { ArrowHookDownLeft } from '@styled-icons/fluentui-system-filled/ArrowHookDownLeft';
import PortionsCounter from '../components/atoms/PortionsCounter';
import { Person } from '@styled-icons/bootstrap/Person';
import { Timer } from '@styled-icons/material/Timer';
import { useReactToPrint } from 'react-to-print';
import RecipeToPrint from '../components/organisms/RecipeToPrint';
import MacronutrientInfoWrapper from '../components/molecules/MacronutrientInfoWrapper';

const BackButton = styled(ArrowHookDownLeft)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  padding: 10px;
  margin: 0;
  left: 10px;
  top: 10px;
  position: fixed;
  display: none;
  z-index: 6;
  color: ${({ theme }) => theme.grey};
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  @media (max-width: 450px) {
    display: block;
  }
`;

// const MoonIcon = styled.div`
//   cursor: pointer;
//   display: none;
//   position: fixed;
//   top: 20px;
//   right: 20px;
//   z-index: 10;
//   background-image: url(${moon});
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 20px;
//   height: 20px;
//   @media (max-width: 450px) {
//     display: block;
//   }
//   ${(props) =>
//     props.wakeLockEnabled &&
//     css`
//       background-image: url(${moonOff});
//     `}
// `;

// const SleepModeText = styled.div`
//   cursor: default;
//   z-index: 10;
//   position: fixed;
//   display: none;
//   left: 65px;
//   bottom: 75px;
//   background: rgba(255, 255, 255, 0.7);
//   backdrop-filter: blur(8px);
//   border-radius: 0 5px 5px 0;
//   font-weight: ${({ theme }) => theme.bold};
//   transform: translateX(-150%);
//   transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   font-size: ${({ theme }) => theme.fontSize.xxs};
//   text-align: center;
//   text-transform: uppercase;
//   padding: 5px;
//   width: fit-content;
//   color: ${({ theme }) => theme.grey};
//   @media (max-width: 450px) {
//     display: block;
//   }
//   ${(props) =>
//     props.wakeLockEnabled &&
//     css`
//       transform: translateX(0%);
//       @media (max-width: 450px) {
//         left: 50%;
//         transform: translateX(-50%);
//         bottom: 90px;
//         border-radius: 5px;
//       }
//     `};
// `;

const StyledWrapper = styled.div`
  width: 1200px;
  margin: 50px auto 25px auto;
  display: grid;
  grid-gap: 35px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    width: 50%;
    width: 600px;
    #ingredientsSection,
    #preparationSection,
    #headerAndNutrientsSection {
      text-align: center;
    }
    #headerAndNutrientsSection {
      grid-row: 2/3;
    }
    #imageSection {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    #ingredientsSection {
      grid-column: 1/2;
      grid-row: 3/4;
    }
    #preparationSection {
      grid-column: 1/2;
      grid-row: 4/5;
    }
  }
  @media (max-width: 715px) {
    width: 99%;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const HeaderAndNutrientsSection = styled.section`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 715px) {
    padding: 30px 10px 0 10px;
  }
  @media (max-width: 450px) {
    transform: translateY(-80px);
    background-color: ${({ theme }) => theme.white};
    border-radius: 35px 35px 0 0;
    z-index: 5;
  }
`;

const StyledRecipeName = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  text-overflow: ellipsis;
  letter-spacing: 2px;
  white-space: wrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.l};
    margin: 0;
  }
`;
const StyledSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.grey};
  padding: 0;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  @media (max-width: 1310px) {
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const StyledInfo = styled.div`
  color: ${({ theme }) => theme.black};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.bold};
  margin: 0 5px 0 0;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  @media (max-width: 700px) {
    font-size: 0.6em;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
  > svg {
    margin: 0 5px 2px 0;
    color: ${({ theme }) => theme.grey};
  }

  > div {
    color: ${({ theme }) => theme.black};
    padding: 5px;
    border-radius: 15px;
    width: fit-content;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 10px 0;
`;

const SectionName = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  padding: 0;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.light};
  margin: 0;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.secondary};
  text-align: left;
  > p {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.grey};
    margin: 0 5px;
    text-transform: uppercase;
  }
`;

const StyledMacronutrientsContainer = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
`;

const ImageIconsWrapper = styled.section`
  height: 450px;
  background-color: ${({ theme }) => theme.white} !important;
  min-height: 450px;
  margin-left: auto;
  width: 100%;
  position: relative;
  grid-column: 2/3;
  grid-row: 1/2;
  background-color: ${({ theme }) => theme.black};
  overflow: hidden;
  border-radius: 15px;
  @media (max-width: 450px) {
    border-radius: 0;
  }
`;

const Image = styled.div`
  min-height: 450px;
  height: 450px;
  position: relative;
  width: 100%;
  background-image: url(${(props) => props.src});
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  min-width: 200px;
  background-size: cover;
  background-position: 50% 50%;
  transform: scale(1.1);
  opacity: 1;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 10vw;
  }
`;

const IngredientsSection = styled.section`
  grid-column: 1/2;
  grid-row: 2/3;
  @media (max-width: 715px) {
    padding: 0 10px;
    td {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
  @media (max-width: 450px) {
    text-align: center;
    transform: translateY(-60px);
  }
`;

const StyledTr = styled.tr`
  font-size: ${({ theme }) => theme.fontSize.xs};
  transition: 0.3s;
  :hover {
    transition: 0s;
    background-color: ${({ theme }) => theme.lightSecondary};
  }
  > td {
    .annotation {
      color: ${({ theme }) => theme.secondary};
      font-style: italic;
      font-weight: ${({ theme }) => theme.bold};
    }
    padding: 8px 12px;
    :nth-child(1) {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    :nth-child(2) {
      padding-right: 0;
      text-align: right;
    }
    :nth-child(3) {
      width: 20px;
    }
    border-color: ${({ theme }) => theme.primary} !important;
  }
`;

const PreparationSection = styled.section`
  grid-column: 2/3;
  grid-row: 2/3;
  @media (max-width: 715px) {
    padding: 0 10px;
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  @media (max-width: 450px) {
    text-align: center;
    transform: translateY(-60px);
  }
`;

const StyledPreparation = styled.ol`
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
  counter-reset: item;
  position: relative;
  bottom: 13px;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const PreparationItem = styled.li`
  counter-increment: item;
  &:first-child {
    margin-top: 17px;
  }
  &:before {
    content: counter(item);
    color: ${({ theme }) => theme.grey};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: ${({ theme }) => theme.fontSize.l};
    height: 10px;
    width: 1.2em;
    text-align: center;
    display: inline-block;
  }
`;

// var noSleep = new NoSleep();
const RecipeDetails = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  // const [wakeLockEnabled, setWakeLockEnabled] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printWithImage, setPrintWithImage] = useState(false);
  const { id } = useParams();
  const { specificRecipe, user } = useSelector((state) => ({
    specificRecipe: state.specificRecipe,
    user: state.user,
  }));

  const [ingredientsPortions, setIngredientsPortions] = useState(
    specificRecipe && specificRecipe.recipe.numberOfPortions,
  );
  const [nutrientsPortions, setNutrientsPortions] = useState(
    specificRecipe && specificRecipe.recipe.numberOfPortions,
  );

  let recipeActiveCookingTime = undefined;
  if (specificRecipe && specificRecipe.recipe.activeCookingTime) {
    let split = specificRecipe.recipe.activeCookingTime.split(':').map((x) => parseInt(x));
    if (split.length === 2) {
      let [hours, minutes] = split;
      if (hours > 0 || minutes > 0) recipeActiveCookingTime = { hours, minutes };
    }
  }

  let recipeTotalCookingTime = undefined;
  if (specificRecipe && specificRecipe.recipe.totalCookingTime) {
    let split = specificRecipe.recipe.totalCookingTime.split(':').map((x) => parseInt(x));
    if (split.length === 2) {
      let [hours, minutes] = split;
      if (hours > 0 || minutes > 0) recipeTotalCookingTime = { hours, minutes };
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerWidth <= 450) {
        let image = document.getElementById('image');
        if (image) {
          image.style.transform = `scale(${1.1 - window.pageYOffset / 4000})`;
          image.style.opacity = 1 - +window.pageYOffset / 700 + '';
        }
      }
    });
  }, []);

  const addToFavourites = () => {
    dispatch(addToFavouritesAction(id)).then(() => dispatch(fetchSpecificRecipeAction(id)));
  };
  const removeFromFavourites = () => {
    dispatch(removeFromFavouritesAction(id)).then(() => dispatch(fetchSpecificRecipeAction(id)));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSpecificRecipeAction(id));
    // eslint-disable-next-line
  }, []);

  return !specificRecipe || specificRecipe.recipe.id !== id ? (
    <></>
  ) : (
    <>
      <BackButton onClick={() => navigate(-1)} />
      {/* <MoonIcon
        wakeLockEnabled={wakeLockEnabled}
        onClick={() => {
          if (!wakeLockEnabled) {
            noSleep.enable();
            setWakeLockEnabled(true);
          } else {
            noSleep.disable();
            setWakeLockEnabled(false);
          }
        }}
      />
      <SleepModeText wakeLockEnabled={wakeLockEnabled}>Wygaszanie ekranu zablokowane</SleepModeText> */}
      <div style={{ display: 'none' }}>
        <RecipeToPrint
          amountVariant={specificRecipe.recipe.amountVariant}
          printWithImage={printWithImage}
          recipeActiveCookingTime={recipeActiveCookingTime}
          recipeTotalCookingTime={recipeTotalCookingTime}
          specificRecipe={specificRecipe}
          nutrientsPortions={nutrientsPortions}
          ingredientsPortions={ingredientsPortions}
          ref={componentRef}
        />
      </div>
      <StyledWrapper>
        <ImageIconsWrapper id="imageSection">
          <Image id="image" src={specificRecipe.recipe.imageUrl} />
          <RecipeIcons
            setPrintWithImage={setPrintWithImage}
            handlePrint={handlePrint}
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            user={user}
            specificRecipe={specificRecipe}
          />
        </ImageIconsWrapper>
        <HeaderAndNutrientsSection id="headerAndNutrientsSection">
          <section>
            <StyledRecipeName>{specificRecipe.recipe.name}</StyledRecipeName>
            <StyledSubtitle>{specificRecipe.recipe.description}</StyledSubtitle>
            <InfoWrapper className="info">
              {recipeActiveCookingTime && (
                <StyledInfo>
                  <Timer size={18} />
                  Aktywny czas pracy :
                  <div>
                    {recipeActiveCookingTime.hours}
                    {recipeActiveCookingTime.minutes > 0 && '.' + recipeActiveCookingTime.minutes}h
                  </div>
                </StyledInfo>
              )}
              {recipeTotalCookingTime && (
                <StyledInfo>
                  <Timer size={18} />
                  Całkowity czas przygotowania:
                  <div>
                    {recipeTotalCookingTime.hours}
                    {recipeTotalCookingTime.minutes > 0 && '.' + recipeTotalCookingTime.minutes}h
                  </div>
                </StyledInfo>
              )}
              <StyledInfo>
                <Person size={20} />
                {specificRecipe.recipe.amountVariant === 'Portions'
                  ? 'Kcal w 1 porcji :'
                  : 'Kcal w 1 sztuce :'}
                <div>
                  {specificRecipe &&
                    specificRecipe.recipe.recipeIngredients
                      .map(
                        (i) =>
                          i.ingredient &&
                          (i.ingredient.calories * i.amount) /
                            specificRecipe.recipe.numberOfPortions,
                      )
                      .reduce((x, y) => x + y, 0)
                      .toFixed(0)}
                </div>
              </StyledInfo>
            </InfoWrapper>
          </section>
          <section>
            <SectionHeader>
              <SectionName className="sectionName">Składniki odżywcze</SectionName>
              <PortionsCounter
                variant={specificRecipe.recipe.amountVariant}
                portions={nutrientsPortions}
                setPortions={setNutrientsPortions}
                numberOfPortions={specificRecipe.recipe.numberOfPortions}
                min={1}
                max={50}
              />
            </SectionHeader>
            <StyledMacronutrientsContainer id="nutrientsContainer">
              <MacronutrientInfoWrapper
                unit=" kcal"
                value={(
                  (specificRecipe.macronutrients.calories /
                    specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(0)}
                title="Kalorie"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.fats / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Tłuszcze"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.proteins /
                    specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Białko"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.carbs / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Węglowodany"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.sugars / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Cukry"
              />
              <MacronutrientInfoWrapper
                unit=" g"
                value={(
                  (specificRecipe.macronutrients.salt / specificRecipe.recipe.numberOfPortions) *
                  nutrientsPortions
                ).toFixed(1)}
                title="Sól"
              />
            </StyledMacronutrientsContainer>
          </section>
        </HeaderAndNutrientsSection>
        <IngredientsSection id="ingredientsSection">
          <SectionHeader>
            <SectionName className="sectionName">Składniki</SectionName>
            <PortionsCounter
              variant={specificRecipe.recipe.amountVariant}
              portions={ingredientsPortions}
              setPortions={setIngredientsPortions}
              numberOfPortions={specificRecipe.recipe.numberOfPortions}
              min={1}
              max={50}
            />
          </SectionHeader>
          <Table borderless>
            <tbody>
              {ingredientsPortions &&
                specificRecipe &&
                specificRecipe.recipe.recipeIngredients &&
                specificRecipe.recipe.recipeIngredients.map(
                  ({ ingredient, amount, unitOfMeasure, annotation }) => (
                    <StyledTr key={ingredient.name}>
                      <td>
                        {ingredient.name}
                        {annotation && <span className="annotation"> ({annotation})</span>}
                      </td>
                      <td>
                        {(
                          (amount / specificRecipe.recipe.numberOfPortions) *
                          ingredientsPortions
                        ).toFixed(0)}
                      </td>
                      <td>
                        {unitOfMeasure && unitOfMeasure === 'Milliliter' && 'ml'}
                        {unitOfMeasure && unitOfMeasure === 'Gram' && 'gr'}
                      </td>
                    </StyledTr>
                  ),
                )}
            </tbody>
          </Table>
        </IngredientsSection>
        <PreparationSection id="preparationSection">
          <SectionName className="sectionName">Przygotowanie</SectionName>
          <StyledPreparation>
            {specificRecipe &&
              specificRecipe.recipe.preparation &&
              specificRecipe.recipe.preparation
                .split('*')
                .filter((pi) => pi.trim() !== '')
                .map((pi, index) => <PreparationItem key={index}>{pi}</PreparationItem>)}
          </StyledPreparation>
        </PreparationSection>
      </StyledWrapper>
    </>
  );
};

RecipeDetails.propTypes = {
};

export default RecipeDetails;
