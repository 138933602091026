import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

const StyledWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  text-align: left;
  position: absolute;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  display: block;
  color: ${({ theme }) => theme.red};
`;

const FieldErrorMessage = ({ name, style }) => (
  <StyledWrapper style={style}>
    <StyledErrorMessage name={name} component="div" className="invalid-feedback" />
  </StyledWrapper>
);

FieldErrorMessage.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};

export default FieldErrorMessage;
