import React from 'react';
import styled from 'styled-components';

const StyledMacronutrient = styled.div`
  width: 250px;
  margin: 13px auto 13px 0;
  &:nth-child(even) {
    margin: 13px 0 13px auto;
  }
  &:nth-last-child(1),
  &:nth-last-child(2) {
    margin-bottom: 0;
  }
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.primary};

  @media (max-width: 590px) {
    width: 220px;
    margin: 10px auto !important;
  }
  @media (max-width: 530px) {
    flex-direction: column;
    width: 90%;
    margin: 5px;
    border-radius: 10px;
  }
`;

const StyledValueContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  width: 80px;
  height: 45px;
  margin: 5px;
  text-align: center;
  @media (max-width: 530px) {
    width: 100%;
    margin: 0;
    padding: 0px;
    border-radius: 0;
  }
  > p {
    position: relative;
    font-weight: ${({ theme }) => theme.bold};
    font-size: ${({ theme }) => theme.fontSize.xs};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > span {
      font-size: ${({ theme }) => theme.fontSize.xxs};
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

const StyledMacronutrientTitle = styled.h3`
  margin: 0 0 0 10px;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.bold};
  @media (max-width: 530px) {
    margin: 7px 0;
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`;

const MacronutrientInfoWrapper = ({ unit, value, title }) => {
  return (
    <StyledMacronutrient>
      <StyledValueContainer>
        <p>
          {value}
          <span>{unit}</span>
        </p>
      </StyledValueContainer>
      <StyledMacronutrientTitle>{title}</StyledMacronutrientTitle>
    </StyledMacronutrient>
  );
};

export default MacronutrientInfoWrapper;
