import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Options } from '@styled-icons/ionicons-outline/Options';

const StyledButton = styled(Options)`
  transition: 0.5s;
  margin-left: 6px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  ${(props) =>
    props.currentFilterStatus === undefined &&
    css`
      color: ${({ theme }) => theme.grey};
    `}
  ${(props) =>
    props.currentFilterStatus === 'Requested' &&
    css`
      color: ${({ theme }) => theme.orange};
    `}
  ${(props) =>
    props.currentFilterStatus === 'Rejected' &&
    css`
      color: ${({ theme }) => theme.red};
    `}
  ${(props) =>
    props.currentFilterStatus === 'Accepted' &&
    css`
      color: ${({ theme }) => theme.green};
    `}
`;

const StyledFiltersList = styled.div`
  display: none;
  position: absolute;
  top: 65px;
  right: 55px;
  z-index: 15;
  background-color: ${({ theme }) => theme.white};
  margin-bottom: 5px;
  padding: 20px 10px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  color: ${({ theme }) => theme.black};
  > h5 {
    font-size: ${({ theme }) => theme.fontSize.xs};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.bold};
    @media (max-width: 450px) {
      font-size: ${({ theme }) => theme.fontSize.xxs};
    }
  }
  @media (max-width: 1000px) {
    top: 40px;
    right: 30px;
  }

  ${(props) =>
    props.$activeFilterModal &&
    css`
      display: flex;
      flex-flow: column nowrap;
    `}
`;

const StyledOption = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${(props) => props.color};
  padding: 3px 0;
  margin: 0 10px;
  :hover {
    font-weight: ${({ theme }) => theme.bold};
    cursor: pointer;
  }
  ${(props) =>
    props.$activeStatus ?
    css`
      font-weight: ${({ theme }) => theme.bold};
      text-decoration: underline;
    ` : undefined};
`;

const FilterModal = ({ currentFilterStatus, changeStatus }) => {
  const [activeFilterModal, setActiveFilterModal] = useState(false);
  const activeFilterModalRef = useRef(activeFilterModal);

  useEffect(() => {
    window.addEventListener('mouseup', (event) => {
      if (!activeFilterModalRef.current) return;
      var filterModal = document.getElementById('filterModal');
      if (event.target !== filterModal) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === filterModal) return;
          else parent = parent.parentNode;
        }
        setActiveFilterModal(false);
      }
    });
    return () => {
      activeFilterModalRef.current = false;
    };
  }, []);

  useEffect(() => {
    activeFilterModalRef.current = activeFilterModal;
  }, [activeFilterModal]);

  return (
    <div id="filterModal">
      <StyledButton
        currentFilterStatus={currentFilterStatus}
        onClick={() => setActiveFilterModal((x) => !x)}
      />
      <StyledFiltersList $activeFilterModal={activeFilterModal}>
        <h5>Filtruj :</h5>
        <StyledOption
          color="rgb(153, 153, 153)"
          $activeStatus={currentFilterStatus === undefined}
          onClick={() => {
            setActiveFilterModal(false);
            changeStatus(undefined);
          }}
        >
          Wszystkie
        </StyledOption>
        <StyledOption
          color="rgb(255, 132, 0)"
          $activeStatus={currentFilterStatus === 'Requested'}
          onClick={() => {
            setActiveFilterModal(false);
            changeStatus('Requested');
          }}
        >
          Oczekujące
        </StyledOption>
        <StyledOption
          color="rgb(62, 158, 30)"
          $activeStatus={currentFilterStatus === 'Accepted'}
          onClick={() => {
            setActiveFilterModal(false);
            changeStatus('Accepted');
          }}
        >
          Zaakceptowane
        </StyledOption>
        <StyledOption
          color="rgb(184, 29, 29)"
          $activeStatus={currentFilterStatus === 'Rejected'}
          onClick={() => {
            setActiveFilterModal(false);
            changeStatus('Rejected');
          }}
        >
          Odrzucone
        </StyledOption>
      </StyledFiltersList>
    </div>
  );
};

FilterModal.propTypes = {
  style: PropTypes.array,
  secondary: PropTypes.bool,
  currentFilterStatus: PropTypes.string,
  changeStatus: PropTypes.func,
};
export default FilterModal;
