import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login as loginAction, clearErrors as clearLoginErrorsAction } from '../actions';
import { routes } from '../routes';
import AuthTemplate from '../templates/AuthTemplate';
import FormInput from '../components/atoms/FormInput';
import FormErrorMessage from '../components/atoms/FormErrorMessage';

const StyledFormInput = styled(FormInput)`
  margin: 40px auto;
  width: 80;
`;

const LoginPage = ({ location }) => {
  const [loadingActive, setLoadingActive] = useState(false);

  const { user, loginErrors } = useSelector(state => ({
    user: state.user,
    loginErrors: state.loginErrors,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLoginErrorsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginErrors) setLoadingActive(false);
  }, [loginErrors]);

  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email jest wymagany'),
        password: Yup.string().required('Hasło jest wymagane'),
      })}
      onSubmit={({ email, password }) => {
        setLoadingActive(true);
        dispatch(
          loginAction(
            email,
            password
          ),
        ).then(() => {
          navigate(location?.state?.from ?? '/home');
        });
      }}
    >
      {({ handleChange, handleBlur, errors, touched, values }) => {
        if (user) {
          return <Navigate to={routes.home} />;
        }
        return (
          <AuthTemplate
            header="Logowanie"
            buttonText="Zaloguj się"
            linkUrl={routes.register}
            loadingActive={loadingActive}
            linkText="Nowy w Szamie? Stwórz nowe konto!"
          >
            <StyledFormInput
              labelName="Email"
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.email}
              touched={touched.email}
              value={values.email}
            />
            <StyledFormInput
              labelName="Hasło"
              name="password"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.password}
              touched={touched.password}
              value={values.password}
            />
            {loginErrors &&
              Object.keys(loginErrors).map((keyName, i) => (
                <FormErrorMessage key={i}>{loginErrors[keyName]}</FormErrorMessage>
              ))}
          </AuthTemplate>
        );
      }}
    </Formik>
  );
};

LoginPage.propTypes = {
  location: PropTypes.object,
};

export default LoginPage;
