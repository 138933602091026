import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from '../components/organisms/Sidebar';
import Footer from '../components/molecules/Footer';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 65px);
  margin-left: 65px;
  height: 100%;
  @media (max-width: 450px) {
    width: 100%;
    margin-left: 0;
  }
`;

const Content = styled.div`
  margin-bottom: 20px;
  cursor: default;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  position: relative;
  flex: 1 0 auto;
  @media (max-width: 450px) {
    padding-bottom: 130px;
  }
`;

const NotificationPopup = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 25;
  transform: translate(-50%, -150%);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 350px;
  padding: 10px 20px;
  text-align: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.lightSecondary};
  > p {
    padding: 0;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const UserPageTemplate = ({ children }) => {
  const showLogoutNotification = useSelector((state) => state.showLogoutNotification);
  useEffect(() => {
    const logoutPopup = document.getElementById('logoutPopup');
    if (showLogoutNotification === true) logoutPopup.style.transform = 'translate(-50%, 0)';
    else logoutPopup.style.transform = 'translate(-50%, -150%)';
  }, [showLogoutNotification]);
  return (
    <>
      <Sidebar />
      <StyledContainer>
        <Content>
          {children}
          <NotificationPopup id="logoutPopup">
            <p>Zostałeś wylogowany.</p>
          </NotificationPopup>
        </Content>
        <Footer />
      </StyledContainer>
    </>
  );
};

UserPageTemplate.propTypes = {
  children: PropTypes.any,
};
export default UserPageTemplate;
