import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  disableSleepMode as disableSleepModeAction,
  enableSleepMode as enableSleepModeAction,
  fetchVerificationRequestsCount as fetchVerificationRequestsCountAction,
} from '../../actions';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from '../../routes';
import { JournalBookmark } from '@styled-icons/bootstrap/JournalBookmark';
import { Home } from '@styled-icons/ionicons-outline/Home';
import { Shield } from '@styled-icons/feather/Shield';
import { ShoppingBag } from '@styled-icons/feather/ShoppingBag';
import BlackCarrot from '!file-loader!../../assets/blackCarrot.svg';
import DarkBlueBowl from '!file-loader!../../assets/darkBlueBowl.svg';
import BlackBowl from '!file-loader!../../assets/blackBowl.svg';
import DarkBlueCarrot from '!file-loader!../../assets/darkBlueCarrot.svg';
import Cook from '!file-loader!../../assets/cook2.svg';
import moon from '!file-loader!../../assets/moon.svg';
import moonOff from '!file-loader!../../assets/moonOff.svg';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';

const Blur = styled.div`
  position: fixed;
  display: block;
  transform: ${(props) => (props.$activeMenu ? 'translateX(0)' : 'translateX(-100%)')};
  opacity: ${(props) => (props.$activeMenu ? '1' : '0')};
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 9;
  @media (max-width: 450px) {
    display: none;
  }
`;

const StyledMenu = styled.div`
  position: fixed;
  width: 65px;
  height: 100%;
  top:0;
  left:0;
  transition: 0.3s ease-in-out;
  z-index: 10;
  background-color: ${({ theme }) => theme.primary};
  > ul {
    padding: 0;
  }
  ${(props) =>
    props.$activeMenu &&
    css`
      width: 250px;
      a:before {
        width: 230px !important;
      }
      .itemName{
        opacity:1;
      }
      .tooltip{
        opacity:0!important;
      }
      #arrow{
        transform:rotate(180deg);
      }
      @media (max-width: 450px) {
        width:100%;
        transform: translateX(0%);
        width:100%;
        transition: 0s ease-in-out;
        height:65px;
        bottom: 0;
        align-items:center;
        top:unset;
        border-top: 2px solid ${({ theme }) => theme.white};
        display:flex;
        flex-flow: row nowrap;
        overflow: visible;
        .item:nth-of-type(1){
          &.active ~ #indicator{
            left: 0;
          }
        }
        .item:nth-of-type(2){
          &.active ~ #indicator{
            left: 16.6%;
          }
        }
        .item:nth-of-type(3){
          &.active ~ #indicator{
            left: 33.2%;
          }
        }
        .item:nth-of-type(4){
          &.active ~ #indicator{
            left: 49.8%;
          }
        }
        .item:nth-of-type(5){
          &.active ~ #indicator{
            left: 66.4%;
          }
        }
        .item:nth-of-type(6){
          &.active ~ #indicator{
            left: 83%;
          }
        }
        :before{
          display:none;
        }
      }
        > ul {
          padding: 0;
        }
      }
    `}
  }
`;

const ItemWrapper = styled(NavLink)`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 20px 0px;
  height: 50px;

  &:nth-child(2) {
    @media (min-width: 451px) {
      margin-top: 70px;
    }
  }

  &:last-child {
    margin: 0 0 5px 0;
  }

  text-decoration: none !important;
  &:hover {
    > .tooltip {
      transform: translate(0px, -50%);
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  &:hover,
  &.active {
    &:before {
      transition: 0.3s ease-in-out;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 10px;
      width: 45px;
      height: 50px;
      border-radius: 10px;
      background-color: rgba(150, 150, 150, 0.1);
    }
    > .itemName,
    svg {
      color: ${({ theme }) => theme.darkSecondary};
    }
    #carrot {
      background-image: url(${DarkBlueCarrot});
    }
    #bowl {
      background-image: url(${DarkBlueBowl});
    }
    &#user {
      :before {
        border-radius: 0 !important;
        width: 100% !important;
        margin-left: 0;
        height: 75px;
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    min-width: 16.6%;
    justify-content: center;
    margin: 0;
    text-align: center;
    :first-child {
      margin-top: 0;
    }
    &:hover,
    &.active {
      :before {
        display: none;
      }
    }
    &.active .icon {
      position: relative;
      transform: translateY(-18px);
      z-index: 1;
      > svg {
        color: ${({ theme }) => theme.darkSecondary};
      }
    }
  }
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  top: -40%;
  width: 16.6%;
  justify-content: center;
  transition: 0.3s;
  left: -20%;
`;

const StyledIndicator = styled.div`
  width: 55px;
  height: 55px;
  position: relative;
  margin: auto;
  background-color: ${({ theme }) => theme.lightSecondary};
  backdrop-filter: blur(5px);
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.white};
  transition: 0.5s;
`;

const IconWrapper = styled.div`
  width: 65px;
  min-width: 65px;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  > svg {
    width: 25px;
    height: 25px;
    color: ${({ theme }) => theme.black};
    @media (max-width: 450px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledCarrotIcon = styled.div`
  width: 25px;
  height: 25px;
  background-image: url(${BlackCarrot});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledBowlIcon = styled.div`
  width: 25px;
  height: 25px;
  background-image: url(${BlackBowl});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledCookIcon = styled.div`
  width: 35px;
  height: 35px;
  margin-bottom: 3px;
  background-image: url(${Cook});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
`;

const MenuItemName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.black};
  font-weight: ${({ theme }) => theme.bold};
  margin: 0;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    margin-top: 5px;
    color: ${({ theme }) => theme.grey};
  }
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
`;

const Tooltip = styled.span`
  position: absolute;
  left: 70px;
  top: 25px;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  width: 122px;
  backdrop-filter: contrast(0.5);
  text-align: center;
  transition: 0.2s;
  opacity: 0;
  transform: translate(-10px, -50%);
  pointer-events: none;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.white};
  font-weight: ${({ theme }) => theme.bold};
  @media (max-width: 450px) {
    display: none;
  }
`;

const BottomContainer = styled.section`
  @media (max-width: 450px) {
    position: static;
    margin: auto;
    width: 16.6%;
    #user {
      height: 20px;
    }
    #moon {
      display: none;
    }
    :before {
      display: none;
    }
  }
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.lightSecondary};
  }

  ${(props) =>
    props.$activeMenu &&
    css`
      :before {
        width: 230px;
      }
    `}

  #user {
    > .itemName {
      max-width: 155px;
      overflow: hidden;
      font-size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.black}!important;
      > span {
        display: block;
        font-size: ${({ theme }) => theme.fontSize.xs};
        color: ${({ theme }) => theme.grey};
      }
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

const WakeLockWrapper = styled.section`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 220px;
  padding-bottom: 10px;
  &#moon {
    margin: 0 0 15px 0;
  }
  &:hover,
  &.active {
    > span {
      color: ${({ theme }) => theme.secondary};
    }
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const MoonIcon = styled.div`
  background-image: url(${moon});
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  ${(props) =>
    props.$sleepMode &&
    css`
      background-image: url(${moonOff});
    `}
`;

const SleepModeText = styled.div`
  cursor: default;
  z-index: 10;
  position: fixed;
  left: 65px;
  bottom: 75px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 0 5px 5px 0;
  font-weight: ${({ theme }) => theme.bold};
  transform: translateX(-150%);
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-size: ${({ theme }) => theme.fontSize.xxs};
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  width: fit-content;
  color: ${({ theme }) => theme.grey};
  ${(props) =>
    props.$sleepMode &&
    css`
      transform: translateX(0%);
      @media (max-width: 450px) {
        // left: 50%;
        // transform: translateX(-50%);
        // bottom: 90px;
        // border-radius: 5px;
        display: none;
      }
    `};
`;

const ToggleContainer = styled.div`
  display: flex;
  position: absolute;
  right: -5px;
  flex-flow: row nowrap;
  align-items: center;
  backdrop-filter: contrast(0.6);
  border-radius: 7px;
  cursor: pointer;
  margin-top: 20px;
  transition: 0.2s;
  :hover {
    backdrop-filter: contrast(0.5);
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const StyledToggle = styled(ArrowRightShort)`
  width: 25px;
  height: 25px;
  margin: 5px;
  color: ${({ theme }) => theme.primary};
  transition: 0.3s;
`;

const RequestRecipesCount = styled.div`
    position: absolute;
    bottom: 0;
    left: 10px;
    background - color: ${({ theme }) => theme.orange};
    border: 1px solid ${({ theme }) => theme.white};
    border - radius: 100 %;
    width: 20px;
    height: 20px;
  > p {
      font - size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.white};
      text - shadow: 1px 1px ${({ theme }) => theme.grey};
      position: absolute;
      top: 50 %;
      left: 50 %;
      transform: translate(-50 %, -50 %);
    }
    `;

const Sidebar = () => {
  const dispatch = useDispatch();
  const sleepMode = useSelector((state) => state.sleepMode);
  const { user, requestsCount } = useSelector((state) => ({
    user: state.user,
    requestsCount: state.requestsCount,
  }));
  const [activeMenu, setActiveMenu] = useState(false);
  const activeMenuRef = useRef(activeMenu);
  useEffect(() => {
    if (window.innerWidth <= 450) setActiveMenu(true);
    if (user && user.roles.split(',').includes('ADMIN'))
      dispatch(fetchVerificationRequestsCountAction('Requested'));
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 450) setActiveMenu(true);
      else setActiveMenu(false);
    });
    window.addEventListener('mouseup', (event) => {
      if (window.innerWidth >= 450) {
        if (!activeMenuRef.current) return;
        var menu = document.getElementById('menu');
        if (event.target !== menu) {
          let parent = event.target.parentNode;
          while (parent !== null) {
            if (parent === menu) return;
            else parent = parent.parentNode;
          }
          setActiveMenu(false);
        }
      }
    });
    return () => {
      activeMenuRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    activeMenuRef.current = activeMenu;
  }, [activeMenu]);

  const setActiveMenuFalse = () => {
    if (window.innerWidth >= 450) setActiveMenu(false);
  };

  return (
    <>
      <Blur $activeMenu={activeMenu}/>
      <SleepModeText $sleepMode={sleepMode}>Wygaszanie ekranu zablokowane</SleepModeText>
      <StyledMenu id="menu" $activeMenu={activeMenu}>
        <ToggleContainer onClick={() => setActiveMenu((x) => !x)}>
          <StyledToggle id="arrow" />
        </ToggleContainer>
        <ItemWrapper className="item" to={routes.home} onClick={() => setActiveMenuFalse()}>
          <IconWrapper className="icon">
            <Home />
          </IconWrapper>
          <MenuItemName className="itemName">Home</MenuItemName>
          <Tooltip className="tooltip">Home</Tooltip>
        </ItemWrapper>
        <ItemWrapper className="item" to={routes.recipes} onClick={() => setActiveMenuFalse()}>
          <IconWrapper className="icon">
            <JournalBookmark />
          </IconWrapper>
          <MenuItemName className="itemName">Przepisy</MenuItemName>
          <Tooltip className="tooltip">Moje przepisy</Tooltip>
        </ItemWrapper>
        <ItemWrapper className="item" to={routes.ingredients} onClick={() => setActiveMenuFalse()}>
          <IconWrapper className="icon">
            <StyledBowlIcon id="bowl" />
          </IconWrapper>
          <MenuItemName className="itemName">Składniki</MenuItemName>
          <Tooltip className="tooltip">Składniki</Tooltip>
        </ItemWrapper>
        <ItemWrapper className="item" to={routes.diet} onClick={() => setActiveMenuFalse()}>
          <IconWrapper className="icon">
            <StyledCarrotIcon id="carrot" />
          </IconWrapper>
          <MenuItemName className="itemName">Dieta</MenuItemName>
          <Tooltip className="tooltip">Dieta</Tooltip>
        </ItemWrapper>
        <ItemWrapper className="item" to={routes.shoppingList} onClick={() => setActiveMenuFalse()}>
          <IconWrapper className="icon">
            <ShoppingBag />
          </IconWrapper>
          <MenuItemName className="itemName">Zakupy</MenuItemName>
          <Tooltip className="tooltip">Zakupy</Tooltip>
        </ItemWrapper>
        {user && user.roles.split(',').includes('ADMIN') && (
          <ItemWrapper className="item" to={routes.adminPage} onClick={() => setActiveMenuFalse()}>
            <IconWrapper className="icon">
              <Shield />
              {requestsCount > 0 && (
                <RequestRecipesCount>
                  <p>{requestsCount}</p>
                </RequestRecipesCount>
              )}
            </IconWrapper>
            <MenuItemName className="itemName">Admin</MenuItemName>
            <Tooltip className="tooltip">Admin</Tooltip>
          </ItemWrapper>
        )}
        {window.innerWidth >= 450 ? (
          <BottomContainer $activeMenu={activeMenu}>
            <WakeLockWrapper
              id="moon"
              onClick={() => {
                setActiveMenu(false);
                if (!sleepMode) dispatch(enableSleepModeAction());
                else dispatch(disableSleepModeAction());
              }}
            >
              <IconWrapper>
                <MoonIcon $sleepMode={sleepMode}/>
              </IconWrapper>
              <MenuItemName className="itemName">Nie wygaszaj ekranu</MenuItemName>
            </WakeLockWrapper>
            <ItemWrapper
              className="item"
              id="user"
              to={routes.account}
              onClick={() => setActiveMenuFalse()}
            >
              <IconWrapper>
                <StyledCookIcon />
              </IconWrapper>
              {user ? (
                <>
                  <MenuItemName className="itemName">
                    {user.username}
                    <span>{user.email}</span>
                  </MenuItemName>
                  <Tooltip className="tooltip">{user.username}</Tooltip>
                </>
              ) : (
                <>
                  <MenuItemName className="itemName">Witaj, szefie!</MenuItemName>
                  <Tooltip className="tooltip">Zaloguj</Tooltip>
                </>
              )}
            </ItemWrapper>
          </BottomContainer>
        ) : (
          <ItemWrapper
            className="item"
            id="user"
            to={user ? routes.account : routes.login}
            onClick={() => setActiveMenuFalse()}
          >
            <IconWrapper className="icon">
              <StyledCookIcon />
            </IconWrapper>
            {user ? (
              <MenuItemName className="itemName">Konto</MenuItemName>
            ) : (
              <MenuItemName className="itemName">Zaloguj</MenuItemName>
            )}
          </ItemWrapper>
        )}
        <IndicatorWrapper id="indicator">
          <StyledIndicator />
        </IndicatorWrapper>
      </StyledMenu>
    </>
  );
};

export default Sidebar;
