import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const moveLoadingButton = keyframes`
0%{
  width:0px;
  opacity: 0;
}
50%{
  opacity: 0.1;
}
100%{
  width:100%;
  opacity: 0;
}
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  cursor: default;
  backdrop-filter: blur(2px);
  text-align: center;
  display: ${props => (props.$activeModal ? 'block' : 'none')};
  ${props =>
    (props.$errors !== 0 || props.$formErrors !== null) &&
    css`
      display: none;
    `};
`;

const StyledBox = styled.div`
  width: 700px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 32px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(100, 100, 100, 0.5);
  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 450px) {
    margin: 0;
    width: 95%;
  }
`;

const StyledTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.m};
  margin: 50px 10px;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const ButtonsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

const ChoiceButton = styled.div`
  width: 50%;
  height: 75px;
  color: ${({ theme }) => theme.grey};
  background-color: ${({ theme }) => theme.primary};
  font-size: ${({ theme }) => theme.fontSize.s};
  cursor: pointer;
  transition: 0.3s;
  &:nth-child(2) {
    ${props =>
      props.$loadingActive ?
      css`
        &:before {
          content: '';
          transform: rotate(180deg);
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: ${({ theme }) => theme.white};
          animation: ${moveLoadingButton} 1.5s infinite;
        }
      ` : undefined}
  }
  > p {
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: ${({ theme }) => theme.lightGrey};
    > p {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const ConfirmModal = ({
  errors,
  formErrors,
  text,
  onModalSubmit,
  activeModal,
  closeModal,
  submitButtonText,
}) => {
  const [loadingActive, setLoadingActive] = useState(false);

  const activeModalRef = useRef(activeModal);
  useEffect(() => {
    window.addEventListener('mouseup', event => {
      if (!activeModalRef.current) return;
      var modal = document.getElementById('modal');
      if (event.target !== modal) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === modal) return;
          else parent = parent.parentNode;
        }
        closeModal();
      }
    });
    return () => {
      activeModalRef.current = false;
    };
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    activeModalRef.current = activeModal;
  }, [activeModal]);

  useEffect(() => {
    if ((errors && Object.keys(errors).length > 0) || (formErrors && formErrors.length !== null)) {
      setLoadingActive(false);
    }
  }, [errors, formErrors]);

  return (
    <StyledWrapper
      $errors={errors ? Object.keys(errors).length : 0}
      $formErrors={formErrors ? formErrors.length : null}
      $activeModal={activeModal}
    >
      <StyledBox id="modal">
        <StyledTitle>{text}</StyledTitle>
        <ButtonsWrapper>
          <ChoiceButton $loadingActive={loadingActive} onClick={closeModal}>
            <p>Cofnij</p>
          </ChoiceButton>
          <ChoiceButton
            $loadingActive={loadingActive}
            onClick={() => {
              setLoadingActive(true);
              onModalSubmit();
            }}
          >
            <p>{submitButtonText}</p>
          </ChoiceButton>
        </ButtonsWrapper>
      </StyledBox>
    </StyledWrapper>
  );
};

ConfirmModal.propTypes = {
  formErrors: PropTypes.array,
  errors: PropTypes.object,
  text: PropTypes.string,
  onModalSubmit: PropTypes.func.isRequired,
  activeModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
};

ConfirmModal.defaultProps = { text: 'Jeśteś powien?', submitButtonText: 'Wyślij' };

export default ConfirmModal;
