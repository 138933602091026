import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 5px;
  padding: 40px;
  min-width: 500px;
  background-color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.primaryFont};
  box-shadow: 0 5px 15px rgba(100, 100, 100, 0.5);
  > span {
    font-size: ${({ theme }) => theme.fontSize.l};
    color: ${({ theme }) => theme.grey};
  }
  @media (max-width: 600px) {
    width: 90%;
    min-width: unset;
    font-size: ${({ theme }) => theme.fontSize.s};
    > span {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.black};
  margin: 20px 0;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding: 0 5px;
  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

const ShortMessageTemplate = ({
  firstLineText,
  secondLineText,
  cooldown,
  buttonLink,
  buttonText,
  link,
}) => {
  useEffect(() => {
    cooldown !== undefined &&
      setTimeout(function () {
        window.location.href = link;
      }, cooldown);
  });

  const navigate = useNavigate();

  return (
    <StyledWrapper>
      {firstLineText}
      <span>{secondLineText}</span>
      {buttonLink && <StyledLink onClick={() => navigate(buttonLink)}>{buttonText}</StyledLink>}
    </StyledWrapper>
  );
};

ShortMessageTemplate.propTypes = {
  firstLineText: PropTypes.string,
  secondLineText: PropTypes.string,
  cooldown: PropTypes.number,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
};

export default ShortMessageTemplate;
