import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FileEarmarkImage } from '@styled-icons/bootstrap/FileEarmarkImage';
import FieldErrorMessage from './FieldErrorMessage';

const StyledInputWrapper = styled.div`
  width: 80%;
  height: 250px;
  border: 2px dashed ${({ theme }) => theme.primary};
  padding: 10px;
  position: relative;
  margin: 0 auto 30px auto;
  :hover {
    svg {
      color: ${({ theme }) => theme.lightGrey};
      transform: scale(1.1);
    }
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 !important;
`;

const StyledLabel = styled.label`
  width: 80%;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme }) => theme.fontSize.m};
  margin: 0;
  padding: 0 10px;
  color: ${({ theme }) => theme.black};
  pointer-events: none;
  > svg {
    transition: 0.2s;
    margin: auto;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    color: ${({ theme }) => theme.grey};
  }
  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    > svg {
      width: 45px;
      height: 45px;
    }
  }
`;

const StyledImage = styled.img`
  max-height: 100px;
  width: auto;
  margin: 10px auto;
`;

const FormFile = ({ name, onChange, onBlur, errors, touched, value }) => (
  <StyledInputWrapper value={value}>
    <StyledLabel>
      {value ? <StyledImage alt="uploadedImage" src={value} /> : <FileEarmarkImage />}
      <strong>Upuść obrazek tutaj</strong>lub kliknij aby wybrać plik
    </StyledLabel>
    <StyledInput
      name={name}
      type="file"
      title=""
      onChange={onChange}
      onBlur={onBlur}
      className={`form-control${errors && touched ? ' is-invalid' : ''}`}
    />
    <FieldErrorMessage style={{ marginTop: '20px' }} name={name} />
  </StyledInputWrapper>
);

FormFile.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  touched: PropTypes.bool,
  value: PropTypes.string,
};

export default FormFile;
