import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*, *::before, *::after{
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html{
    height: 100%;
    margin: 0;
    font-size: 62,5%;
    position:relative;
    scroll-behavior: smooth;
}

body{
    height: 100%;
    width:100%;
    background-size:cover;
    background-repeat: repeat-y;
    margin: 0;
    background-color:#fff;
    font-size: 1.6rem;
    font-family: 'Bellota Text', cursive;
    /* font-family: 'Poppins', sans-serif; */
}`;

export default GlobalStyle;
