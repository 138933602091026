import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Add } from '@styled-icons/material/Add';
import PropTypes from 'prop-types';

const moveButton = keyframes`
from{
  transform: rotate(0);
}
to{
  transform: rotate(180deg);
}
`;

const StyledContainer = styled.div`
  cursor: pointer;
  border-radius: 5px;
  margin: 10px 0 10px auto;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.bold};
  > svg {
    margin: 2px 0 0 5px;
    width: 15px;
    height: 15px;
  }
  :hover {
    > svg {
      animation: ${moveButton} 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    }
  }
  @media (max-width: 750px) {
    width: 40px;
    height: 40px;
    padding: 0;
    align-items: center;
    background-color: ${({ theme }) => theme.primary};
    justify-content: center;
    border-radius: 10px;
    svg {
      margin: 0;
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme.grey};
    }
  }
`;

const NewItemButton = ({ id, onClick, content, style }) => {
  const [buttonTitle, setButtonTitle] = useState(content);
  const changeButtonTitle = () => {
    if (window.innerWidth > 750) setButtonTitle(content);
    else setButtonTitle(undefined);
  };

  useEffect(() => {
    changeButtonTitle();
    window.addEventListener('resize', function () {
      changeButtonTitle();
    });
    return () => {
      window.removeEventListener('resize', function () {
        changeButtonTitle();
      });
    };
    //eslint-disable-next-line
  }, []);

  return (
    <StyledContainer id={id} style={style} onClick={onClick}>
      {buttonTitle}
      <Add />
    </StyledContainer>
  );
};

NewItemButton.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.any,
  id: PropTypes.string,
  style: PropTypes.object,
};

export default NewItemButton;
