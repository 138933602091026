import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MealCard from '../components/organisms/MealCard';
import {
  fetchMeals as fetchMealsAction,
  fetchCurrentNutrientsGoal as fetchCurrentNutrientsGoalAction,
  removeMeal as removeMealAction,
} from '../actions/index';
import { routes } from '../routes';
import DaySummary from '../components/molecules/DaySummary';
import DayPicker from '../components/organisms/DayPicker';
import { FormattedDate } from '../helpers/Functions';
import NewItemButton from '../components/atoms/NewItemButton';
import ViewName from '../components/atoms/ViewName';

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 0 35px;

  #emptySpace,
  #newItemButtonContainer {
    width: 33%;
  }
  #dietHeader {
    width: calc(100% - 66%);
    text-align: center;
  }

  @media (max-width: 1000px) {
    padding: 0 10px;
    height: unset;
  }
`;

const Diet = () => {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(FormattedDate(new Date()));
  const [dayPickerVisibleDays, setDayPickerVisibleDays] = useState([]);
  const dispatch = useDispatch();
  const { meals, currentNutrientsGoal } = useSelector((state) => ({
    meals: state.meals,
    currentNutrientsGoal: state.currentNutrientsGoal,
  }));

  const [userNutrientsGoal, setUserNutrientsGoal] = useState(
    currentNutrientsGoal
      ? currentNutrientsGoal
      : { calories: '0', proteins: '0', fats: '0', sugars: '0', carbohydrates: '0' },
  );

  useEffect(() => {
    let daysToFetch = [...dayPickerVisibleDays, selectedDay];
    dispatch(fetchMealsAction(daysToFetch));
    // eslint-disable-next-line
  }, [dayPickerVisibleDays, selectedDay, dispatch]);

  useEffect(() => {
    dispatch(fetchCurrentNutrientsGoalAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserNutrientsGoal(
      currentNutrientsGoal
        ? currentNutrientsGoal
        : { calories: '0', proteins: '0', fats: '0', sugars: '0', carbohydrates: '0' },
    );
    // eslint-disable-next-line
  }, [currentNutrientsGoal]);
  return (
    <>
      <HeaderContainer>
        <div id="emptySpace" />
        <ViewName id="dietHeader">Dieta</ViewName>
        <div id="newItemButtonContainer">
          <NewItemButton
            onClick={() => navigate(routes.addMeal, { state: { date: selectedDay } })}
            content="Dodaj posiłek"
          />
        </div>
      </HeaderContainer>
      <DayPicker
        newItemButton
        caloriesBar
        caloriesGoal={userNutrientsGoal.calories}
        fetchSelectedDaysToParent={(selectedDays) => {
          if (selectedDays.length === 1) setSelectedDay(selectedDays[0]);
        }}
        multipleDaySelect={false}
        daysCount={7}
        valueForTheDay={(date) =>
          meals
            .filter((m) => m.meal.dateTime.split('T')[0] === date)
            .map((m) => m.macronutrientsToEat.calories)
            .reduce((x, y) => x + y, 0)
            .toFixed(1)
        }
        visibleDaysChanged={(days) => setDayPickerVisibleDays(days)}
      />
      {meals
        .filter((m) => m.meal.dateTime.split('T')[0] === selectedDay)
        .map(({ meal, macronutrientsToEat, ingredientsToCook }) => (
          <MealCard
            key={meal.id}
            ingredientsToCook={ingredientsToCook}
            macronutrientsToEat={macronutrientsToEat}
            meal={meal}
            removeMealAction={(id) => dispatch(removeMealAction(id))}
          />
        ))}
      <DaySummary
        nutrientsGoal={userNutrientsGoal}
        selectedDay={selectedDay}
        meals={meals.filter((m) => m.meal.dateTime.split('T')[0] === selectedDay)}
      />
    </>
  );
};

export default Diet;
