import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../components/atoms/Button';
import { Form } from 'formik';
import { routes } from '../routes';
import { LogIn } from '@styled-icons/ionicons-outline/LogIn';

const borderAnimation = keyframes`
from{
  opacity:0;
  transform: translateY(-10px);
}
to{
  opacity:1;
  transform: translateY(0px);
}
`;

const moveLoadingButton = keyframes`
0%{
  width:0px;
  opacity: 0;
}
50%{
  opacity: 0.1;
}
100%{
  width:100%;
  opacity: 0;
}
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  text-align: center;
  margin: 100px 0 50px 0;
  @media (max-width: 450px) {
    margin: 10px 0;
  }
`;

const StyledAuthCard = styled.div`
  width: 650px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(150, 150, 150, 0.5);
  @media (max-width: 700px) {
    width: 90%;
  }
  animation: ${borderAnimation} 1s both;
`;

const ChildrenWrapper = styled.div`
  padding: 0 50px;
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const StyledHeader = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.primaryFont};
  letter-spacing: 1px;
  padding: 25px 0 0 0;
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const StyledButton = styled(Button)`
  width: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  ${(props) =>
    props.$loadingActive ? 
    css`
      &:before {
        content: '';
        transform: rotate(180deg);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: ${({ theme }) => theme.white};
        animation: ${moveLoadingButton} 1.5s infinite;
      }` : undefined}
  svg {
    margin-left: 5px;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.black};
  margin: 20px 0;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0 5px;
`;

const StyledForgetPassword = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  color: ${({ theme }) => theme.grey};
  padding: 0 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const AuthTemplate = ({
  loadingActive,
  buttonText,
  header,
  children,
  linkText,
  linkUrl,
}) => {

  const navigate = useNavigate();

  return (
    <StyledContainer>
      <StyledAuthCard>
        <Form>
          <StyledHeader>{header}</StyledHeader>
          <ChildrenWrapper>{children}</ChildrenWrapper>
          <StyledLink onClick={() => navigate(linkUrl)}>{linkText}</StyledLink>
          <StyledButton $loadingActive={loadingActive} type="submit">
            {buttonText}
            <LogIn size="35" />
          </StyledButton>
        </Form>
      </StyledAuthCard>
      <StyledForgetPassword onClick={() => navigate(routes.forgetPassword)}>
        Zapomniałeś hasła?
      </StyledForgetPassword>
    </StyledContainer>
  );
};

AuthTemplate.propTypes = {
  buttonText: PropTypes.string,
  header: PropTypes.string,
  loadingActive: PropTypes.bool,
  children: PropTypes.any,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
};

export default AuthTemplate;
