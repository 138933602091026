import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FieldErrorMessage from './FieldErrorMessage';

const StyledInputWrapper = styled.div`
  margin: auto;
  position: relative;
  width: 60%;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  min-height: 100px;
  color: ${({ theme }) => theme.black};
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  background-color: transparent;
  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border-color: ${({ theme }) => theme.grey};
  }
  &:valid ~ label,
  &:focus ~ label {
    font-size: 0.8rem;
    top: -15px;
    left: -5px;
    color: ${({ theme }) => theme.secondary};
  }

  &.is-invalid:focus ~ label {
    color: ${({ theme }) => theme.red} !important;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 10px;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: normal;
  pointer-events: none;
  transition: 0.2s ease all;
`;

const FormInput = ({
  labelName,
  name,
  onChange,
  onBlur,
  value,
  rows,
  errors,
  touched,
  autofocus,
}) => (
  <StyledInputWrapper>
    <StyledTextarea
      autoFocus={autofocus}
      name={name}
      onChange={onChange}
      rows={rows}
      onBlur={onBlur}
      value={value}
      required
      className={`form-control${errors && touched ? ' is-invalid' : ''}`}
    />
    <StyledLabel>{labelName}</StyledLabel>
    <FieldErrorMessage name={name} />
  </StyledInputWrapper>
);

FormInput.propTypes = {
  labelName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
};

export default FormInput;
