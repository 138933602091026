import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Search } from '@styled-icons/evil/Search';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 8;
  overflow: none;
`;

const StyledSearchIconWrapper = styled.div`
  @media (max-width: 450px) {
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 10px;
    svg {
      width: 25px;
      height: 25px;
      margin: 7px 0 0 0;
      color: ${({ theme }) => theme.grey};
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  font-weight: ${({ theme }) => theme.regular};
  border: none;
  background-color: transparent;
  border-radius: 15px;
  ::placeholder {
    color: ${({ theme }) => theme.grey};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
  :focus {
    outline: none;
    ::placeholder {
      color: ${({ theme }) => theme.lightGrey};
    }
  }
`;

const StyledActiveVariant = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  margin: 5px 0 0 0;
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.bold};
  white-space: nowrap;
  > svg {
    width: 20px;
    height: 20px;
    margin: 0 0 4px 5px;
    color: ${({ theme }) => theme.grey};
  }
`;

const StyledDropdown = styled.div`
  display: ${(props) => (props.$activeDropdown ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  right: 0;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  z-index: 10;
  padding: 5px;
  > div {
    :hover {
      background-color: ${({ theme }) => theme.primary};
    }
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    padding: 7px 10px;
    font-weight: ${({ theme }) => theme.bold};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.xxs};
    color: ${({ theme }) => theme.black};
    white-space: nowrap;
  }
`;
const handleKeyPress = (callback) => (event) => {
  callback(event.target.value);
};

const Searchbar = ({
  id,
  searchVariants,
  searchVariant,
  setSearchVariant,
  className,
  style,
  onEnter,
  placeholder,
}) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const activeDropdownRef = useRef(activeDropdown);

  useEffect(() => {
    window.addEventListener('mouseup', (event) => {
      if (!activeDropdownRef.current) return;
      var dropdown = document.getElementById('dropdown');
      if (event.target !== dropdown) {
        let parent = event.target.parentNode;
        while (parent !== null) {
          if (parent === dropdown) return;
          else parent = parent.parentNode;
        }
        setActiveDropdown(false);
      }
    });
    return () => {
      activeDropdownRef.current = false;
    };
  }, []);

  useEffect(() => {
    activeDropdownRef.current = activeDropdown;
  }, [activeDropdown]);

  return (
    <StyledWrapper id={id} className={className} style={style}>
      <StyledSearchIconWrapper>
        <Search size={30} />
      </StyledSearchIconWrapper>
      <Input
        type="search"
        onInput={handleKeyPress(onEnter)}
        placeholder={placeholder}
      />
      {searchVariants && (
        <div id="dropdown" style={{ position: 'relative' }}>
          <StyledActiveVariant onClick={() => setActiveDropdown((x) => !x)}>
            {searchVariant === 'Name' ? 'po nazwie' : 'po składnikach'}
            <KeyboardArrowDown />
          </StyledActiveVariant>
          <StyledDropdown $activeDropdown={activeDropdown}>
            <div
              onClick={() => {
                setSearchVariant('Name');
                setActiveDropdown(false);
              }}
            >
              po nazwie
            </div>
            <div
              onClick={() => {
                setSearchVariant('Ingredients');
                setActiveDropdown(false);
              }}
            >
              po składnikach
            </div>
          </StyledDropdown>
        </div>
      )}
    </StyledWrapper>
  );
};
Searchbar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
};
export default Searchbar;
