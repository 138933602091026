import imagesLoaded from 'imagesloaded';

export function FormattedDate(d) {
  if (typeof d == 'string' || typeof d == 'number') d = new Date(d);
  const yyyy = d.getFullYear().toString();
  const mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
  const dd = d.getDate().toString();
  return `${yyyy}-${mm[1] ? mm : `0${mm[0]}`}-${dd[1] ? dd : `0${dd[0]}`}`;
}

export function DateToFormattedTime(d) {
  if (typeof d == 'string' || typeof d == 'number') d = new Date(d);

  const hh = d.getHours().toString();
  const min = d.getMinutes().toString();

  return `${hh[1] ? hh : `0${hh[0]}`}:${min[1] ? min : `0${min[0]}`}`;
}

export function DateToFormattedString(d) {
  if (typeof d == 'string' || typeof d == 'number') d = new Date(d);

  return FormattedDate(d) + ' ' + DateToFormattedTime(d);
}

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const ResizeGridItems = () => {
  const resizeGridItem = (grid, gridItem) => {
    var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    var rowSpan = Math.ceil(
      (gridItem.querySelector('.content').getBoundingClientRect().height + rowGap) /
        (rowHeight + rowGap),
    );
    gridItem.style.gridRowEnd = 'span ' + rowSpan;
  };

  var grids = [...document.getElementsByClassName('grid')];
  grids.forEach(grid => {
    var gridItems = [...grid.getElementsByClassName('grid-item')];
    gridItems.forEach(gridItem => imagesLoaded(gridItem, () => resizeGridItem(grid, gridItem)));
  });
};

export const range = (min, max) => {
  min = parseInt(min);
  max = parseInt(max);
  var len = max - min + 1;
  var arr = new Array(len);
  for (var i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
};
